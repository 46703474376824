import React, { Component, createRef } from 'react';

import {
  Dialog,
  Text,
  Button,
  Suggest,
  Datepicker,
  Select,
  Textarea,
  Icon,
  IconButton,
} from 'new-ui';
import { Moment } from 'moment';
import { getText } from '../../../i18n';

import { getEmployeeFullName } from '../../bi/utils/employees';
import { MainAnalytic } from '../../bi/utils/analytics';
import { dateUtcFormat } from '../../bi/utils/formatDate';
import trimTimezone from '../../bi/utils/trimTimezone';
import { isSmartAgent } from '../../bi/utils/env';

import { EmployeeSuggest } from '../EmployeeSuggest';
import { HotelSuggestion } from '../HotelSuggestion';
import { GeneralInfoList } from '../../page/HotelResult/components/Hotel/components/GeneralInfoList';

import { ADULTFIELD } from '../Menu/HotelSearchMenu/constants';
import ACTIONS from '../../bi/services/hotels/action';
import {
  CHECKIN,
  CHECKOUT,
  SELECTTRAVELLERS,
  SELECT_BED_TYPE,
  TIME,
  DATE,
} from '../../bi/constants/hotelsSearch';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { HOTEL_FORM_REQUEST, HOTEL_FORM_REQUEST_FIELDS } from '../../bi/constants/hotel';
import { DATEFORMATS, PATTERN } from '../../bi/constants/dateFormats';
import { generateTravelers } from '../../bi/constants/travelers';

import HotelsService from '../../bi/services/hotels';
import EmployeeService from '../../bi/services/employee';
import {
  HotelAutocompleteItem,
  HotelAutocompleteObj,
  IHotel,
  OfflineRoomGroup,
  Region,
} from '../../bi/services/hotels/types';
import { EmployeesObj } from '../../bi/types/employees';
import { EmployeeType } from '../../bi/services/employee/consts';

import styles from './index.module.css';

const LABELS = {
  SUGGEST_PLACEHOLDER: getText('components:menu.hotel.cityOrHotel'),
  DATE_IN_PLACEHOLDER: getText('components:menu.hotel.dateIn'),
  DATE_OUT_PLACEHOLDER: getText('components:menu.hotel.dateOut'),
  FORM_HOTEL_SEARCH_DIALOG_TITLE: getText('components:formHotelSearchDialog.title'),
  FORM_HOTEL_SEARCH_DIALOG_DESCRIPTION: getText('components:formHotelSearchDialog.description'),
  GUESTS: getText('components:formHotelSearchDialog.guests'),
  PLACING: getText('components:formHotelSearchDialog.placing'),
  BED_TYPE: getText('components:formHotelSearchDialog.bedType'),
  WITH_BREAKFAST_NAME: getText('hotels:hotelResult.item.generalList.withBreakfastName'),
  WITH_BREAKFAST: getText('hotels:hotelResult.item.generalList.withBreakfast'),
  WITHOUT_BREAKFAST_NAME: getText('hotels:hotelResult.item.generalList.withoutBreakfastName'),
  WITHOUT_BREAKFAST: getText('hotels:hotelResult.item.generalList.withoutBreakfast'),
};

const TRAVELERS = generateTravelers(6);

type SelectValueType = Moment | string | null;
type WithTimeValueType = boolean | string | Moment;

interface FormHotelSearchDialogProps {
  hotelsService: HotelsService,
  employeeService: EmployeeService,
  hotel: IHotel,
  onSendRequest(): void,
  show: boolean,
  loading: boolean,
  selectOfflineRoom: OfflineRoomGroup,
}

interface FormHotelSearchDialogState {
  region: Region,
  checkin: Moment | null,
  checkout: Moment | null
  checkinMinDate: Moment,
  checkoutMinDate: Moment,
  adult: number,
  bedType: number,
  travellersCount: number,
  customCheckin: Moment | null,
  customCheckout: Moment | null,
  datepickersOpened: {
    from: boolean,
    to: boolean,
  },
  comment: string,
  arrayEmployees: any[],
  hasAdd: boolean,
  inputValue: string,
  canAddEmployee: boolean,
  employeesId: number[],
}

class FormHotelSearchDialog extends Component<FormHotelSearchDialogProps, FormHotelSearchDialogState> {
  suggest: React.ForwardedRef<HTMLInputElement>;
  unsubscribeFn: () => void;
  constructor(props: FormHotelSearchDialogProps) {
    super(props);

    const {
      region,
      adult,
      bedType,
      travellersCount,
      checkin,
      checkout,
      checkinMinDate,
      checkoutMinDate,
      customCheckin,
      customCheckout,
      comment,
      arrayEmployees,
      employeesId,
    } = props.hotelsService.getSearchState();

    this.suggest = createRef();

    this.state = {
      region,
      checkin,
      checkout,
      checkinMinDate,
      checkoutMinDate,
      adult,
      bedType,
      travellersCount,
      customCheckin,
      customCheckout,
      datepickersOpened: {
        from: false,
        to: false,
      },
      comment,
      arrayEmployees,
      employeesId,
      inputValue: '',
      canAddEmployee: false,
      hasAdd: false,
    };
  }

  componentDidMount() {
    this.unsubscribeFn = this.props.hotelsService.subscribeSearch(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = (state: FormHotelSearchDialogState) => this.setState({ ...state });

  // @ts-ignore
  getKey = ({ items = [], title, Id }) => {
    if (items.length) {
      return title;
    }

    return Id;
  };

  handleSendRequest = () => {
    const { hotelsService, hotel, onSendRequest } = this.props;

    hotelsService.changeloadingRequest();

    MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.SENDTOTRAVELASSISTANT, {
      // @ts-ignore
      value: hotel.Id,
    });

    return onSendRequest();
  };

  handleInputChange = (value: string) => this.handleFetchRequest(value) as Promise<EmployeeType[]>;

  handleFetchRequest = (value: string) => this.props.employeeService.autocomplete(value).then((res: any[]) =>
    res.filter((employee: any[]) => {
      // @ts-ignore
      const employeeName = getEmployeeFullName(employee);

      return !this.state.arrayEmployees.some(item => item === employeeName);
    }));

  handleClose = () => this.props.hotelsService.showFormHotelSearchDialog();

  handleSwitchEmpty = () => this.setState({ hasAdd: !this.state.hasAdd });

  handleSelected = (suggestion: Partial<EmployeeType>) => {
    const { arrayEmployees, employeesId } = this.state;
    const employeeIds = [...employeesId];

    if (suggestion.Id) employeeIds.push(suggestion.Id);

    const employee = getEmployeeFullName(suggestion);
    this.setState({ hasAdd: false });

    return this.props.hotelsService.updateSelectedEmployee([...arrayEmployees, employee.trim()], employeeIds);
  };

  handleRemoveEmployee = (employee: EmployeesObj) => this.props.hotelsService.removeEmployee(employee);

  handleSuggestSelected = (suggest: HotelAutocompleteItem) => {
    if (suggest !== null) {
      this.props.hotelsService.setSearch('region.selected', suggest);
    }
  };

  handleChangeForm = (
    field: string,
    value: Moment | string | null,
    withTime: boolean | string | Moment = false,
  ) => {
    const { hotelsService } = this.props;
    const preparedField = field === ADULTFIELD ? ADULTFIELD : `${field}.${withTime ? TIME : DATE}`;

    if (field !== ADULTFIELD && !withTime) {
      hotelsService.setSearch(`${field}.time`, null);
    }

    hotelsService.setSearch(preparedField, value);
  };

  handleChangeBedType = (field: string, value: string) => this.props.hotelsService.setSearch(field, value);

  handleGetSuggests = (query: string) => this.props.hotelsService.autocomplete(query);

  handleChangeField = (field: string, value: string) => this.props.hotelsService.setSearch(field, value);

  renderSuggestion = ({ title, items, Name, FullName }: any) => {
    const { region: { label: query } } = this.state;

    return (
      <HotelSuggestion
        items={ items }
        title={ title }
        Name={ Name }
        FullName={ FullName }
        query={ query }
      />
    );
  };

  renderNumber = (number: number) => (
    <Text
      className={ styles.number }
      color='gray'
      type='NORMAL_16_130'
    >
      { number }.
    </Text>
  );

  renderEnterEmployee = () => {
    const { inputValue, canAddEmployee } = this.state;

    return (
      <div className={ styles['enter-container'] }>
        <div className={ styles.suggest }>
          <EmployeeSuggest
            onFetch={ this.handleInputChange }
            onSelect={ this.handleSelected }
            value={ inputValue as unknown as EmployeeType }
            canAddEmployee={ canAddEmployee }
            autoFocus
            withFetching
          />
        </div>
      </div>
    );
  };

  renderItemEmployee = (employee: any, number: number) => {
    const deleteContent = (
      <IconButton
        onClick={ () => this.handleRemoveEmployee(employee) }
        iconType='closeButton'
        className={ styles.remove }
      />
    );

    return (
      <div className={ styles['employee-container'] }>
        <div className={ styles.employee }>
          { this.renderNumber(number) }
          <Text type='NORMAL_16' >
            { employee }
          </Text>
          { deleteContent }
        </div>
      </div>
    );
  };

  renderDefaultEmployee = () => {
    const classNames = [styles['default-container']];
    const renderIconName = isSmartAgent ? HOTEL_FORM_REQUEST.SELECT_TRAVELER : HOTEL_FORM_REQUEST.SELECT_EMPLOYEE;

    return (
      <div data-qa={ QA_ATTRIBUTES.hotels.hotelSearchDialog.employees } className={ classNames.join(' ') }>
        <div className={ styles['add-employee'] }>
          <Button
            onClick={ this.handleSwitchEmpty }
            type='textual'
            className={ styles.button }
          >
            <Icon type='addEmployee' className={ styles.icon } />
            { renderIconName }
          </Button>
        </div>
      </div>
    );
  };

  renderGuestsBlock = () => {
    const {
      travellersCount,
      adult,
      bedType,
    } = this.state;

    const placingHtml = travellersCount > 1 && (
      <div className={ styles.placing }>
        <Text type='NORMAL_14' className={ styles.text }>
          { LABELS.PLACING }
        </Text>
        <div className={ styles['select-wrapper'] }>
          <Select
            theme='default-small'
            items={ SELECTTRAVELLERS[travellersCount] }
            value={ adult }
            onChange={ (value: string | number) => this.handleChangeForm(ADULTFIELD, value as string) }
            disabled
          />
        </div>
      </div>
    );

    const bedTypeHtml = (travellersCount === 2 && adult === 2) && (
      <div className={ styles.placing }>
        <Text type='NORMAL_14' className={ styles.text }>
          { LABELS.BED_TYPE }
        </Text>
        <div className={ `${styles['select-wrapper-bed-type']} ${styles['select-wrapper']}` }>
          <Select
            theme='default-small'
            items={ SELECT_BED_TYPE }
            value={ bedType }
            onChange={ (value: string) => this.handleChangeBedType(ACTIONS.BED_TYPE_SEARCH, value) }
          />
        </div>
      </div>
    );

    return (
      <div className={ styles['placing-wrapper'] }>
        <div className={ styles.adults }>
          <Text type='NORMAL_14' className={ styles.text }>
            { LABELS.GUESTS }
          </Text>
          <Select
            qaAttr={ QA_ATTRIBUTES.hotels.hotelSearchDialog.guests }
            theme='default-small'
            items={ TRAVELERS }
            value={ travellersCount }
            onChange={ (value: string | number) =>
              this.handleChangeField(HOTEL_FORM_REQUEST_FIELDS.TRAVELLERS_COUNT, value as string) }
            disabled
          />
        </div>
        { placingHtml }
        { bedTypeHtml }
      </div>
    );
  };

  renderDateBlock = () => {
    const {
      checkin,
      checkout,
      checkinMinDate,
      checkoutMinDate,
      customCheckin,
      customCheckout,
      datepickersOpened: {
        from,
        to,
      },
    } = this.state;

    const preparedCheckin = customCheckin || checkin;
    const preparedCheckout = customCheckout || checkout;
    const checkinFormat = customCheckin ? PATTERN.FULLDATEWITHTIME : DATEFORMATS.DATE;
    const checkoutFormat = customCheckout ? PATTERN.FULLDATEWITHTIME : DATEFORMATS.DATE;

    return (
      <div className={ styles.datePicker }>
        <div className={ styles.date }>
          <Datepicker
            qaAttr={ QA_ATTRIBUTES.hotels.hotelSearchDialog.checkIn }
            closeOnTabOut
            open={ from }
            withLabel
            placeholder={ LABELS.DATE_IN_PLACEHOLDER }
            type='dateTimeList'
            inputTheme='open'
            value={ preparedCheckin }
            onChange={ (value: SelectValueType, withTime: WithTimeValueType) => {
              this.handleChangeForm(CHECKIN, value, withTime);
            } }
            min={ checkinMinDate }
            format={ checkinFormat }
            direction='from'
            wrapperClassName={ styles.wrapper }
            inputClassName={ styles.input }
            isDuration
            durationDates={ [preparedCheckin as Moment, preparedCheckout as Moment] }
            disabled
            disableTimeOver
          />
        </div>
        <div className={ styles.date }>
          <Datepicker
            qaAttr={ QA_ATTRIBUTES.hotels.hotelSearchDialog.checkOut }
            closeOnTabOut
            open={ to }
            placeholder={ LABELS.DATE_OUT_PLACEHOLDER }
            withLabel
            type='dateTimeList'
            inputTheme='open'
            value={ preparedCheckout }
            onChange={ (value: SelectValueType, withTime: WithTimeValueType) => this.handleChangeForm(CHECKOUT, value, withTime) }
            min={ checkoutMinDate }
            format={ checkoutFormat }
            direction='to'
            wrapperClassName={ styles.wrapper }
            inputClassName={ styles.input }
            isDuration
            durationDates={ [preparedCheckin as Moment, preparedCheckout as Moment] }
            disabled
          />
        </div>
      </div>
    );
  };

  renderBreakfastAndFreeCancellationInfo = () => {
    const {
      hotel: { CountryCode },
      selectOfflineRoom: { Rates, RateId },
    } = this.props;

    const currentRate = Rates.find((item) => item.RateId === RateId);

    if (currentRate) {
      const {
        CancellationPolicy: { Refundable, DeadLine },
        Meal,
      } = currentRate;

      const deadLine = DeadLine ? dateUtcFormat(trimTimezone(DeadLine), PATTERN.DAY_OF_MONTH_TIME) : '';

      return (
        <div className={ styles['placing-wrapper'] }>
          <GeneralInfoList
            refundable={ Refundable }
            // @ts-ignore
            meal={ Meal }
            date={ deadLine }
            countryCode={ CountryCode }
          />
        </div>
      );
    }

    return null;
  };

  renderEmployeeHtml = (employee: any, number: number) => {
    const html = () => {
      if (employee) {
        return this.renderItemEmployee(employee, number);
      }

      if (this.state.hasAdd) {
        return this.renderEnterEmployee();
      }

      return this.renderDefaultEmployee();
    };

    return (
      <div className={ styles.wrapper }>
        { html() }
      </div>
    );
  };

  render() {
    const {
      region,
      travellersCount,
      hasAdd,
      arrayEmployees,
      comment,
    } = this.state;
    const { show, loading, hotel: { IsAvailableContract } } = this.props;

    const suggests = region.suggests.filter((item: HotelAutocompleteObj) => item.title === HOTEL_FORM_REQUEST.HOTELS);

    const employeesHtml = arrayEmployees.map((item, index) => this.renderEmployeeHtml(item, index + 1));

    if (employeesHtml.length < travellersCount && hasAdd) {
      employeesHtml.push(this.renderEnterEmployee());
    } else if (employeesHtml.length < travellersCount) {
      employeesHtml.push(this.renderDefaultEmployee());
    }

    const dateBlockHtml = this.renderDateBlock();
    const breakfastAndFreeCancellationBlockHtml = this.renderBreakfastAndFreeCancellationInfo();
    const guestsBlockHtml = this.renderGuestsBlock();

    return (
      <Dialog
        className={ styles.dialog }
        showClosing
        show={ show }
        onChange={ this.handleClose }
      >
        <div className={ styles.wrapper }>
          <Text
            qaAttr={ QA_ATTRIBUTES.hotels.hotelSearchDialog.title }
            type='bold_20'
            className={ styles.title }
          >
            { LABELS.FORM_HOTEL_SEARCH_DIALOG_TITLE }
          </Text>
          <Text
            qaAttr={ QA_ATTRIBUTES.hotels.hotelSearchDialog.description }
            type='NORMAL_16'
            className={ styles.title_next }
          >
            { LABELS.FORM_HOTEL_SEARCH_DIALOG_DESCRIPTION }
          </Text>
          <div className={ styles.suggest }>
            <Suggest
              qaAttr={ QA_ATTRIBUTES.hotels.hotelSearchDialog.suggest }
              isFetching={ region.loading }
              placeholder={ LABELS.SUGGEST_PLACEHOLDER }
              ref={ this.suggest }
              value={ region.label }
              // @ts-ignore
              items={ suggests }
              // @ts-ignore
              onSelect={ this.handleSuggestSelected }
              onChange={ this.handleGetSuggests }
              renderItem={ this.renderSuggestion }
              // @ts-ignore
              keyExtractor={ this.getKey }
              disabled={ IsAvailableContract }
            />
          </div>
          { dateBlockHtml }
          { guestsBlockHtml }
          { breakfastAndFreeCancellationBlockHtml }
          { employeesHtml }
          <div className={ styles.textarea }>
            <Textarea
              qaAttr={ QA_ATTRIBUTES.hotels.hotelSearchDialog.comment }
              value={ comment }
              placeholder={ HOTEL_FORM_REQUEST.COMMENT }
              onChange={ (value: string) => this.handleChangeField(HOTEL_FORM_REQUEST_FIELDS.COMMENT, value) }
            />
          </div>
          <Button
            qaAttr={ QA_ATTRIBUTES.hotels.hotelSearchDialog.sendRequest }
            className={ styles.action }
            onClick={ this.handleSendRequest }
            loading={ loading }
          >
            { HOTEL_FORM_REQUEST.SEND_REQUEST }
          </Button>
        </div>
      </Dialog>
    );
  }
}

export { FormHotelSearchDialog };
