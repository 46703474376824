import React from 'react';
import { Text } from 'new-ui';

import { TRANSIT_DOCUMENTS } from '../../constants';

import styles from '../../../../styles/index.module.css';

type TWarningText = { transitDocumentsSelected: boolean };

export const WarningText = ({ transitDocumentsSelected }: TWarningText) => {
  if (transitDocumentsSelected) {
    return null;
  }

  return (
    <div className={ styles.warning }>
      <Text color='red'>
        { TRANSIT_DOCUMENTS.WARNING_CHOOSE_DOCUMENT }
      </Text>
    </div>
  );
};
