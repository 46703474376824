import { getText } from '../../../../../i18n';
import {
  EKaliningradRadioVariantsRequest,
  EKaliningradRadioVariantsResponse,
} from '../../../../bi/constants/train';

export const TRANSIT_DOCUMENTS = {
  UPD: getText('components:trainItem.attentionKaliningrad.upd'),
  UPD_INFO: {
    FIRST: getText('components:trainItem.attentionKaliningrad.updInfo.first'),
    SECOND: getText('components:trainItem.attentionKaliningrad.updInfo.second'),
  },
  VISA: getText('components:trainItem.attentionKaliningrad.visa'),
  VISA_INFO: {
    FIRST: getText('components:trainItem.attentionKaliningrad.visaInfo.first'),
    SECOND: getText('components:trainItem.attentionKaliningrad.visaInfo.second'),
    THIRD: getText('components:trainItem.attentionKaliningrad.visaInfo.third'),
    FOURTH: getText('components:trainItem.attentionKaliningrad.visaInfo.fourth'),
    FIFTH: getText('components:trainItem.attentionKaliningrad.visaInfo.fifth'),
  },
  WARNING_CHOOSE_DOCUMENT: getText('cart:tooltipTexts.chooseTransitDocumnet'),
};

/** Список вариантов для упрощенного документа */
export const SIMPLIFIED_DOCUMENT_VARIANTS: string[] = [
  EKaliningradRadioVariantsRequest.SimplifiedDocument,
  EKaliningradRadioVariantsResponse.SimplifiedDocument,
];

/** Список вариантов для документа для транзитного проезда */
export const PERMISSION_DOCUMENT_VARIANTS: string[] = [
  EKaliningradRadioVariantsRequest.Permission,
  EKaliningradRadioVariantsResponse.Permission,
];
