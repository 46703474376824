import React from 'react';
import { Text } from 'new-ui';
import { getText } from '../../../i18n';

import TaxiProviders from '../TaxiProviders';

import { dateUtcFormat } from '../../bi/utils/formatDate';

import { ACTIVE_PROVIDERS, TAXI_CLASSES_TITLE } from '../../bi/constants/taxi';
import { PATTERN } from '../../bi/constants/dateFormats';

import { ITaxiItemData } from '../../bi/types/taxi';

import styles from './styles/index.module.css';

interface ITaxiVoucherItemProps {
  item: ITaxiItemData,
}

const LABELS = {
  TITLE: getText('taxi:voucher.title'),
  DESCRIPTION: getText('taxi:voucher.morePaymentInfo'),
  AVAILABLE_APPLICATIONS: getText('taxi:voucher.availableApplications'),
  FROM: getText('taxi:voucher.dateFrom'),
  TO: getText('taxi:voucher.dateTo'),
  MSK_TIME: getText('taxi:voucher.mskTime'),
  TAXI_CLASS: (taxiClass: string) => getText('taxi:taxiClass', { taxiClass }),
  SUPPORT: getText('taxi:voucher.support'),
};

const TaxiVoucherItem = ({
  item: {
    StartDate,
    EndDate,
    Classes,
  },
}: ITaxiVoucherItemProps) => {
  const renderDate = (prefix: string, postfix: string, dateFrom: string, dateTo: string): JSX.Element =>
    <Text type='NORMAL_16_130' className={ styles.text }>
      {`${prefix} ${dateUtcFormat(dateFrom, PATTERN.DATE_VOUCHER_FROM)} ${postfix} ${dateUtcFormat(dateTo, PATTERN.DATE_VOUCHER_TO)} ${LABELS.MSK_TIME}`}
    </Text>;

  const renderClasses = () => LABELS.TAXI_CLASS(Classes.map(name => TAXI_CLASSES_TITLE[name].toLowerCase()).join(', '));

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.title }>
        <Text type='bold_18'>{ LABELS.TITLE }</Text>
      </div>
      <div className={ styles.description }>
        <Text type='NORMAL_16' >{ LABELS.DESCRIPTION }</Text>
      </div>
      <div className={ styles.details }>
        <div className={ styles.applications }>
          <Text type='NORMAL_16_130' className={ styles.text }>{ LABELS.AVAILABLE_APPLICATIONS }</Text>
          <TaxiProviders providers={ ACTIVE_PROVIDERS } className={ styles.providers } />
        </div>
        { renderDate(LABELS.FROM, LABELS.TO, StartDate, EndDate) }
        <Text type='NORMAL_16_130' className={ styles.text }>{ renderClasses() }</Text>
        <Text type='SEMIBOLD_16'>{ LABELS.SUPPORT }</Text>
      </div>
    </div>
  );
};

export { TaxiVoucherItem };
