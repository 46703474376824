// @ts-nocheck
import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CONFIG from '../../../config';

const SmartChat = ({ workspaceService }) => {
  const initializeSmartChat = useCallback(async () => {
    const { Email } = workspaceService.get();
    localStorage.setItem('smart-chat-client-email', Email);
    window.smartwayWidget = {};
    const domain = CONFIG.SMARTCHAT.URL;

    const injectWidgetStyles = () => {
      const href = `${domain}/style.css`;

      const link = document.createElement('link');
      link.href = href;
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.media = 'screen,print';

      document.getElementsByTagName('head')[0].appendChild(link);
    };

    const injectWidget = async () => {
      const widgetContainerElement = document.getElementById('smart-chat-widget-container');

      const path = `${domain}/widget.js`;
      let widget;
      try {
        // на время поставил игнор
        widget = await import(/* @vite-ignore */ path);
      } catch (err) {
        widget = require(path);
      }

      widget.render(widgetContainerElement);
    };

    await injectWidgetStyles();
    await injectWidget();
  }, [workspaceService]);

  useEffect(() => {
    initializeSmartChat();
  }, [workspaceService]);

  return null;
};

SmartChat.propTypes = {
  workspaceService: PropTypes.object.isRequired,
};

export default SmartChat;
