import React from 'react';
import clsx from 'clsx';
import { Text, TextColor, TextType } from 'new-ui';

import { getText } from '../../../i18n';

import styles from './styles/index.module.css';

interface TTrainKaliningradAttentionProps {
  color?: TextColor;
  isTrainItem?: boolean;
}

interface IContentItem {
  text: TextType | string;
  url?: string;
}

const LABELS = {
  ATTENTION_KALININGRAD: {
    FIRST: getText('components:trainItem.attentionKaliningrad.first'),
    SECOND: getText('components:trainItem.attentionKaliningrad.second'),
    THIRD: getText('components:trainItem.attentionKaliningrad.third'),
    FOURTH_FIRST: getText('components:trainItem.attentionKaliningrad.fourth.first'),
    FOURTH_SECOND: getText('components:trainItem.attentionKaliningrad.fourth.second'),
    FOURTH_THIRD: getText('components:trainItem.attentionKaliningrad.fourth.third'),
    FOURTH_FOURTH: getText('components:trainItem.attentionKaliningrad.fourth.fourth'),
    FOURTH_FIFTH: getText('components:trainItem.attentionKaliningrad.fourth.fifth'),
    FOURTH_SIXTH: getText('components:trainItem.attentionKaliningrad.fourth.sixth'),
    FOURTH_SEVENTH: getText('components:trainItem.attentionKaliningrad.fourth.seventh'),
    TITLE_RZD: getText('components:trainItem.attentionKaliningrad.titleRzd'),
    LINK_RZD: getText('components:trainItem.attentionKaliningrad.linkRzd'),
    LINK_STD: getText('components:trainItem.attentionKaliningrad.linkStd'),
  },
};

export const TrainKaliningradAttention = ({
  color = 'default',
  isTrainItem = false,
}: TTrainKaliningradAttentionProps) => {
  const createLink = (url: string, text: TextType) => (
    <a href={ url } target='_blank' rel='noreferrer'>
      { text }
    </a>
  );

  const mapTextAttention = (contentArray: IContentItem[]) =>
    contentArray.map((item, index) => (
      item.url
        ? <span key={ index }>{ createLink(item.url, item.text as TextType) }</span>
        : <span key={ index }>{ item.text }</span>),
    );

  const wrapperSecondaryText = clsx(
    styles.secondary_text,
    !isTrainItem && styles.links_search,
  );

  const createText = (contentArray: IContentItem[], type = 'NORMAL_14' as TextType) => (
    <Text className={ wrapperSecondaryText } type={ type } color={ color }>
      { mapTextAttention(contentArray) }
    </Text>
  );

  return (
    <div className={ isTrainItem ? styles.wrap_info : styles.wrap }>
      {createText([{ text: LABELS.ATTENTION_KALININGRAD.FIRST }])}
      <ul className={ `${styles.description_list} ${styles.description_list_top}` }>
        <li>{createText([{ text: LABELS.ATTENTION_KALININGRAD.SECOND }])}</li>
        <li>{createText([{ text: LABELS.ATTENTION_KALININGRAD.THIRD }])}</li>
      </ul>
      {createText([{ text: LABELS.ATTENTION_KALININGRAD.FOURTH_FIRST }])}
      <ol className={ `${styles.description_list} ${styles.description_list_bottom}` }>
        <li>{createText([{ text: LABELS.ATTENTION_KALININGRAD.FOURTH_SECOND }])}</li>
        <li>{createText([{ text: LABELS.ATTENTION_KALININGRAD.FOURTH_THIRD }])}</li>
        <li>
          {createText([
            { text: LABELS.ATTENTION_KALININGRAD.FOURTH_FOURTH },
            {
              url: LABELS.ATTENTION_KALININGRAD.LINK_RZD,
              text: LABELS.ATTENTION_KALININGRAD.TITLE_RZD,
            },
          ])}
        </li>
        <li>
          {createText([
            { text: LABELS.ATTENTION_KALININGRAD.FOURTH_FIFTH },
            {
              url: LABELS.ATTENTION_KALININGRAD.LINK_STD,
              text: LABELS.ATTENTION_KALININGRAD.LINK_STD,
            },
            { text: LABELS.ATTENTION_KALININGRAD.FOURTH_SIXTH },
          ])}
        </li>
        <li>{createText([{ text: LABELS.ATTENTION_KALININGRAD.FOURTH_SEVENTH }])}</li>
      </ol>
    </div>
  );
};
