import { EmployeeDocumentType } from '../../../../bi/services/employee/consts';
import { EmployeesDocument } from '../../../../bi/types/employees';

export const checkIsKaliningradButtonsAvailable = (
  isKaliningradTicket: boolean,
  documents: EmployeesDocument[],
  isBelarusCitizen: boolean,
) => {
  const isForeignPassport = documents
    .find(({ Type }) => Type === EmployeeDocumentType.ForeignPassport);

  return isKaliningradTicket && (isForeignPassport || isBelarusCitizen);
};
