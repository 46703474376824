import { FIELD_APPROVAL_REQUEST } from '../constants/approve';
import { COMPANY_DEPARTMENT_DIVIDER } from '../constants/cart';
import { IApprovalPreStepRequest, IApprovalStepRequest } from '../types/approvalScheme';

interface Approver {
  UserId: string;
}

interface Step {
  Approvers: Approver[];
  Roles?: string;
}

interface Company {
  CompanyId: string;
  Departments: Department[];
  ShortCompanyName?: string;
  CompanyName: string;
}

interface Department {
  Id: string;
  Name: string;
}

export interface Employee {
  Companies?: Company[];
  CompanyId?: number;
  DepartmentId?: number;
  ProjectId?: number | null;
  Departments?: string;
}

export interface CustomAnalytic {
  Required: boolean;
  Values: { Id: number }[];
}

export interface ApplicationScheme {
  IsRequiredDestinationCity?: boolean;
  IsRequiredDates?: boolean;
  IsRequiredBudget?: boolean;
  IsRequiredPurpose?: boolean;
  IsRequiredComment?: boolean;
}

type ApplicationSchemeKey = keyof ApplicationScheme;

interface Scheme {
  Steps: Step[];
  PreSteps: Step[];
}

interface ApprovalERSchemeStep {
  Type?: number;
  ConditionOfApproval: number;
  Approvers: string[];
  Roles: number[];
}

interface MappedDepartment {
  value: string;
  label: string;
}

interface ValidationFunctions {
  IsRequiredDestinationCity: () => boolean;
  IsRequiredDates: () => boolean;
  IsRequiredBudget: () => boolean;
  IsRequiredPurpose: () => boolean;
  IsRequiredComment: () => boolean;
}

type ValidationFunctionKey = keyof ValidationFunctions;

type CompanyItem = { value: string | boolean | undefined };

const checkAllSteps = (
  step: IApprovalPreStepRequest[] | IApprovalStepRequest[],
  userId: string | number | null,
) => step &&
  step.length === 1 &&
  step[0]?.Approvers.length === 1 &&
  step[0].Approvers[0] === userId;

const checkEmployeesCompanies = (Employees: any[]) =>
  Employees.reduce(
    (r, { Companies = [], CompanyId: selectedCompanyId, DepartmentId: selectedDepartmentId }) => {
      const finderDepartments = Companies.find(
        ({ CompanyId }: Company) => selectedCompanyId && CompanyId === selectedCompanyId,
      );

      if (finderDepartments && finderDepartments.Departments.length) {
        const filterDepartment = !!selectedDepartmentId;

        return [...r, { value: filterDepartment }];
      }

      return [...r, { value: selectedCompanyId }];
    },
    [] as { value: boolean | string | undefined }[],
  );

const checkEmployeeRequiredProject = (employees: Employee[], companiesCostCenter: string[]) => {
  const isRequiredProjectExist = employees.some(({ Companies, ProjectId }) => {
    if (ProjectId || !companiesCostCenter.length || !Companies?.length) return false;

    const companyIds = Companies?.map(({ CompanyId }) => CompanyId);

    return companyIds.some((companyId) => companiesCostCenter.includes(companyId) && !ProjectId);
  });

  return !isRequiredProjectExist;
};

const checkCustomAnalyticRequiredNotFilled = (
  userCustomAnalytics: CustomAnalytic | never[],
  selectedCustomAnalytics: number[],
) =>
  (userCustomAnalytics as CustomAnalytic[]).some(({ Required, Values }:CustomAnalytic) => {
    if (!Required) return false;

    const valuesIds = Values.map(({ Id: id }) => id);

    return !valuesIds.some(value => selectedCustomAnalytics.includes(value));
  });

const disabledApprovalButton = (
  Employees: Employee[],
  Budget: number | null,
  destination: any,
  Dates: string | null | number,
  Purpose: any,
  Comment: string | null,
  applicationScheme: ApplicationScheme,
  isApprover: boolean,
  companiesWithCostCenter: string[],
  sortedCustomAnalytics: CustomAnalytic | CustomAnalytic | never[],
  chosenAnalytics: number[],
): { disabled: boolean, notValidFields: string[] } => {
  const notValidFields: string[] = [];

  const validationFunctions: ValidationFunctions = {
    IsRequiredDestinationCity: (): boolean => !!destination,
    IsRequiredDates: (): boolean => !!Dates,
    IsRequiredBudget: (): boolean => !!Budget,
    IsRequiredPurpose: (): boolean => Purpose !== null,
    IsRequiredComment: (): boolean => !!Comment && !!Comment.length,
  };

  Object.keys(applicationScheme).forEach((key) => {
    if (
      Object.prototype.hasOwnProperty.call(validationFunctions, key) &&
      applicationScheme[key as ApplicationSchemeKey] &&
      typeof applicationScheme[key as ApplicationSchemeKey] === 'boolean'
    ) {
      const validationFunction = validationFunctions[key as ValidationFunctionKey];

      if (validationFunction && typeof validationFunction === 'function' && !validationFunction()) {
        notValidFields.push(key);
      }
    }
  });
  const companiesArray = isApprover || checkEmployeesCompanies(Employees);
  const checkCompanies = isApprover || (
    Array.isArray(companiesArray) &&
    companiesArray.every(({ value }: CompanyItem) => !!value)
  );
  const checkRequiredProject = checkEmployeeRequiredProject(Employees, companiesWithCostCenter);
  const checkRequiredCustomAnalyticNotFilled = checkCustomAnalyticRequiredNotFilled(sortedCustomAnalytics, chosenAnalytics);
  const disabled = !Employees.length || !checkCompanies || !!notValidFields.length || !checkRequiredProject || checkRequiredCustomAnalyticNotFilled;

  if (!checkRequiredProject) {
    notValidFields.push('IsRequiredProject');
  }

  if (checkRequiredCustomAnalyticNotFilled) {
    notValidFields.push('IsRequiredCustomAnalytic');
  }

  return ({ disabled, notValidFields });
};

const checkFieldsForTooltip = (
  Employees: Employee[],
  Budget: number | null,
  Destination: number | null,
  date: string | null | number,
  Purpose: any,
  Comment: string | null,
  applicationScheme: ApplicationScheme,
  isApprover: boolean,
  companiesWithCostCenter: string[],
  sortedCustomAnalytics: CustomAnalytic | never[],
  chosenAnalytics: number[],
) => {
  const nonFilledFields = [];
  const { notValidFields } = disabledApprovalButton(
    Employees,
    Budget,
    Destination,
    date,
    Purpose,
    Comment,
    applicationScheme,
    isApprover,
    companiesWithCostCenter,
    sortedCustomAnalytics,
    chosenAnalytics,
  );

  if (notValidFields.includes('IsRequiredDestinationCity')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.CITY);
  }

  if (notValidFields.includes('IsRequiredDates')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.DATES);
  }

  if (notValidFields.includes('IsRequiredBudget')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.BUDGET);
  }

  if (notValidFields.includes('IsRequiredPurpose')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.PURPOSE);
  }

  if (notValidFields.includes('IsRequiredComment')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.COMMENT);
  }

  if (notValidFields.includes('IsRequiredProject')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.PROJECT);
  }

  if (notValidFields.includes('IsRequiredCustomAnalytic')) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.CUSTOM_ANALYTIC);
  }

  const foundEmployee = Employees.find(({ Departments, DepartmentId: selectedDepartmentId, CompanyId: selectedCompanyId }) => (
    Departments?.length ? !selectedDepartmentId : !selectedCompanyId
  ));

  if (!Employees.length) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.EMPLOYEES);
  } else if (foundEmployee && !isApprover) {
    nonFilledFields.push(FIELD_APPROVAL_REQUEST.COMPANIES);
  }

  return nonFilledFields;
};

const checkAddCompany = (isMulti: boolean, companiesLength: number, index: number) => {
  if (!isMulti || (companiesLength === 1)) return true;

  return (!isMulti || companiesLength === 1) && Number(index) === 0;
};

const checkApprovalSchemeArray = (
  steps: ApprovalERSchemeStep[],
) => steps.every(({ Approvers, Roles }: any) => Approvers.length + (Roles?.length || 0) >= 1);

const getEmployeeCompaniesWithDepartments = (employeeCompanies: Company[]) =>
  employeeCompanies.reduce<MappedDepartment[]>((r, { CompanyId, Departments = [], ShortCompanyName, CompanyName }) => {
    if (Departments.length) {
      const mapperDepartment = Departments.map(({ Id: depId, Name }) =>
        ({ value: `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${depId}`, label: `${ShortCompanyName || CompanyName} (${Name})` }),
      );

      return [...r, ...mapperDepartment];
    }

    return [...r, { value: CompanyId, label: ShortCompanyName || CompanyName }];
  }, []);

const getApproversAndRolesLength = (roles: number[], approvers: string[]) =>
  ((roles && roles.length) || 0) + ((approvers && approvers.length) || 0);

const getScheme = (scheme: Scheme) => {
  const steps = scheme.Steps.map((item: Step) => {
    const approvers = item.Approvers.map(({ UserId }: Approver) => UserId);

    return {
      ...item,
      Approvers: approvers,
    };
  });

  const preSteps = scheme.PreSteps.map((item: Step) => {
    const approvers = item.Approvers.map(({ UserId }: Approver) => UserId);

    return {
      ...item,
      Approvers: approvers,
    };
  });

  return ({
    ...scheme,
    Steps: steps,
    PreSteps: preSteps,
  });
};

export {
  disabledApprovalButton,
  checkFieldsForTooltip,
  getEmployeeCompaniesWithDepartments,
  checkAddCompany,
  checkAllSteps,
  checkApprovalSchemeArray,
  getApproversAndRolesLength,
  getScheme,
};
