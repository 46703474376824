import React, { Dispatch, FC, SetStateAction, useEffect, useState, KeyboardEvent } from 'react';
import { Datepicker, Input, Text, Select, Tooltip, IconButton } from 'new-ui';
import { Moment } from 'moment';
import { getText } from '../../../i18n';

import { TimeInputWithoutValue } from '../TimeInputWithoutValue';

import { getMoment, momentObject, getTimeFromFullFormYear, getDateFromFullFormYear } from '../../bi/utils/formatDate';

import { TRANSFERFIELDS, FIELDSLABELS, PLACETYPE } from '../../bi/constants/transfer';
import { FILTER_TYPE } from '../../bi/constants/transfers';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { INPUT_TYPES } from '../../bi/constants/app';
import { ToDoList } from '../ToDoList';

import { TransferItemType } from '../../bi/types/transfer';

import CartService from '../../bi/services/cart';
import TransferService from '../../bi/services/transfer';

import styles from './styles/index.module.css';

const LABELS = {
  VIEWERS_TOOLTIP: getText('constants:transfer.viewersTooltip'),
  PHONE_FORMAT: getText('services:employee.store.employee.phoneFormat'),
  DISABLED_DATE: getText('constants:transfer.disabledDate'),
};

const MAX_LENGTH_VIEWERS = 5;

type Item = {
  label: string,
  value: number | string,
};

const prepareRequiredFields = (itemData: { [x: string]: any }) => {
  const {
    Notes,
    TableSignature,
    DateArrival,
    AdditionalServices,
    AdditionalData,
    Viewers = [],
  } = itemData;

  const currentFields: { [x: string]: string | number | [] } = {};

  AdditionalData.forEach((i: string) => {
    currentFields[i] = itemData[i];
  });

  currentFields.DateArrival = DateArrival;

  if (AdditionalServices.includes(FILTER_TYPE.TABLE)) {
    currentFields.TableSignature = TableSignature || '';
  }

  currentFields.Notes = Notes || '';
  currentFields.Viewers = Viewers;

  return currentFields;
};

const TIME_LABEL = '--:--';

interface ITransferFieldsProps {
  itemData: TransferItemType<string>,
  readonly: boolean,
  isIncludeInsurance: boolean,
  onUpdate(): void,
  onChange(value: any, field: string): void,
  onChangeDate(
    { value, hours, minutes }: { value?: Moment, hours?: string, minutes?: string },
    field: string,
    setDateArrival?: Dispatch<SetStateAction<Moment | null>>,
    setTimeArrival?: Dispatch<SetStateAction<string>>): void,
  onChangeTime(fieldArrival: string, valueArrival: string, fieldDeparture: string, valueDeparture: string): void,
  onChangeTerminal(value: string, field: string): void,
  onUpdateTransfer(
    field?: string,
    setDateArrival?: Dispatch<SetStateAction<Moment | null>> | { (): void },
    setTimeArrival?: Dispatch<SetStateAction<string>> | { (): void },
    viewers?: string[] | null
  ): void,
  transferService: TransferService,
  cartService: CartService,
}

const TransferFields: FC<ITransferFieldsProps> = ({
  itemData,
  readonly,
  isIncludeInsurance,
  onUpdate,
  onChange,
  onChangeDate,
  onChangeTime,
  onChangeTerminal,
  onUpdateTransfer,
  transferService,
  cartService,
}) => {
  const [dateArrival, setDateArrival] = useState<Moment | null>(null);
  const [timeArrival, setTimeArrival] = useState('');

  const [dateDeparture, setDateDeparture] = useState<Moment | null>(null);
  const [timeDeparture, setTimeDeparture] = useState('');

  const [arrivalTerminal, setArrivalTerminal] = useState<string | null>(null);
  const [dapartureTerminal, setDepartureTerminal] = useState<string | null>(null);

  const [validViewerError, setValidViewerError] = useState('');

  const viewersList = itemData.Viewers || [];

  const setDatesAndTimes = () => {
    const {
      DateArrival,
      DateDeparture,
      ArrivalTerminal,
      DepartureTerminal,
    } = itemData;

    if (DateArrival) {
      setDateArrival(momentObject(DateArrival));
      setTimeArrival(getTimeFromFullFormYear(DateArrival));
    }

    if (DateDeparture) {
      setDateDeparture(momentObject(DateDeparture));
      setTimeDeparture(getTimeFromFullFormYear(DateDeparture));
    }

    setArrivalTerminal(ArrivalTerminal);
    setDepartureTerminal(DepartureTerminal);

    onChangeTime(TRANSFERFIELDS.TimeArrival,
      DateArrival ? getTimeFromFullFormYear(DateArrival) : '',
      TRANSFERFIELDS.TimeDeparture,
      DateDeparture ? getTimeFromFullFormYear(DateDeparture) : '');
  };

  const setTransferService = (allowBooking: boolean) => {
    const {
      BookId,
      DateArrival,
      DateDeparture,
      ArrivalNumber,
      AdditionalData,
      CarriageNumber,
      ArrivalTerminal,
      DepartureTerminal,
    } = itemData;

    transferService.setTransferCart({
      // @ts-ignore
      BookId, // TODO проверить типы
      DateArrival,
      DateDeparture,
      ArrivalNumber,
      TimeDeparture: DateDeparture ? getTimeFromFullFormYear(DateDeparture) : '',
      TimeArrival: DateArrival ? getTimeFromFullFormYear(DateArrival) : '',
      AdditionalData,
      AllowBooking: allowBooking,
      CarriageNumber,
      EndTerminals: DepartureTerminal,
      StartTerminals: ArrivalTerminal,
    });
  };

  const handleAddViewers = (value = '') => {
    if (!value?.trim() || readonly) {
      return;
    }

    const valuesToAdd = [
      ...viewersList,
      value.trim(),
    ];

    onChange(valuesToAdd, TRANSFERFIELDS.Viewers);
    onUpdateTransfer(TRANSFERFIELDS.Viewers, () => {}, () => {}, valuesToAdd);
  };

  const handleDeleteViewer = (idx: number) => {
    if (readonly) return;

    const filteredViewers = viewersList.filter((_, itemIdx) => itemIdx !== idx);

    onChange(filteredViewers, TRANSFERFIELDS.Viewers);
    onUpdateTransfer(TRANSFERFIELDS.Viewers, () => {}, () => {}, filteredViewers);
  };

  const handleBlurViewerInput = (isValid: boolean) => {
    if (!isValid) {
      return setValidViewerError(LABELS.PHONE_FORMAT);
    }

    return setValidViewerError('');
  };

  const handleFocusViewerInput = () => setValidViewerError('');

  const handleUpdateTransferService = async () => {
    const {
      BookId,
      DateArrival,
      DateDeparture,
    } = itemData;

    const result = await cartService.getCheckTime({
      DepartureTime: DateDeparture ? `${getDateFromFullFormYear(DateDeparture)} ${getTimeFromFullFormYear(DateDeparture)}` : '',
      PickUpTime: `${getDateFromFullFormYear(DateArrival)} ${getTimeFromFullFormYear(DateArrival)}`,
      BookId,
    });
    const { AllowBooking } = result;

    setTransferService(AllowBooking);
  };

  const handleKeyDownNumber = (event: KeyboardEvent<HTMLInputElement>, type: string) => {
    const allowedKeys = [8, 9, 37, 39];

    if (type === INPUT_TYPES.NUMBER && !/[0-9]/.test(event.key) && !allowedKeys.includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const { DateArrival } = itemData;

    if (DateArrival) {
      handleUpdateTransferService();
    } else setTransferService(true);

    setDatesAndTimes();
  }, []);

  const renderInput = (fields: any, field: string, length: number, type = INPUT_TYPES.TEXT, qaAttr = '') => (
    <Input
      value={ fields[field] || '' }
      onChange={ value => onChange(value, field) }
      onBlur={ onUpdate }
      max={ length }
      disabled={ readonly }
      debounceMs={ 0 }
      onKeyDown={ event => handleKeyDownNumber(event, type) }
      qaAttr={ qaAttr }
    />
  );

  const handleBlurTimeDeparture = () => {
    if (dateDeparture) {
      onUpdateTransfer(TRANSFERFIELDS.TimeDeparture, setDateArrival, setTimeArrival);
    }
  };

  const handleChangeTimeDeparture = (hours = '', minutes = '') => {
    setTimeDeparture(!hours && !minutes ? '' : `${hours}:${minutes}`);

    onChangeDate({ hours, minutes }, TRANSFERFIELDS.TimeDeparture, setDateArrival, setTimeArrival);
  };

  const handleBlurTimeArrival = () => {
    if (dateArrival) {
      onUpdateTransfer(TRANSFERFIELDS.TimeArrival, setDateArrival, setTimeArrival);
    }
  };

  const handleChangeTimeArrival = (hours = '', minutes = '') => {
    setTimeArrival(!hours && !minutes ? '' : `${hours}:${minutes}`);

    onChangeDate({ hours, minutes }, TRANSFERFIELDS.TimeArrival);
  };

  const handleChangeDateArrival = (value: Moment) => {
    setDateArrival(value);

    if (timeArrival) {
      const [hours, minutes] = timeArrival.split(':');

      return onChangeDate({ value, hours, minutes }, TRANSFERFIELDS.DateArrival);
    }

    setTimeArrival('00:00');

    return onChangeDate({ value }, TRANSFERFIELDS.DateArrival);
  };

  const handleChangeDateDeparture = (value: Moment) => {
    setDateDeparture(value);

    if (!timeDeparture) {
      setTimeDeparture('00:00');
    }

    onChangeDate({ value }, TRANSFERFIELDS.DateDeparture, setDateArrival, setTimeArrival);
  };

  const handleChangeDepartureTerminal = (value: string) => {
    setDepartureTerminal(value);

    onChangeTerminal(value, TRANSFERFIELDS.DepartureTerminal);
  };

  const handleChangeArrivalTerminal = (value: string) => {
    setArrivalTerminal(value);

    onChangeTerminal(value, TRANSFERFIELDS.ArrivalTerminal);
  };

  const renderViewersTooltip = () => {
    const renderContentTooltip = () => (
      <Text
        color='white'
        type='NORMAL_14_130'
        className={ styles['tooltip-content'] }
      >
        { LABELS.VIEWERS_TOOLTIP }
      </Text>
    );

    return (
      <Tooltip renderContent={ renderContentTooltip }>
        <IconButton iconType='question' />
      </Tooltip>
    );
  };

  const renderInsuranceTooltip = () => (
    <Text type='NORMAL_14' color='white'>{ LABELS.DISABLED_DATE }</Text>
  );

  const renderField = (field: string) => {
    const {
      ArrivalNumber,
      DateArrival,
      DateDeparture,
      Notes,
      TableSignature,
      CarriageNumber,
      EndPlace: { Type: TypeEndPlace },
      StartTerminals,
      EndTerminals,
      Viewers = [],
    } = itemData;

    const fields = { ArrivalNumber, DateArrival, DateDeparture, Notes, TableSignature, CarriageNumber, Viewers };

    let label: string | React.JSX.Element = '';
    let inputHtml = null;

    const getLabel = {
      [PLACETYPE.AIRPORT]: FIELDSLABELS[`${field}Airline` as keyof typeof FIELDSLABELS],
      [PLACETYPE.RAILSTATION]: FIELDSLABELS[`${field}Railway` as keyof typeof FIELDSLABELS],
    };

    const getLabelForTime = {
      [PLACETYPE.AIRPORT]: FIELDSLABELS[`${field}TimeAirline` as keyof typeof FIELDSLABELS],
      [PLACETYPE.RAILSTATION]: FIELDSLABELS[`${field}TimeRailway` as keyof typeof FIELDSLABELS],
    };

    switch (field) {
      case TRANSFERFIELDS.DateDeparture: {
        let labelField = '';
        let timeLabelField = '';
        labelField = getLabel[itemData.EndPlace.Type];
        timeLabelField = getLabelForTime[`${itemData.EndPlace.Type}`];

        inputHtml = (
          <div className={ styles.date }>
            <div className={ styles['date-time'] }>
              <Tooltip
                show={ isIncludeInsurance }
                renderContent={ renderInsuranceTooltip }
                bodyClassName={ styles.tooltip }
              >
                <Text className={ styles.label } color='gray'>{ labelField }</Text>
                <Datepicker
                  type='date'
                  value={ dateDeparture }
                  onChange={ handleChangeDateDeparture }
                  inputClassName={ styles.input }
                  placeholder={ FIELDSLABELS.DATE_PLACEHOLDER }
                  min={ getMoment() }
                  disabled={ readonly || isIncludeInsurance }
                  isCleansing={ !isIncludeInsurance }
                  qaAttr={ QA_ATTRIBUTES.cart.transfer.dateDeparture }
                />
              </Tooltip>
            </div>
            <div className={ styles['date-time'] }>
              <Tooltip
                show={ isIncludeInsurance }
                renderContent={ renderInsuranceTooltip }
                bodyClassName={ styles.tooltip }
              >
                <Text className={ styles.label } color='gray'>{ timeLabelField }</Text>
                <TimeInputWithoutValue
                  border
                  value={ timeDeparture }
                  onChange={ handleChangeTimeDeparture }
                  onBlur={ handleBlurTimeDeparture }
                  label={ TIME_LABEL }
                  disabled={ readonly || isIncludeInsurance }
                  max={ 5 }
                  qaAttr={ QA_ATTRIBUTES.cart.transfer.timeDeparture }
                />
              </Tooltip>
            </div>
          </div>
        );

        break;
      }

      case TRANSFERFIELDS.DateArrival: {
        let labelField = '';
        labelField = FIELDSLABELS[field as keyof typeof FIELDSLABELS];
        const disabled = ((!dateDeparture || !timeDeparture)
          && (TypeEndPlace === PLACETYPE.AIRPORT || TypeEndPlace === PLACETYPE.RAILSTATION))
          || readonly
          || isIncludeInsurance;

        inputHtml = (
          <div className={ styles.date }>
            <div className={ styles['date-time'] }>
              <Tooltip
                show={ isIncludeInsurance }
                renderContent={ renderInsuranceTooltip }
                bodyClassName={ styles.tooltip }
              >
                <Text className={ styles.label } color='gray'>{ labelField }</Text>
                <Datepicker
                  isCleansing={ !disabled }
                  type='date'
                  value={ dateArrival }
                  onChange={ handleChangeDateArrival }
                  inputClassName={ styles.input }
                  placeholder={ FIELDSLABELS.DATE_PLACEHOLDER }
                  min={ getMoment() }
                  disabled={ disabled }
                  qaAttr={ QA_ATTRIBUTES.cart.transfer.dateCarDelivery }
                />
              </Tooltip>
            </div>
            <div className={ styles['date-time'] }>
              <Tooltip
                show={ isIncludeInsurance }
                renderContent={ renderInsuranceTooltip }
                bodyClassName={ styles.tooltip }
              >
                <Text className={ styles.label } color='gray'>{ FIELDSLABELS.TimeArrival }</Text>
                <TimeInputWithoutValue
                  label={ TIME_LABEL }
                  border
                  value={ timeArrival }
                  onChange={ handleChangeTimeArrival }
                  onBlur={ handleBlurTimeArrival }
                  disabled={ disabled }
                  max={ 5 }
                />
              </Tooltip>
            </div>
          </div>
        );

        break;
      }

      case TRANSFERFIELDS.ArrivalNumber: {
        label = getLabel[itemData.StartPlace.Type];
        inputHtml = renderInput(fields, field, 10, INPUT_TYPES.TEXT, QA_ATTRIBUTES.cart.transfer.arrivalFlightNumber);

        break;
      }

      case TRANSFERFIELDS.CarriageNumber: {
        label = FIELDSLABELS[field as keyof typeof FIELDSLABELS];
        inputHtml = renderInput(fields, field, 5, INPUT_TYPES.NUMBER);

        break;
      }

      case TRANSFERFIELDS.Notes: {
        label = FIELDSLABELS[field as keyof typeof FIELDSLABELS];
        inputHtml = renderInput(fields, field, 10000);

        break;
      }

      case TRANSFERFIELDS.TableSignature: {
        label = FIELDSLABELS[field as keyof typeof FIELDSLABELS];
        inputHtml = renderInput(fields, field, 100);

        break;
      }

      case TRANSFERFIELDS.StartTerminals: {
        label = FIELDSLABELS[field as keyof typeof FIELDSLABELS];
        const terminalValues: Item[] = StartTerminals ? StartTerminals.map(value => ({ label: value, value })) : [{ label: '', value: '' }];

        inputHtml = (
          <div className={ styles.date }>
            <Select
              smooth
              theme='default-border'
              value={ arrivalTerminal || '' }
              items={ terminalValues }
              onChange={ handleChangeArrivalTerminal }
              className={ styles.terminals }
              disabled={ readonly }
            />
          </div>
        );

        break;
      }

      case TRANSFERFIELDS.EndTerminals: {
        label = FIELDSLABELS[field as keyof typeof FIELDSLABELS];
        const terminalValues: Item[] = EndTerminals ? EndTerminals.map(value => ({ label: value, value })) : [{ label: '', value: '' }];

        inputHtml = (
          <div className={ styles.date }>
            <Select
              smooth
              theme='default-border'
              value={ dapartureTerminal || '' }
              items={ terminalValues }
              onChange={ handleChangeDepartureTerminal }
              className={ styles.terminals }
              disabled={ readonly }
            />
          </div>
        );

        break;
      }

      case TRANSFERFIELDS.Viewers: {
        label = (
          <div className={ styles.viewers_label }>
            <Text color='gray'>
              { FIELDSLABELS[field as keyof typeof FIELDSLABELS] }
            </Text>
            { renderViewersTooltip() }
          </div>
        );
        inputHtml = (
          <ToDoList
            list={ viewersList }
            onAddValue={ handleAddViewers }
            onDeleteItem={ handleDeleteViewer }
            maxLengthList={ MAX_LENGTH_VIEWERS }
            editWrapperClassName={ styles['viewers-edit-wrap'] }
            listWrapperClassName={ styles['viewers-list'] }
            onBlur={ handleBlurViewerInput }
            errorMsg={ validViewerError }
            onFocus={ handleFocusViewerInput }
            disable={ readonly }
          />
        );

        break;
      }
    }

    return {
      label,
      inputHtml,
    };
  };
  const fieldsToBeRendered = prepareRequiredFields(itemData);

  const html = Object.keys(fieldsToBeRendered)
    .map((field, index) => {
      const { label, inputHtml } = renderField(field);

      const labelHTML = field === TRANSFERFIELDS.Viewers
        ? label
        : <Text className={ styles.label } color='gray'>{ label }</Text>;

      return (
        <div className={ styles.item } key={ `field_${field}_${index}` }>
          { labelHTML }
          { inputHtml }
        </div>
      );
    })
    .filter(field => field !== null);

  return (
    <div className={ styles.wrapper }>
      { html }
    </div>
  );
};

export { TransferFields };
