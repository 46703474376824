import { useEffect, useCallback } from 'react';

import CONFIG from '../../../config';

import CarrotQuest from '../../bi/services/chat/carrotQuest';

import { WorkspaceService } from '../../bi/types/workspace';

interface ICarrotQuestChat {
  carrotQuestService: CarrotQuest,
  workspaceService: WorkspaceService,
}

const CarrotQuestChat = ({
  carrotQuestService,
  workspaceService,
}: ICarrotQuestChat) => {
  const initializeCarrotQuest = useCallback(async () => {
    const { userId, hash } = await carrotQuestService.getUserAuthorizeData();
    const { Email } = workspaceService.get();

    // eslint-disable-next-line func-names
    (function () {
      // eslint-disable-next-line no-shadow,no-unused-vars,@typescript-eslint/no-shadow
      function t(t: string) {
        // eslint-disable-next-line func-names
        return function () {
          // eslint-disable-next-line prefer-rest-params
          window.carrotquestasync.push(t, arguments);
        };
      }

      if (typeof window.carrotquest === 'undefined') {
        const e = document.createElement('script');
        e.type = 'text/javascript';
        e.async = !0;
        e.src = '//cdn.carrotquest.app/api.min.js';
        document.getElementsByTagName('head')[0].appendChild(e);
        window.carrotquest = {};
        window.carrotquestasync = [];
        // eslint-disable-next-line no-undef
        window.carrotquest.settings = { messenger_mode: 'hidden' };
        for (let n = ['connect', 'track', 'identify', 'auth', 'onReady', 'addCallback', 'removeCallback', 'trackMessageInteraction'], a = 0; a < n.length; a++) {
          // eslint-disable-next-line no-undef
          window.carrotquest[n[a]] = t(n[a]);
        }
      }
    }());
    const config = {
      settings: { messenger_mode: 'hidden' },
    };
    window.carrotquest.connect(CONFIG.CARROTQUEST.APPID, config);
    window.carrotquest.auth(userId, hash);
    window.carrotquest.identify({ $email: Email, $name: Email });
  }, [carrotQuestService, workspaceService]);

  useEffect(() => {
    initializeCarrotQuest();
  }, [initializeCarrotQuest, workspaceService]);

  return null;
};

export default CarrotQuestChat;
