import { RouteComponentProps } from 'react-router-dom';
import { History, Location } from 'history';

import RequestService from '../services/requests';
import Smartdesk from '../services/smartdesk';
import FeatureFlags from '../services/featureFlags';
import { UserSessionService } from './userSession';
import AccountSettings from '../services/accountSettings';

interface PrepareTransferPlacesType {
  value: string,
  coordinates: {
    lat: string,
    lon: string,
  },
  selected: {
    Address: string
    Coordinates: {
      Latitude: string,
      Longitude: string,
    },
    Type: string,
  },
}

interface TransferFromToType extends PrepareTransferPlacesType {
  selected: {
    Address: string
    Coordinates: {
      Latitude: string,
      Longitude: string,
    },
    Type: string,
    Country: string,
  },
}

export interface ILocation {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state: any;
}

interface ISelectedSuggest {
  Address: string;
  Coordinates: {
    Latitude: string;
    Longitude: string;
  }
}

interface IHistoryItem {
  EndPlace: { Address: string },
  StartPlace: { Address: string },
}

interface IFilter {
  field: string;
  name: string;
  value: any;
}

interface ITransferItem {
  AdditionalServices: string[];
  AllowableTime: number | null;
  AlreadyInCart: boolean | null;
  CancellationTime: number | null;
  Capacity: number | null;
  Class: string;
  FavoriteId: string | null;
  IncludeTablet: boolean;
  Models: string[];
  Price: number;
  RateId: string;
  Title: string;
  TravelPolicy: {
    Apply: boolean;
    Errors: any;
  }
}

interface TransferSearch {
  value: string | null,
  suggestions: any[],
  coordinates: {
    lat: string | null,
    lon: string | null,
  },
  selected: {
    Address: string,
    Coordinates: {
      Latitude: string,
      Longitude: string,
    },
    Type: string,
  },
}

interface SuggestionType {
  BookId: string,
  IncludeTablet: boolean,
  Price: string,
  CancellationTime: number,
  AllowableTime: number,
  AdditionalServices: string[],
  RateId: string,
  TravelPolicy: {
    Apply: false,
    Errors: any,
  },
  FavoriteId: string,
}

interface RatesType {
  Capacity: number,
  Class: string,
  FavoriteId: string,
  Models: string[],
  Suggestion: SuggestionType[],
}

interface PrepareRateType {
  Title: string,
  Class: string,
  Models: string[],
  FavoriteId: string,
  Capacity: number,
  Price: number,
  CancellationTime: number,
  AllowableTime: number,
  AdditionalServices: string[],
  RateId: string,
  AlreadyInCart: boolean,
  IncludeTablet: boolean,
  TravelPolicy: {
    Apply: false,
    Errors: any,
  },
}

interface TransferType {
  DateArrival: string,
  DateDeparture: string,
  ArrivalNumber: string,
  TimeDeparture: string,
  TimeArrival: string,
}

interface SearchObject {
  StartPlace: {
    Address: string,
    Coordinates: {
      Latitude: string,
      Longitude: string,
    },
    Type: string,
  },
  EndPlace: {
    Address: string,
    Coordinates: {
      Latitude: string,
      Longitude: string,
    },
    Type: string,
  }
}

interface TagType {
  name: string,
  key: string,
  filter: string,
}

interface FilterType {
  price: {
    min: number,
    max: number,
    from: number,
    to: number,
  },
  carClass: any,
  table: boolean,
  travelPolicyList: any[],
  selectedTravelPolicy: string,
}

interface TransferSearchProps {
  transferService: any;
  userSessionService: UserSessionService,
  history: History,
  location: Location,
  featureFlagsService: FeatureFlags,
  smartdeskService: Smartdesk,
  accountSettings: AccountSettings,
  isShowTitle?: boolean,
}

interface TransferSearchMenuProps {
  subMenu?: boolean;
  onSearch(): void;
  transferService: any;
  getShowElementForSmartagent: boolean,
}

interface TransferMenuProps {
  onSearch(): void;
  swapPlaces(): void;
  updateSuggestions(type: string, value: string): void;
  selectedSuggest(type: string, item: ISelectedSuggest): void;
  renderSuggestion(address: any): JSX.Element;
  getShowElementForSmartagent: boolean,
}

interface TransferSubMenuProps extends TransferMenuProps {
  transferService: any;
}

interface SuggestItems {
  Id?: string;
  value: number;
  label: string;
}

interface TransferHistoryListProps {
  list: IHistoryItem[];
  onSearch(item: SearchObject): void;
}

interface TransferHistoryItemProps {
  item: IHistoryItem;
}

interface TransferResultPageProps {
  history: RouteComponentProps['history'];
  transferService: any;
  userSessionService: any;
  favoriteService: any;
  notificationService: any;
  match: { params: { guid: string } },
  requestsService: RequestService
  featureFlagsService: FeatureFlags,
}

interface TransferFiltersProps {
  updateFilter(item: { name: string, value: string | boolean | { value: string } | [], field?: string }): void;
  travelPolicyList: any;
}

interface TransferResultMainProps {
  onAddToShoppingCart(item: ITransferItem): void;
  onAddToNotepad(item: ITransferItem): void;
  onAddToFavorite(item: ITransferItem, action: string): void;
  onAlreadyInCart(id: string): void;
}

interface TransferItemProps extends TransferResultMainProps {
  item: ITransferItem,
  travelPolicyList: [];
  selectedTravelPolicy: string;
  index?: number;
}

interface TransferResultProps extends TransferResultMainProps {
  userSessionService: any;
  sortSearchResult(opts: string): any;
  onCloseTag(tag: TagType): void;
  onResetFilters(): void;
  updateFilter(opts: IFilter): void;
}

interface TransferItemType<T> {
  AdditionalData: string[],
  Viewers: string[] | null,
  AdditionalServices: string[],
  AllowableTime: number,
  ArrivalNumber: string,
  ArrivalTerminal: string,
  BookId: string,
  CancellationTime: number,
  Capacity: number,
  CarriageNumber: string,
  Class: string,
  DateArrival: T,
  DateDeparture: string,
  DepartureTerminal: string,
  EndPlace: {
    Address: string,
    Coordinates: {
      Latitude: string,
      Longitude: string,
    },
    Type: string,
  },
  EndTerminals: [],
  FlexibleTariff: boolean,
  FlexibleTariffAgreement: boolean,
  IncludeTablet: boolean,
  Models: string[],
  Notes: string,
  Price: {
    TotalPrice: number,
    Base: number,
    Commission: number,
  }
  ProviderName: string,
  RateId: string,
  SearchId: string,
  StartPlace: {
    Address: string,
    Coordinates: {
      Latitude: string,
      Longitude: string,
    },
    Type: string,
    TransferPlaceType: string,
  },
  StartTerminals: string[],
  TableSignature: string,
  CarClass?: string,
}

export enum AnalyticSortTransfer {
  cheaper_first = 'cheaper_first',
  more_expensive_first = 'more_expensive_first',
}

export enum AnalyticTypeClassTransfer {
  standard = 'standard',
  standard_plus = 'standard_plus',
  comfort = 'comfort',
  comfort_plus = 'comfort_plus',
  business = 'business',
  government = 'government',
  minivan = 'minivan',
  minibus = 'minibus',
}

export enum AnalyticSignTransfer {
  included = 'included',
  not_included = 'not_included',
}

export type {
  ISelectedSuggest,
  IHistoryItem,
  IFilter,
  ITransferItem,
  PrepareTransferPlacesType,
  RatesType,
  PrepareRateType,
  TagType,
  TransferSearch,
  SearchObject,
  FilterType,
  TransferType,
  TransferSearchProps,
  TransferMenuProps,
  TransferSubMenuProps,
  TransferSearchMenuProps,
  SuggestItems,
  TransferHistoryListProps,
  TransferHistoryItemProps,
  TransferResultPageProps,
  TransferFiltersProps,
  TransferItemProps,
  TransferResultProps,
  TransferItemType,
  TransferFromToType,
};
