import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../i18n';

import { getInfoByServiceType, prepareDocumentNumber } from '../../bi/utils/insurance';
import { formatDate } from '../../bi/utils/formatDate';

import { SERVICETYPE } from '../../bi/constants/serviceType';
import { PATTERN } from '../../bi/constants/dateFormats';

import { IInsuranceItemData } from '../../bi/types/insurance';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('components:insuranceItem.title'),
  RATE: getText('components:insuranceItem.rate'),
  RATENAME: {
    BASE: getText('components:insurance.rate.base.title'),
    OPTIMAL: getText('components:insurance.rate.optimal.title'),
    BUSINESS: getText('components:insurance.rate.business.title'),
  },
  SERVICES: {
    AEROEXPRESS: getText('components:insurance.titleService.aeroExpress'),
    AIR: getText('components:insurance.titleService.air'),
    CUSTOM: getText('components:insurance.titleService.custom'),
    HOTEL: getText('components:insurance.titleService.hotel'),
    TAXIVOUCHER: getText('components:insurance.titleService.taxi'),
    TRAIN: getText('components:insurance.titleService.train'),
    TRANSFER: getText('components:insurance.titleService.transfer'),
    VIPHALL: getText('components:insurance.titleService.vipHall'),
    BUS: getText('components:insurance.titleService.bus'),
  },
  NOT_DOCUMENTS: getText('components:insuranceItem.documents.noDocuments'),
};

const DOCUMENTS = {
  1: getText('components:insuranceItem.documents.passport'),
  2: getText('components:insuranceItem.documents.foreignPassport'),
  3: getText('components:insuranceItem.documents.birthCertificate'),
  4: getText('components:insuranceItem.documents.domesticPassport'),
};

interface ITraveller {
  AllDocuments: IDocument[],
  Birthday: string,
  Name: string,
  Patronymic?: string,
  Surname: string,
}

interface IDocument {
  Number: string,
  Type: number,
}

interface InsuranceItemProps {
  item: IInsuranceItemData;
}

const InsuranceItem = ({
  item,
}: InsuranceItemProps) => {
  const renderTravellers = (travellers: ITraveller[], ServiceType: string) => travellers
    .map(({ Name, Surname, Patronymic = '', Birthday, AllDocuments }, idx) => {
      let textDocuments = '';

      if (AllDocuments) {
        let document = AllDocuments.find(({ Type }) => Type === 1);

        const isRuPassport = !!document;
        document ??= AllDocuments[0];

        if (!AllDocuments.length) {
          return LABELS.NOT_DOCUMENTS;
        }

        const number = prepareDocumentNumber(document.Number, isRuPassport);

        if (ServiceType !== SERVICETYPE.HOTEL &&
          ServiceType !== SERVICETYPE.TRANSFER &&
          ServiceType !== SERVICETYPE.TAXI_VOUCHER &&
          ServiceType !== SERVICETYPE.AEROEXPRESS) {
          textDocuments = ` ${DOCUMENTS[document.Type as keyof typeof DOCUMENTS]} ${number}, `;
        }
      }

      return (
        <Text key={ idx } type='NORMAL_16' color='gray'>
          { `${Surname} ${Name.slice(0, 1)}.${Patronymic.slice(0, 1)}` },
          { textDocuments }
          { formatDate(Birthday, PATTERN.SHORT_FULL_YEAR) }
        </Text>
      );
    });

  const renderItemsByType = () => item.InsuranceServices.map(({ ServiceType, Data }, idx) => {
    const data = JSON.parse(Data);

    const upperServiceType = ServiceType.toUpperCase();
    const { Travellers } = data;

    return (
      <div key={ idx } className={ styles.item_wrapper }>
        <Text type='bold_16'>{ LABELS.SERVICES[upperServiceType as keyof typeof LABELS.SERVICES] } </Text>
        <div className={ styles.info }>
          <Text type='NORMAL_16_130' color='gray'>{ getInfoByServiceType(ServiceType, data) }</Text>
          { renderTravellers(Travellers, ServiceType) }
        </div>
      </div>
    );
  });

  const rateName = item.Rate.toUpperCase();
  const insuranceServices = item.InsuranceServices ? renderItemsByType() : null;

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.title }>
        <Text type='SEMIBOLD_16'>{ LABELS.TITLE }</Text>
        <Text type='SEMIBOLD_16'>{ LABELS.RATE } { LABELS.RATENAME[`${rateName}` as keyof typeof LABELS.RATENAME] }</Text>
      </div>
      <div className={ styles.items }>
        { insuranceServices }
      </div>
    </div>
  );
};

export { InsuranceItem };
