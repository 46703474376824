import React, { useEffect } from 'react';
import { RadioButton, Text } from 'new-ui';
import { observer } from 'mobx-react';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';
import { useServices } from '../../../../bi/context/services';

import { InformationIcon } from '../../../InformationIcon';
import { TooltipTextUPD, TooltipTextVisa, WarningText } from './components';

import {
  TRANSIT_DOCUMENTS,
  PERMISSION_DOCUMENT_VARIANTS,
  SIMPLIFIED_DOCUMENT_VARIANTS,
} from './constants';
import {
  EKaliningradRadioVariantsRequest,
  EKaliningradRadioVariantsResponse,
} from '../../../../bi/constants/train';

import styles from '../../styles/index.module.css';

interface ITransitionRadioButtonsProps {
  ticketId: string;
  employeeId: string;
  isForeignCitizen: boolean;
}

export const TransitionRadioButtons = observer(({
  ticketId,
  employeeId,
  isForeignCitizen,
}: ITransitionRadioButtonsProps) => {
  const {
    trainsService: { setTrainTransitionDocument, addTrainTransitDocument },
  } = useServices(['Trains']);

  const { trainStore: { transitTrainDocuments } } = useStores([MOBX_STORES.TRAIN]);

  const transitDocumentEmployee = transitTrainDocuments?.[ticketId]?.[employeeId];

  useEffect(() => {
    if (isForeignCitizen) {
      setTrainTransitionDocument(
        EKaliningradRadioVariantsRequest.Permission,
        ticketId,
        employeeId,
      );
      addTrainTransitDocument(
        ticketId,
        employeeId,
        EKaliningradRadioVariantsRequest.Permission,
      );
    }
  }, [isForeignCitizen, transitDocumentEmployee]);

  const transitDocumentsSelected = !!(transitDocumentEmployee
    && transitDocumentEmployee !== EKaliningradRadioVariantsResponse.NoValue);

  const onRadioChange = (value: string) => {
    setTrainTransitionDocument(value, ticketId, employeeId);
    addTrainTransitDocument(ticketId, employeeId, value);
  };

  const renderSimplifiedDocumentButton = () => {
    if (isForeignCitizen) return null;

    return (
      <div className={ styles.radio_wrapper }>
        <RadioButton
          value={ EKaliningradRadioVariantsRequest.SimplifiedDocument }
          checked={ SIMPLIFIED_DOCUMENT_VARIANTS.includes(transitDocumentEmployee) }
          onChange={ onRadioChange }
        >
          <Text>{ TRANSIT_DOCUMENTS.UPD }</Text>
        </RadioButton>
        <InformationIcon
          className={ styles.tooltip }
          text={ TooltipTextUPD() }
          iconClassName={ styles.icon }
        />
      </div>
    );
  };

  return (
    <div className={ styles.transit_document }>
      { renderSimplifiedDocumentButton() }
      <div className={ styles.radio_wrapper }>
        <RadioButton
          value={ EKaliningradRadioVariantsRequest.Permission }
          checked={ PERMISSION_DOCUMENT_VARIANTS.includes(transitDocumentEmployee) }
          onChange={ onRadioChange }
        >
          <Text>{ TRANSIT_DOCUMENTS.VISA }</Text>
        </RadioButton>
        <InformationIcon
          className={ styles.tooltip }
          text={ TooltipTextVisa() }
          iconClassName={ styles.icon }
        />
      </div>
      <WarningText transitDocumentsSelected={ transitDocumentsSelected } />
    </div>
  );
});
