import { observable, action, makeObservable } from 'mobx';

import { ICarPlacesDetails, IInternalTrainDetailsClientData } from '../../../types/trains';

export type TTrainStore = typeof TrainStore;

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable loading = true;
  @observable loadingCarDetails = true;
  @observable train: IInternalTrainDetailsClientData | null = null;
  @observable car: ICarPlacesDetails | null = null;
  @observable transitTrainDocuments: Record<string, Record<string, string>> = {};

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  setTrainTransitionDocument = (documentType: string, ticketId: string, employeeId: string) => {
    this.transitTrainDocuments[ticketId] = this.transitTrainDocuments[ticketId] || {};
    this.transitTrainDocuments[ticketId][employeeId] = documentType;
  };

  @action
  removeEmployeeFromTransitDocuments = (ticketId: string, employeeId: string) => {
    if (!this.transitTrainDocuments[ticketId]) return;

    /** Создаем новый объект для сотрудников, исключая текущего сотрудника */
    const updatedEmployees = Object.entries(this.transitTrainDocuments[ticketId])
      .reduce((acc, [empId, transitDocuments]) => {
        if (empId !== employeeId) {
          acc[empId] = transitDocuments;
        }

        return acc;
      }, {} as Record<string, string>);

    /** Если после удаления сотрудника остаются записи, обновляем ticketId */
    if (Object.keys(updatedEmployees).length > 0) {
      this.transitTrainDocuments[ticketId] = updatedEmployees;

      return;
    }

    const { [ticketId]: _, ...remainingDocuments } = this.transitTrainDocuments;
    this.transitTrainDocuments = remainingDocuments;
  };

  @action
  setLoadingCarDetails = (value: boolean) => {
    this.loadingCarDetails = value;
  };

  @action
  setTrainInfo = (train: IInternalTrainDetailsClientData) => {
    this.train = train;
  };

  @action
  setPlaceDetails = (car: ICarPlacesDetails) => {
    this.car = car;
    this.setLoadingCarDetails(false);
  };

  @action
  setTrainFavorite = (value: string) => {
    // @ts-ignore
    this.train = {
      ...this.train,
      FavoriteId: value,
    };
  };
}

const TrainStore = new Store();

export { TrainStore };
