import { TravelPolicyItem } from '../services/userSession/types';

export interface IFreePlace {
  altPrice: number,
  buyFull: boolean,
  buyFullPrices: Record<number, number>,
  buyFullAltPrices: Record<number, number>,
  hasAlternative: boolean,
  nonRefundable: boolean,
  places?: string[],
  price: number,
  travellers: number,
  number: number,
  litter?: string,
  readOnly?: boolean,
  agentFee?: number,
  altFee?: number,
  qaAttr?: string,
}

export interface ISelectedPlace {
  number: number;
  price?: number;
  nonRefundable?: boolean;
  hasAlternative?: boolean;
  altPrice?: number;
  altFee?: number;
  SpecialTariff?: string;
  CompartmentNumber?: string;
  agentFee?: number;
  compartmentAgentFee?: number;
}

interface ICarCss {
  width: number;
  height: number;
  top: number;
  left: number;
}

export interface ICompartDetails {
  compartmentAltPrice?: number,
  compartmentPrice?: number;
  specialTariff?: string,
}

export interface ICompartSection {
  CompartmentNumber?: string,
  SpecialTariff?: string,
  buyFullCompartment?: boolean,
  compartmentAltPrice?: number,
  compartmentPlaces?: string[],
  compartmentPrice?: number,
  hasAlternative?: boolean,
  compartmentAgentFee?: number,
  compartmentAltFee?: number,
  miniSections?: ICompartSection[],
}

interface IShemePlace {
  css: ICarCss,
  disabled: boolean,
  number: number,
  selected: boolean,
  type: string,
}

export interface IShemeSection {
  css: ICarCss,
  number: number,
  places: IShemePlace[],
}

interface IAnimal {
  css: ICarCss,
}

export interface ILevel {
  count: number,
  perSection: number,
  scheme: string,
  sections: IShemeSection[],
  info: any[],
  animal: IAnimal[],
}

export interface IPlacesInfo {
  Number: string,
  SeatType: string,
}

export interface ICarJsonData {
  type: string;
  levels: ILevel[],
}

export interface ISection {
  css: ICarCss,
  litter: string,
  miniSections?: ICompartSection[],
  number: number,
  places: IPlace[],
  CompartmentNumber?: string,
  SpecialTariff?: string,
  buyFullCompartment?: boolean,
  compartmentAltPrice?: number,
  compartmentPlaces?: string[],
  compartmentPrice?: number,
  hasAlternative: boolean,
  compartmentAgentFee: number,
  compartmentAltFee: number,
}

export interface IPlace {
  number: number,
  altPrice?: number,
  altFee?: number,
  buyFullPrices?: Record<number, number>,
  buyFullPlaces?: number[] | null;
  buyFullAltPrices?: Record<number, number>,
  hasAlternative?: boolean,
  nonRefundable?: boolean,
  price?: number,
  travellers?: number,
  disabled: boolean,
  compartDetails: ICompartDetails | null;
  css?: ICarCss,
  type: string,
  selected: boolean,
  qaAttr?: string,
  readOnly?: boolean,
  alwaysDisabled?: boolean,
  agentFee: number | string,
}

export interface IEntireCompartment {
  selectedPlace: ISelectedPlace[];
  entireCompartment: boolean;
  initialSelectedPlace: ISelectedPlace[];
  differentTrains: boolean,
  placesByCompartmentPrices: IPlacesByCompartmentPriceClientData[];
  setEntireCompartment: (value: boolean) => void;
}

export type IArrSourcesWithTransfer = IVariantWithTransfer[][];

export interface ISortSelectItem {
  label: string,
  type: number | null,
  value: string,
  analyticValue?: AnalyticSortTrain,
}

export interface TrainDetails {
  TrainId: number;
  DepartureDateLocal: string;
  DepartureDate: string;
  ArrivalDateLocal: string;
  ArrivalDate: string;
  StationFrom: string;
  StationTo: string;
  ChangeDuration: number;
  TravelTime: number;
  Cars: ICar[];
  TrainNumber: number;
  StationCodeFrom: string;
  StationCodeTo: string;
}

export enum AnalyticSortTrain {
  travel_time_ascending = 'travel_time_ascending',
  travel_time_descending = 'travel_time_descending',
  departure_time_descending = 'departure_time_descending',
  departure_time_ascending = 'departure_time_ascending',
  arrival_time_descending = 'arrival_time_descending',
  arrival_time_ascending = 'arrival_time_ascending',
  cheaper_first = 'cheaper_first',
  more_expensive_first = 'more_expensive_first',
}

export interface ITypeValue {
  type: string,
  value: boolean,
}

export interface IPriceFilter {
  border: {
    from: number;
    to: number;
  };
  roundBy: number;
  from: number;
  to: number;
}

interface ITimeFilter {
  border: {
    from: number;
    to: number;
  };
  from: number;
  to: number;
}

interface IFilterTime {
  departure: ITimeFilter,
  arrival: ITimeFilter,
}

export interface ITypeCar {
  [key: string]: boolean;
}

export interface ITransfers {
  [key: string]: TransferDetail;
}

interface TransferDetail {
  name: string;
  selected: boolean;
}

export interface IFilters {
  type: ITypeCar;
  trainNumberOrName: string;
  isSapsan: boolean;
  price: IPriceFilter;
  time: IFilterTime;
  travelPolicyList: string[];
  selectedTravelPolicy: string;
  number: string;
  date: string;
  favoriteId: boolean;
  transfers: ITransfers;
}

export type TimeFilterType = 'departure' | 'arrival';

export interface ITransfersFilter {
  transfers: string,
  value: boolean,
}

interface IRightsBuyTrip {
  AccountId: number,
  EmployeeId: number,
  UserId: number | null,
  Login: string,
  CanLogin: boolean,
  ViewTrips: string,
  BuyTripPersonal: string,
  BuyTripAccount: string,
  Limits: any | null,
  Approve: string,
  Finance: string,
  EditRights: string,
  Notifications: string,
  EditEmployee: string,
  EditTravelPolicies: boolean,
  SendDocuments: boolean,
  CanUseIntercom: boolean,
  ViewExpenseReports: string,
  ApproveExpenseReports: string,
  CreateExpenseReports: string,
  CanBook: boolean,
}

export interface ISettingsBuyTrain {
  accountTravelPolicy: TravelPolicyItem,
  rightsBuyTrip: IRightsBuyTrip,
  travelPolicyAllList: TravelPolicyItem[],
}

export interface IFavorite {
  number: null | number,
  date: null | string
}

export interface ICarItem {
  CarNumber: string;
  ClassService: string;
  Bedding: boolean;
  OneCompartment: null | string | number;
  Up: string;
  Down: null | string | number;
  parentCar: ICarPlacesDetails;
  IsAlternative: boolean;
}

export interface IPlacesItem {
  place: string[];
  many: boolean;
}

export interface ISavedTicket {
  car: ICarItem;
  places: IPlacesItem[];
  totalPrice: number,
  train: IInternalTrainDetailsClientData;
  searchId: string;
  travellers: number;
  tripId: string | null;
  TrainId: number;
  idRequestItem: number;
}

export interface SavedTicket {
  CarType: string;
  StationDepart: string;
  StationArrive: string;
  TrainId: number;
  totalPrice: number;
  travellers: number;
}

// search
export interface ISearchResult {
  Id: string;
  Trains: ISearchTrain[]
  TrainsWithTransfer: IVariantWithTransfer[][]
}

export interface IInternalSearchResult extends ISearchResult {
  Trains: IInternalSearchTrain[];
}

export interface ISearchTrain {
  TrainId: number;
  ProviderName: string;
  ProviderSource: string;
  TrainName: string;
  Route: string[];
  TrainNumber: string;
  TrainNumberLocal: string;
  StationFrom: string;
  StationCodeFrom: string;
  StationParentCodeFrom: string;
  StationTo: string;
  StationCodeTo: string;
  StationParentCodeTo: string;
  DepartureDate: string;
  DepartureDateLocal: string;
  ArrivalDate: string;
  ArrivalDateLocal: string;
  TravelTime: string;
  Cars: ICar[];
  FavoriteId: string;
  TravelPolicy: ITravelPolicy;
  ERegistration: boolean;
  ChangeDuration: number;
  NonRefundable: boolean;
  IsSuburban: boolean;
}

export interface IInternalSearchTrain extends ISearchTrain {
  SearchId: string;
}

export interface ITravelPolicy {
  Apply: boolean;
  Errors: Record<string, string>
}

export interface ICar {
  Type: string;
  TypeIndex: number;
  FreePlaces: number;
  MinimalCost: IPriceDetails;
}

export interface IPriceDetails {
  Base: number;
  Fee: number;
  Commission: number;
  Total: number;
  AgentFee: number;
}

export interface IVariantWithTransfer {
  Id: number;
  TripDuration: number;
  Classes: IGroupedCar[];
  Trains: ISearchTrain[];
}

export interface IGroupedCar {
  Type: string;
  FreePlaces: number;
  Price: number;
  AgentFee: number;
}

// autocomplete
export interface ITrainAutocompleteItem {
  Name: string;
  Code: string;
  Hint: string;
  BaseCode: string;
}

export interface IFromTo {
  label: string,
  selected: ITrainAutocompleteItem | object,
  suggests: any[],
  loading?: boolean,
}

// getSearchHistory
export interface ITrainHistoryItem {
  StationFrom: ITrainPlace;
  StationTo: ITrainPlace;
  DepartureDate?: string;
  Travellers?: number;
}

export interface ITrainPlace {
  City: string;
  Code: string;
  CountryCode?: string;
  Name?: string;
}

// saveCarsWithoutSchemaStats
export interface ICarWithoutSchemaRequest {
  Date: string;
  TrainNumber: string;
  CarNumber: string;
  FullRoute: string;
  SelectedRoute: string;
  Login?: string;
  CompanyId: number ;
  Event: number;
}

export interface ICarWithoutSchemaResponse {
  Id: string;
}

// /trains/${searchId}/${trainId}/places/v2
export interface ITrainPlacesDetailsClientData {
  Train: ITrainDetailsClientData;
  SearchRequest: ISearchDataClientData;
}

export interface ISearchDataClientData {
  StationFrom: ITrainPlaceClientData;
  StationTo: ITrainPlaceClientData;
  DepartureDate: string;
  Travellers: number
}

export interface ITrainPlaceClientData {
  City: string;
  Code: string;
  Name: string;
}

interface ITrainDetailsClientData {
  SearchId: string;
  TrainId: number;
  Number: string;
  Number2: string;
  Type: string;
  Route: string[];
  DepartureDate: string;
  DepartureDateLocal: string;
  ArrivalDate: string;
  ArrivalDateLocal: string;
  TravelTime: string;
  Length: string;
  ERegistration: boolean;
  Brand: string;
  FirmName: string;
  StationDepart: string;
  StationFrom: string;
  StationArrive: string;
  StationTo: string;
  Cars: ICarDetailClientData[];
  TravelPolicy: ITravelPolicyCarTariffClient,
  FavoriteId: string
}

export interface IInternalTrainDetailsClientData extends ITrainDetailsClientData {
  Cars: IInternalCarDetailClientData[];
  StationCodeFrom: string;
  StationCodeTo: string;
}
interface ICarDetailClientData {
  Number: string;
  Tariffs: ICarTariffClientData[];
}

export interface IInternalCarDetailClientData extends ICarDetailClientData {
  Tariffs: IInternalTCarTariffClientData[];
}

interface ICarTariffClientData {
  Id: number;
  Type: string;
  TypeShow: string;
  TypeIndex: number;
  TrainLetter: string;
  ArrivalDate: string;
  ArrivalDateLocal: string;
  ClassService: string;
  ClassServiceDescription: string;
  AddSigns: string;
  Tariff: IPriceDetails;
  Tariff2: IPriceDetails;
  TariffService: string;
  Carrier: string;
  Owner: string;
  BuyTwo: number;
  ERegistration: boolean;
  NonRefundable: boolean;
  Modificator: ICarModificatorClientData;
  Seats: ISeatDetails;
  Places: string[];
  CarDetails: string;
  HaveInvalidPlaces: boolean; // Места для инвалидов
  HavePetsPlaces: boolean; // Места для пассажиров с животными
  HaveAdultsWithChildrenPlaces: boolean; // Места для пассажиров с детьми
  AroundHavePatsPlaces: boolean; // Места рядом с местами для пассажиров с животными
  PlacesForMotherWithChild: boolean; // Места для матери и ребенка
  TravelPolicy: ITravelPolicyCarTariffClient
}

export interface IInternalTCarTariffClientData extends ICarTariffClientData {
  Number: string;
  tariffDescription?: IInternalCarriageResponse | undefined;
}
export interface ICarModificatorClientData {
  Type: string;
  TimeInWay: string
  Length: string;
  Arrival: ICarModificatorArrivalClientData
}

export interface ICarModificatorArrivalClientData {
  ArrivalDate: string;
  ArrivalLocalDate: string;
  // [JsonIgnore]
  Date: string;
  // [JsonIgnore]
  LocalDate: string;
  // [JsonIgnore]
  Time: string;
  // [JsonIgnore]
  LocalTime: string;
  GetDateTime: string;
}

interface ITravelPolicyCarTariffClient {
  Apply: boolean;
  Dictionary: Record<string, string>;
}

export interface ISeatDetails {
  Down: number | string;
  Up: number;
  FreeCompartments: number;
  Undef: number | string;
}

// trains/${searchId}/${trainId}/places/details/${carNumber}/${carId}
export interface ICarPlacesDetailsClientData {
  TrainNumber: string;
  FirmName: string;
  CarNumber: string;
  CarType: string
  PlacesPrices: IPlacesPriceClientData[];
  PlacesByCompartmentPrices: IPlacesByCompartmentPriceClientData[];
  ERegistration: boolean;
}

export interface IPlacesPriceClientData {
  Places: string[];
  PriceDetails : IPriceDetails;
  AltPriceDetails : IPriceDetails;
  NonRefundable: boolean
  HasAlternative: boolean;
  BuyFull: boolean;
  BuyFullPrices: Record<number, number>; // Dictionary<int, decimal>
  BuyFullAltPrices: Record<number, number>;
  TravelPolicy: ITravelPolicyCarTariffClient;
  AltTravelPolicy: ITravelPolicyCarTariffClient;
}

export interface IPlacesByCompartmentPriceClientData {
  PlacesByCompartment : IPlacesByCompartmentClientData;
  TravellersCount: number;
  SpecialTariff: string;
  BuyFull: boolean;
  NonRefundable: boolean;
  BuyFullPriceDetails: IPriceDetails;
  BuyFullAltPriceDetails: IPriceDetails;
}

interface IPlacesByCompartmentClientData {
  CompartmentNumber: string;
  Places: string[];
}

// /train-scheme/train/${trainNumber}/carScheme/?lang=${currentLng}
export interface ICarScheme {
  scheme: any;
  type: string
  carJSON: string;
  carImage: string[];
  direction: string
}

// /train-service/carriage
export interface ICarriageResponse {
  Description: string;
  Services: string[];
}

export interface IInternalCarriageResponse extends ICarriageResponse {
  carNumber: string;
  id: number;
}

// /informant/active/railway
export interface IWidget {
  id: number;
  type: string;
  isActive: boolean;
  content: any;
}

export interface ICarPlacesDetails {
  Places: string[];
  Type: string;
  Number: string,
  FirmName: string
  PlacesPrices: IPlacesPriceClientData[];
  TrainNumber: string;
  trainType: string;
  carJSON: any;
  carImage: string[];
  direction: string;
  PlacesByCompartmentPrices: IPlacesByCompartmentPriceClientData[];
  description?: string;
  BuyTwo?: number; // ????
  ClassService?: string;
  Seats?: ISeatDetails;
  TypeShow?: string;
}

export interface ICarDescriptionParams {
  Number: string;
  Carrier: string;
  ClassService: string;
  AddSigns: string;
}

export interface ITransitDocument {
  TransitDocumentType: string;
  CartItemId: string;
  EmployeeId: string;
}

type TTransitDocRadioButton = {
  text: string;
  checked: boolean;
};

export type TTransitDocumentButtons = Record<string, TTransitDocRadioButton>;
