const ALL = 'All';

const FILTERS_TYPES = {
  NOTE: 'note',
  SERVICE: 'service',
};

enum PHANTOM_STATUS_ENUM {
  NORMAL = 0,
  PHANTOM = 1,
  PHANTOM_DELETED = 2,
}

const BOOKING_ERRORS_CODES = {
  PRICE_CHANGED: 'ReservedWithPriceChange',
  ERROR: 'ReservationError',
  RESERVED: 'Reserved',
};

const FILTERS_KIND = {
  ALL: 'all',
  OWN: 'own',
  ANOTHER: 'anotheruser',
};

export {
  ALL,
  FILTERS_TYPES,
  PHANTOM_STATUS_ENUM,
  FILTERS_KIND,
  BOOKING_ERRORS_CODES,
};
