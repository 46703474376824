import { Moment } from 'moment';

import { getText, getTextArray } from '../../../i18n';

import { ISourcesEmployees } from '../services/checkout/types';

import { getEmployeeFullName } from './employees';

import CONFIG from '../../../config';

import { CartConflicts, TripConflicts } from '../../components/DuplicateDialog';

import {
  AIRLINETYPES,
  BANKRUPTCY_AIRLINES,
  OPERATINGEXCEPTIONS,
  PRICE_AIR_CERTIFICATE,
  AIRLINE_IDS,
  CANCEL_RESERVED,
  AIRLINE_CLASSES_MAP,
} from '../constants/airline';
import { DATEFORMATS, PATTERN } from '../constants/dateFormats';

import {
  dateWithoutMoment,
  formatDate,
  momentObject,
  trimTimeZone,
  getMoment,
  textualMonthWithHoursAndMinutesPattern,
  hoursAndMinutesPattern,
} from './formatDate';
import { secondsToLabel } from './time';
import parseUnix from './parseDateTime';
import parseJsonString from './parseJsonString';
import toDecline from './toDecline';
import { SERVICETYPE } from '../constants/serviceType';

import {
  AirRoute,
  AirSearchRoute,
  AirSegment,
  MarketingAirlineType,
  OperatingAirlineType,
  AirlineFare,
} from '../types/airline';
import { OrderItem } from '../types/order';
import { IAirCommonType } from '../types/favorites';
import { IPrepareNoteItem } from '../services/notepad/types';

const LABELS = {
  todayUntil: (time: string) => getText('utils:airline.todayUntil', { time }),
  until: getText('utils:airline.until'),
  minutes30: getText('utils:airline.30minutes'),
  hoursDeclines: getTextArray('utils:airline.hoursDeclines'),
  duringCheckout: (timeLimit: string) => getText('utils:airline.duringCheckout', { timeLimit }),
};

const SECONDSINONEHOUR = 3600;

const getAirlineType = (
  { OperatingAirline, MarketingAirline }:
  { OperatingAirline: OperatingAirlineType | IAirCommonType, MarketingAirline: MarketingAirlineType | IAirCommonType },
): 'MarketingAirline' | 'OperatingAirline' => {
  const { OPERATINGAIRLINE, MARKETINGAIRLINE } = AIRLINETYPES;

  const { ID: oId } = OperatingAirline;
  const { ID: mId } = MarketingAirline;

  return OPERATINGEXCEPTIONS[oId as 'FV'] && OPERATINGEXCEPTIONS[oId as 'FV'] === mId ? OPERATINGAIRLINE : MARKETINGAIRLINE;
};

const getAirlineIdOrCode = (segment: AirSegment) => {
  const airlineType = getAirlineType(segment);

  return segment[airlineType].ID || segment[airlineType].Code;
};

const infoTerminal = () => ({
  terminal: '',
  different: false,
});

const prevSegmentId = (index: number) => index - 1;

const nextSegmentId = (index: number) => index + 1;

const anotherSegment = (segments: AirSegment[], prevOrNext: number) => segments[prevOrNext];

const isDepartureChanged = (index: number, segment: AirSegment, segments: AirSegment[]) => {
  if (prevSegmentId(index) >= 0) {
    const arrival = segments[prevSegmentId(index)].ArrivalAirport.ID || segments[prevSegmentId(index)].ArrivalAirport.Code;
    const departure = segment.DepartureAirport.ID || segment.DepartureAirport.Code;

    return arrival !== departure;
  }

  return false;
};

const isArrivalChanged = (index: number, segment: AirSegment, segments: AirSegment[]) => {
  if (nextSegmentId(index) < segments.length) {
    const arrival = segment.ArrivalAirport.ID || segment.ArrivalAirport.Code;
    const departure = segments[nextSegmentId(index)].DepartureAirport.ID || segments[nextSegmentId(index)].DepartureAirport.Code;

    return arrival !== departure;
  }

  return false;
};

const getTerminals = (segment: AirSegment, index: number, segments: AirSegment[]) => {
  const arrival = infoTerminal();
  const departure = infoTerminal();
  const { ArrivalTerminal, DepartureTerminal } = segment;

  arrival.terminal = ArrivalTerminal || '';
  departure.terminal = DepartureTerminal || '';

  if (segments.length > 1) {
    const nextSegment = anotherSegment(segments, nextSegmentId(index));
    const prevSegment = anotherSegment(segments, prevSegmentId(index));

    arrival.different = !!(nextSegmentId(index) < segments.length
      && ArrivalTerminal
      && nextSegment.DepartureTerminal
      && ArrivalTerminal !== nextSegment.DepartureTerminal
      && !isArrivalChanged(index, segment, segments));

    departure.different = !!(prevSegmentId(index) >= 0
      && DepartureTerminal
      && prevSegment.ArrivalTerminal
      && DepartureTerminal !== prevSegment.ArrivalTerminal
      && !isDepartureChanged(index, segment, segments));
  }

  return {
    arrival,
    departure,
  };
};

const airlineIsBankruptcy = (id: string) => BANKRUPTCY_AIRLINES.includes(id);

const getNameByFirstSegment = (segment: AirSegment) => segment[getAirlineType(segment)].Name;

const getUniqueBankruptcyNames = (routes: AirRoute[]) => routes.reduce<string[]>((result, item) => {
  const { Segments } = item;

  const firstSegment = Segments[0];
  const airlineId = firstSegment[getAirlineType(firstSegment)].ID;
  const isBankruptcy = airlineIsBankruptcy(airlineId);
  const airlineName = getNameByFirstSegment(firstSegment);

  return !result.includes(airlineName) && isBankruptcy
    ? [...result, airlineName]
    : result;
}, []);

const segmentsArrivalCity = (segments: AirSegment[]) => segments.reduce<string[]>((acc, segment, index) => {
  if (index !== segments.length - 1
    && segment.ArrivalAirport.ID !== segments[index + 1].DepartureAirport.ID
    && segment.ArrivalCity === segments[index + 1].DepartureCity
  ) {
    return [...acc, segment.ArrivalCity];
  }

  return acc;
}, []);

const mappedSegmentsFromTrip = (segment: AirSegment) => {
  const depDate = segment.DepartureDate ? momentObject(trimTimeZone(segment.DepartureDate)) : null;
  const arrDate = segment.ArrivalDate ? momentObject(trimTimeZone(segment.ArrivalDate)) : null;
  const airlineType = getAirlineType(segment);

  return {
    airlineId: segment[airlineType].Code,
    departureAirportId: segment.DepartureAirport.Code ? `, ${segment.DepartureAirport.Code}` : null,
    arrivalAirportId: segment.ArrivalAirport.Code ? `, ${segment.ArrivalAirport.Code}` : null,
    departureTime: formatDate(depDate, hoursAndMinutesPattern),
    arrivalTime: formatDate(arrDate, hoursAndMinutesPattern),
    departureDate: formatDate(depDate, PATTERN.DAY_OF_MONTH),
    arrivalDate: formatDate(arrDate, PATTERN.DAY_OF_MONTH),
    departureWeek: formatDate(depDate, PATTERN.WEEK),
    arrivalWeek: formatDate(arrDate, PATTERN.WEEK),
  };
};

const mappedSegments = (segment: AirSegment) => {
  const airlineType = getAirlineType(segment);

  return {
    airlineId: segment[airlineType].ID,
    departureAirportId: segment.DepartureAirport.ID ? `, ${segment.DepartureAirport.ID}` : null,
    arrivalAirportId: segment.ArrivalAirport.ID ? `, ${segment.ArrivalAirport.ID}` : null,
    departureTime: dateWithoutMoment(segment.DepartureTime, DATEFORMATS.TIME),
    arrivalTime: dateWithoutMoment(segment.ArrivalTime, DATEFORMATS.TIME),
    departureDate: dateWithoutMoment(segment.DepartureTime, PATTERN.DAY_OF_MONTH),
    arrivalDate: dateWithoutMoment(segment.ArrivalTime, PATTERN.DAY_OF_MONTH),
    departureWeek: dateWithoutMoment(segment.DepartureTime, PATTERN.WEEK),
    arrivalWeek: dateWithoutMoment(segment.ArrivalTime, PATTERN.WEEK),
  };
};

const getPriceWithCertificate = (count: number, price = 0) => price + (PRICE_AIR_CERTIFICATE * count);

const getPriceAllCertificates = (items: ({ Data: string })[]) => items.reduce<number>((acc, item) => {
  const jsonData = JSON.parse(item.Data);
  const { FlightCertificate, Metadata } = jsonData;
  const TravellersCount = Metadata ? Metadata.TravellersCount : 1;

  return FlightCertificate ? acc + (PRICE_AIR_CERTIFICATE * TravellersCount) : acc;
}, 0);

const getAirTripItemId = (
  tripItem: {
    ActualVersion: {
      ServiceType: string;
      JsonData: string;
    };
  }) => {
  if (tripItem.ActualVersion.ServiceType === SERVICETYPE.FLIGHT_CERTIFICATE) {
    const { AirTripItemId } = JSON.parse(tripItem.ActualVersion.JsonData);

    return AirTripItemId || null;
  }

  return null;
};

const isAirTicketHasCertificate = (
  items: {
    ActualVersion: {
      ServiceType: string;
      JsonData: string;
    }
  }[],
  currentId: number,
) => items.some(item => currentId === getAirTripItemId(item));

const getAllAirIds = (orderItems: OrderItem[]) => orderItems
  .filter(item => item.ActualVersion.ServiceType === SERVICETYPE.AIR)
  .map(({ Id }) => Id);

const getAllFlightCertificateIds = (orderItems: OrderItem[]) => orderItems
  .filter(({ ActualVersion: { ServiceType } }) => ServiceType === SERVICETYPE.FLIGHT_CERTIFICATE)
  .map(({ ActualVersion: { JsonData } }) => JSON.parse(JsonData).AirTripItemId);

const compareByAirCertificate = (a: OrderItem, b: OrderItem) => {
  const airTripItemIdA = getAirTripItemId(a);
  const airTripItemIdB = getAirTripItemId(b);

  if (!airTripItemIdA && !airTripItemIdB) {
    return a.Id - b.Id;
  }

  if (!airTripItemIdA) {
    const id1 = a.Id;
    const id2 = airTripItemIdB;

    if (id1 === id2) {
      return -1;
    }

    return id1 - id2;
  }

  if (!airTripItemIdB) {
    const id1 = airTripItemIdA;
    const id2 = b.Id;

    if (id1 === id2) {
      return 1;
    }

    return id1 - id2;
  }

  return airTripItemIdA - airTripItemIdB;
};

const getVoidUntilEndOfDayString = (voidTimeLimitUTC: number) => {
  const currentDate = getMoment();
  const voidTimeLimit = parseUnix(voidTimeLimitUTC).local();

  const isElapsed = voidTimeLimit.isBefore(currentDate);

  if (isElapsed) {
    return null;
  }

  const isSameDay = currentDate.isSame(voidTimeLimit, 'day');

  if (isSameDay) {
    return LABELS.todayUntil(voidTimeLimit.format(hoursAndMinutesPattern));
  }

  return `${LABELS.until} ${voidTimeLimit.format(textualMonthWithHoursAndMinutesPattern)}`;
};

const getVoidTimerStartsAfterBookingString = (voidTimeLimitTimestamp: number, serverTime: Moment) => {
  const voidTimeLimitUTC = parseUnix(voidTimeLimitTimestamp);

  const diffInHours = (voidTimeLimitUTC.unix() - serverTime.unix()) / SECONDSINONEHOUR;
  const roundedDiffInHours = Math.round(diffInHours);

  const voidTimeLimitString = diffInHours < 1
    ? LABELS.minutes30
    : `${roundedDiffInHours} ${toDecline(roundedDiffInHours, LABELS.hoursDeclines)}`;

  return LABELS.duringCheckout(voidTimeLimitString);
};

const getFirstAndLastSegments = ({ Segments }: { Segments: AirSegment[] }) => {
  const firstSegment = Segments[0];
  const lastSegment = Segments[Segments.length - 1];

  return {
    firstSegment,
    lastSegment,
  };
};

const serviceProviderBonusCard = (segments: AirSegment[], code: string) =>
  segments.some(({ MarketingAirline: { ID }, OperatingAirline }) =>
    ID === AIRLINE_IDS.S7 && OperatingAirline.ID === AIRLINE_IDS.GLOBUS_CITRUS && ID === code);

const mappedToTrip = (route: AirRoute) => {
  const {
    firstSegment: { DepartureDate },
    lastSegment: { ArrivalDate },
  } = getFirstAndLastSegments(route);

  const depDate = DepartureDate ? momentObject(trimTimeZone(DepartureDate)) : null;
  const arrDate = ArrivalDate ? momentObject(trimTimeZone(ArrivalDate)) : null;

  const { Duration } = route;
  const duration = Duration && Duration > 0 ? secondsToLabel(Duration * 60, true) : null;

  return {
    departureTime: formatDate(depDate, DATEFORMATS.TIME),
    arrivalTime: formatDate(arrDate, DATEFORMATS.TIME),
    departureDate: formatDate(depDate, DATEFORMATS.DATE),
    arrivalDate: formatDate(arrDate, DATEFORMATS.DATE),
    departureWeek: formatDate(depDate, PATTERN.WEEK),
    arrivalWeek: formatDate(arrDate, PATTERN.WEEK),
    duration,
  };
};

const mappedToOther = (route: AirRoute) => {
  const {
    firstSegment: { DepartureTime },
    lastSegment: { ArrivalTime },
  } = getFirstAndLastSegments(route);

  const { Duration } = route;
  const duration = Duration && Duration > 0 ? secondsToLabel(Duration, true) : null;

  return {
    departureTime: dateWithoutMoment(DepartureTime, DATEFORMATS.TIME),
    arrivalTime: dateWithoutMoment(ArrivalTime, DATEFORMATS.TIME),
    departureDate: dateWithoutMoment(DepartureTime, DATEFORMATS.DATE),
    arrivalDate: dateWithoutMoment(ArrivalTime, DATEFORMATS.DATE),
    departureWeek: dateWithoutMoment(DepartureTime, PATTERN.WEEK),
    arrivalWeek: dateWithoutMoment(ArrivalTime, PATTERN.WEEK),
    duration,
  };
};

const parsedItem = (items: IPrepareNoteItem[]) => items.map(item => {
  const {
    Data,
    Metadata,
    Employees,
    IsReserved,
  } = item;
  const itemData = parseJsonString(Data);
  const itemMetadata = Metadata ? parseJsonString(Metadata) : {};

  return {
    id: item.Id,
    PNR: itemMetadata.PNR,
    routes: itemData.Routes,
    allowedEmployees: Employees,
    isReserved: IsReserved,
  };
});

const checkForDuplicateInCart = (
  items: CartConflicts[] | TripConflicts[],
) => {
  const airlineIds = new Set();

  const airlineConflictExists = items.some(item => {
    if (item.ServiceType !== SERVICETYPE.AIR) return null;

    const itemData = parseJsonString(item.Data);

    return itemData.Routes.some((route: AirRoute) =>
      route.Segments.some(segment => {
        const airlineId = getAirlineIdOrCode(segment);

        if (airlineId === CANCEL_RESERVED.SU) return null;

        const conflictExists = airlineIds.has(airlineId);

        if (!conflictExists) {
          airlineIds.add(airlineId);
        }

        return conflictExists;
      }),
    );
  });

  return airlineConflictExists || null;
};

const checkForDuplicateTrips = (
  duplicateCart: CartConflicts[],
  tripConflicts: TripConflicts[],
) => {
  const duplicateAirlineCodes = new Set();

  duplicateCart.forEach(duplicate => {
    if (duplicate.ServiceType !== SERVICETYPE.AIR) return null;

    const tripData = parseJsonString(duplicate.Data);

    return tripData.Routes.forEach((route: AirRoute) => {
      route.Segments.forEach(segment => {
        const airlineCode = getAirlineIdOrCode(segment);

        if (airlineCode === CANCEL_RESERVED.SU) return null;

        return duplicateAirlineCodes.add(airlineCode);
      });
    });
  });

  const hasConflict = tripConflicts.some(conflict => {
    if (conflict.serviceType !== SERVICETYPE.AIR) return null;

    const conflictData = parseJsonString(conflict.jsonData);

    return conflictData.Routes.some((route: AirRoute) =>
      route.Segments.some(segment => {
        const airlineCode = getAirlineIdOrCode(segment);

        if (airlineCode === CANCEL_RESERVED.SU) return null;

        return duplicateAirlineCodes.has(airlineCode);
      }));
  });

  return hasConflict;
};

const isAirlineCompanySatisfying = (routes: AirRoute[]) => routes
  .some(route => route.Segments
    .some(segment =>
      segment.Airline.ID === CANCEL_RESERVED.FV
      || segment.Airline.ID === CANCEL_RESERVED.SU
      || segment.Airline.ID === CANCEL_RESERVED.S7,
    ));

const filterAirItems = (
  params: IPrepareNoteItem[],
  isSpecCart: boolean,
  isMulti: boolean,
) => {
  const airItems = params.filter(({
    Data,
    ServiceType,
    IsReserved,
    Selected,
  }) => {
    if (ServiceType !== SERVICETYPE.AIR) return null;

    const {
      Routes: routes,
      MultiTripInfo: multiTripInfo,
    } = parseJsonString(Data);

    const isMultiTrip = !!multiTripInfo?.Id;

    const specificConditions = isSpecCart || Selected;

    const isValidItem = isMulti
      ? IsReserved && isMultiTrip
      : IsReserved && specificConditions;

    const airlineCompanySatisfies = isAirlineCompanySatisfying(routes);

    return airlineCompanySatisfies && isValidItem;
  });

  return airItems;
};

const parsedAllItems = (
  params: IPrepareNoteItem[] | IPrepareNoteItem,
  isSpecCart: boolean = false,
  isMulti: boolean = false,
) => {
  const airItems = Array.isArray(params)
    ? filterAirItems(params, isSpecCart, isMulti)
    : [params];

  if (!airItems.length) return null;

  return parsedItem(airItems);
};

const isAirConflicts = (
  items: CartConflicts[] | TripConflicts[],
) => items.some(item =>
  item.ServiceType === SERVICETYPE.AIR,
);

const mappedDates = (route: AirRoute, isTrip: boolean) => (isTrip ? mappedToTrip(route) : mappedToOther(route));

const getFlightRoute = (routes: AirRoute[]) => routes
  .flatMap(route => route.Segments
    .map(segment => `${segment.DepartureCity} - ${segment.ArrivalCity}`))
  .join(', ');

const getDatesTrip = (routes: AirRoute[]) => {
  const datesTrip = routes.map(route => mappedDates(route, false));

  return datesTrip.length > 1
    ? `${datesTrip[0].departureDate} - ${datesTrip[datesTrip.length - 1].arrivalDate}`
    : `${datesTrip[0].departureDate} - ${datesTrip[0].arrivalDate}`;
};

const getEmployeeList = (employees: ISourcesEmployees[]) => {
  const employeeNames = employees.map(({ Employee }) => getEmployeeFullName(Employee));

  return employeeNames.length > 1 ? employeeNames.join(', ') : employeeNames[0];
};

const getRateName = (
  metaFare: AirlineFare,
  metaClass: string,
) => {
  const airlineClass = AIRLINE_CLASSES_MAP.get(metaClass);

  if (!metaFare.Name && !airlineClass) {
    return '';
  }

  if (!metaFare.Name) {
    return airlineClass;
  }

  if (
    !airlineClass ||
    metaFare.Name
      .toLowerCase()
      .includes(airlineClass.toLowerCase())
  ) {
    return metaFare.Name;
  }

  return `${airlineClass} (${metaFare.Name})`;
};

const getAirlineId = (segment: AirSegment) => {
  const airlineType = getAirlineType(segment);

  return segment[airlineType].ID || segment[airlineType].Code;
};

const airlineNames = (
  segments: AirSegment[],
  firstSegment: AirSegment,
) => {
  const filterAirlines = segments.filter(
    segment => getAirlineId(segment) === getAirlineId(firstSegment),
  );
  const flightNumbers = segments
    .map(
      segment => `${getAirlineId(segment)} ${segment.FlightNumber}`,
    )
    .join(', ');

  if (
    segments.length === 1 ||
    (segments.length === 2 && filterAirlines.length === 2)
  ) {
    return `${
      firstSegment[getAirlineType(firstSegment)].Name
    } (${flightNumbers})`;
  }

  return flightNumbers;
};

const getImageLogo = (id: string | number) => `${CONFIG.API_CLUSTER}/images/airline-logo/${id}`;

const getImageImageLogo = (id: string) => `${CONFIG.API_CLUSTER}/images/airline-logo/big/${id}`;

const validateSearchFields = (routes: AirSearchRoute[]) => routes.every(({ to, from, date }) => to.selected && from.selected && date);

export {
  getAirlineType,
  getTerminals,
  isArrivalChanged,
  isDepartureChanged,
  airlineIsBankruptcy,
  getNameByFirstSegment,
  getUniqueBankruptcyNames,
  segmentsArrivalCity,
  mappedSegments,
  mappedSegmentsFromTrip,
  mappedDates,
  getPriceAllCertificates,
  getPriceWithCertificate,
  isAirTicketHasCertificate,
  compareByAirCertificate,
  getAllAirIds,
  getAirTripItemId,
  getVoidTimerStartsAfterBookingString,
  getVoidUntilEndOfDayString,
  getAllFlightCertificateIds,
  getImageLogo,
  parsedAllItems,
  getImageImageLogo,
  getAirlineIdOrCode,
  serviceProviderBonusCard,
  validateSearchFields,
  getFlightRoute,
  getDatesTrip,
  getEmployeeList,
  parsedItem,
  checkForDuplicateInCart,
  checkForDuplicateTrips,
  isAirConflicts,
  isAirlineCompanySatisfying,
  getRateName,
  airlineNames,
};
