import { getText } from '../../../i18n';

import {
  checkDateForInsurance,
  dateWithoutMoment,
  formatDate,
  getMoment,
  isSameDate,
  maxDate,
  minDate,
  momentObject,
} from './formatDate';

import { SERVICETYPE } from '../constants/serviceType';
import { DATEFORMATS, DEFAULT_TIME } from '../constants/dateFormats';
import { DEFAULT_RATES } from '../constants/insurance';

import {
  EnumRateName,
  IDataForInsurance,
  IItemInTrip,
  ICartItem,
  IListItem,
  iAirlineRoutesDates,
  InsuranceRateType,
  IInsuranceDataFromService,
} from '../types/insurance';

const LABELS = {
  TABLE_SIGNATURE: getText('transfer:transferResult.tableSignature'),
  INCLUDE: getText('transfer:transferResult.include'),
  NOT_INCLUDE: getText('transfer:transferResult.notInclude'),
  CURRENCY: getText('components:insurance.currency'),
  AT: getText('components:insurance.at'),
};

const ONE_HUNDRED = 100;

const getLabelCheckboxItem = ({ ServiceType, Data }: { ServiceType: string, Data: string }): string => {
  const data = JSON.parse(Data);

  switch (ServiceType) {
    case SERVICETYPE.HOTEL: {
      const { Name, CheckinDate, CheckoutDate } = data;

      return `${Name}, ${formatDate(CheckinDate, DATEFORMATS.DATE)} - 
      ${formatDate(CheckoutDate, DATEFORMATS.DATE)}`;
    }

    case SERVICETYPE.AIR: {
      const { Direction, AirlineRoutesDates } = data;
      const checkinDate = AirlineRoutesDates[0].CheckinDate;

      const checkoutDate = AirlineRoutesDates[1]
        ? `${dateWithoutMoment(AirlineRoutesDates[1].CheckinDate, DATEFORMATS.DATE)}
         ${LABELS.AT} ${dateWithoutMoment(AirlineRoutesDates[1].CheckinDate, DATEFORMATS.TIME)},`
        : '';

      return `${Direction}, ${dateWithoutMoment(checkinDate, DATEFORMATS.DATE)} 
      ${LABELS.AT} ${dateWithoutMoment(checkinDate, DATEFORMATS.TIME)}, ${checkoutDate} `;
    }

    case SERVICETYPE.AEROEXPRESS: {
      const { Description } = data;

      return `${Description}`;
    }

    case SERVICETYPE.TRANSFER: {
      const { Direction, CheckinDate } = data;

      return `${Direction}, ${formatDate(CheckinDate, DATEFORMATS.DATE)}`;
    }

    case SERVICETYPE.VIP_HALL: {
      const { Direction, CheckinDate } = data;

      return `${Direction}, ${formatDate(CheckinDate, DATEFORMATS.DATE)}`;
    }

    case SERVICETYPE.TAXI_VOUCHER: {
      const { CheckinDate, CheckoutDate } = data;

      return `${formatDate(CheckinDate, DATEFORMATS.DATE)} - ${formatDate(CheckoutDate, DATEFORMATS.DATE)}`;
    }

    case SERVICETYPE.TRAIN: {
      const { Direction, CheckinDate, CheckoutDate } = data;

      return `${Direction}, ${formatDate(CheckinDate, DATEFORMATS.DATE)} - ${formatDate(CheckoutDate, DATEFORMATS.DATE)}`;
    }

    case SERVICETYPE.CUSTOM: {
      const { Name, CheckinDate, CheckoutDate } = data;

      const isTheSameData = isSameDate(CheckinDate, CheckoutDate);

      const dates = isTheSameData
        ? `${formatDate(CheckinDate, DATEFORMATS.DATE)}`
        : ` ${formatDate(CheckinDate, DATEFORMATS.DATE)} 
        - ${formatDate(CheckoutDate, DATEFORMATS.DATE)}`;

      return `${Name}, ${dates}`;
    }

    case SERVICETYPE.BUS: {
      const { Direction, CheckinDate, CheckoutDate } = data;

      return `${Direction}, ${formatDate(CheckinDate, DATEFORMATS.DATE)} - ${formatDate(CheckoutDate, DATEFORMATS.DATE)}`;
    }

    default: return '';
  }
};

const getInfoByServiceType = (serviceType: string, data: IDataForInsurance) => {
  switch (serviceType) {
    case SERVICETYPE.HOTEL: {
      const { City, Name, CheckinDate, CheckoutDate } = data;

      const earlyCheckIn = formatDate(CheckinDate, DATEFORMATS.TIME) !== DEFAULT_TIME
        ? `, ${formatDate(CheckinDate, DATEFORMATS.TIME)}`
        : '';
      const earlyCheckOut = formatDate(CheckoutDate, DATEFORMATS.TIME) !== DEFAULT_TIME
        ? `, ${formatDate(CheckoutDate, DATEFORMATS.TIME)}`
        : '';

      return `${Name}, ${City}, 
      ${formatDate(CheckinDate, DATEFORMATS.DAY_MONTH)}${earlyCheckIn} -
      ${formatDate(CheckoutDate, DATEFORMATS.DATE)}, ${earlyCheckOut}`;
    }

    case SERVICETYPE.AIR: {
      const { Direction, AirlineRoutesDates, OrderId = '' } = data;

      const orederTicketId = OrderId || '';

      const checkinDate = AirlineRoutesDates[0].CheckinDate;
      const checkoutDate = AirlineRoutesDates[1]
        ? `${dateWithoutMoment(AirlineRoutesDates[1].CheckinDate, DATEFORMATS.DATE)} 
        ${LABELS.AT} ${dateWithoutMoment(AirlineRoutesDates[1].CheckinDate, DATEFORMATS.TIME)},`
        : '';

      return `${Direction},
        ${dateWithoutMoment(checkinDate, DATEFORMATS.DATE)} 
        ${LABELS.AT} ${dateWithoutMoment(checkinDate, DATEFORMATS.TIME)}, 
        ${checkoutDate} ${orederTicketId} `;
    }

    case SERVICETYPE.TRANSFER: {
      const { Direction, CheckinDate, CarClass, IncludeTable } = data;

      const signature = `${LABELS.TABLE_SIGNATURE} ${IncludeTable ? LABELS.INCLUDE : LABELS.NOT_INCLUDE}`;

      return `${Direction}, ${formatDate(CheckinDate, DATEFORMATS.DATE)} 
      ${LABELS.AT} ${formatDate(CheckinDate, DATEFORMATS.TIME)}, ${CarClass}. ${signature}`;
    }

    case SERVICETYPE.VIP_HALL: {
      const { Name, Direction, CheckinDate, OrderId = '' } = data;

      return `${Name}, ${Direction}, ${formatDate(CheckinDate, DATEFORMATS.DATE)}, ${OrderId}`;
    }

    case SERVICETYPE.TAXI_VOUCHER: {
      const { Price, CheckinDate, CheckoutDate } = data;

      return `${Price} ${LABELS.CURRENCY}, ${formatDate(CheckinDate, DATEFORMATS.DATE)} 
      - ${formatDate(CheckoutDate, DATEFORMATS.DATE)}`;
    }

    case SERVICETYPE.AEROEXPRESS: {
      const { Description } = data;

      return `${Description}`;
    }

    case SERVICETYPE.TRAIN: {
      const { Direction, CheckinDate, OrderId } = data;
      const ticketNumber = OrderId || '';

      return `${Direction}, ${formatDate(CheckinDate, DATEFORMATS.DATE)} 
      ${LABELS.AT} ${formatDate(CheckinDate, DATEFORMATS.TIME)}, ${ticketNumber}`;
    }

    case SERVICETYPE.CUSTOM: {
      const { Name, CheckinDate, CheckoutDate } = data;

      const isTheSameData = isSameDate(CheckinDate, CheckoutDate);

      const dates = isTheSameData
        ? `${formatDate(CheckinDate, DATEFORMATS.DATE)}`
        : `${formatDate(CheckinDate, DATEFORMATS.DATE)} - ${formatDate(CheckoutDate, DATEFORMATS.DATE)}`;

      return `${Name}, ${dates}`;
    }

    case SERVICETYPE.BUS: {
      const { Direction, CheckinDate } = data;

      return `${Direction}, ${formatDate(CheckinDate, DATEFORMATS.DATE)} 
      ${LABELS.AT} ${formatDate(CheckinDate, DATEFORMATS.TIME)}`;
    }

    default: return '';
  }
};

const prepareDocumentNumber = (docNumber: string, isRuPassport: boolean) => {
  if (isRuPassport) {
    return `${docNumber.substring(0, 2)} ${docNumber.substring(2, 4)} ${docNumber.substring(4)}`;
  }

  return docNumber;
};

const defineWarnLabel = (
  isCart: boolean,
  cartItems: number[],
  addedRate: EnumRateName,
  isEmptyEmployees: boolean,
  validationTransferFields: boolean,
  isValidDate: boolean,
  isActiveRate: boolean,
  listItems: IListItem[],
  selectedItems: number[] | IItemInTrip[],
  TEXT: {
    NOT_EMPLOYEES: string,
    NOT_TRANSFER_VALIDATION: string,
    ALL_ITEMS_IN_TRIP: string,
    NOT_VALID_MSG: string,
    NOT_ALLOW_TO_CHOOSE_ITEM: string,
    ITEMS_DOES_NOT_EXIST: string,
    CHOOSE_ITEMS: string,
  },
) => {
  const allItemsInTrip = isCart && !cartItems.length;
  const isDisableBtnEmptyEmployees = !addedRate && isEmptyEmployees;
  const isDisableBtnTransferFields = !addedRate && validationTransferFields;
  const isDisableBtnIsValidDate = !addedRate && !isValidDate;
  const isDisableBtnChoosenRate = !!addedRate && !isActiveRate;
  const isDisableBtnNotSelectedItems = !isCart && !selectedItems.length;
  const isDisableBtnNotCheckboxesItems = !isCart && !listItems.length;

  const isDisableBtn = allItemsInTrip
  || isDisableBtnChoosenRate
  || isDisableBtnEmptyEmployees
  || isDisableBtnTransferFields
  || isDisableBtnNotSelectedItems
  || isDisableBtnIsValidDate
  || isDisableBtnNotCheckboxesItems;

  if (!isDisableBtn) return false;

  switch (isDisableBtn) {
    case isDisableBtnEmptyEmployees: return TEXT.NOT_EMPLOYEES;
    case isDisableBtnTransferFields: return TEXT.NOT_TRANSFER_VALIDATION;
    case allItemsInTrip: return TEXT.ALL_ITEMS_IN_TRIP;
    case isDisableBtnIsValidDate: return TEXT.NOT_VALID_MSG;
    case isDisableBtnChoosenRate: return TEXT.NOT_ALLOW_TO_CHOOSE_ITEM;
    case isDisableBtnNotCheckboxesItems: return TEXT.ITEMS_DOES_NOT_EXIST;
    case isDisableBtnNotSelectedItems: return TEXT.CHOOSE_ITEMS;

    default: return '';
  }
};

const defineWarnLabelForCheckboxes = (
  isCart: boolean,
  choosenRate: EnumRateName,
  disabledItems: number[],
  TripItemId: number,
  TEXT: {
    NOT_ALLOW_TO_CHOOSE_ITEM: string,
    NOT_VALID_ITEM: string,
  }) => {
  const isDisableToChooseItems = choosenRate && !isCart;

  if (isDisableToChooseItems) return TEXT.NOT_ALLOW_TO_CHOOSE_ITEM;

  if (disabledItems.includes(TripItemId)) return TEXT.NOT_VALID_ITEM;

  return '';
};

const processInsuranceItemFromCart = (items: ICartItem[] = []) => {
  const existedCartItem = items.find(({ ServiceType, TripId }) =>
    ServiceType === SERVICETYPE.INSURANCE && TripId === null);
  const isIncludeInsuranceOfCartItems = items.some(({ ServiceType, TripId }) =>
    ServiceType === SERVICETYPE.INSURANCE && TripId === null);

  let insuredItemIds: number[] = [];
  let rateName: string = '';

  if (existedCartItem) {
    const insuredItem = JSON.parse(existedCartItem.Data);
    insuredItemIds = insuredItem.InsuranceServices.map((item: { CartItemId: number }) => item.CartItemId);
    rateName = insuredItem.Rate.toLowerCase();
  }

  return { insuredItemIds, rateName, isIncludeInsuranceOfCartItems };
};

const getResRates = (isCart: boolean, ratesOfInsurance: IInsuranceDataFromService | InsuranceRateType[]) => {
  if (isCart) return { resRates: ratesOfInsurance };

  const {
    InsuranceServices,
    CartInsuranceInfo: {
      TripItemIds,
      InsuranceRatePrice,
      Rate,
    },
  } = ratesOfInsurance as IInsuranceDataFromService;

  const choosenRateName = Rate?.toLowerCase();

  if (choosenRateName) {
    const resRates = InsuranceRatePrice;
    const updatedItems = InsuranceServices.filter(({ TripItemId }: { TripItemId: number }) =>
      TripItemIds.includes(TripItemId));

    return { resRates, updatedItems, choosenRateName };
  }

  return { resRates: DEFAULT_RATES, choosenRateName };
};

const defineIsValidDate = (AirlineRoutesDates: iAirlineRoutesDates, orderDate: [string, | string]) => {
  if (!AirlineRoutesDates) return checkDateForInsurance(orderDate);

  if (AirlineRoutesDates.length === 1) {
    const { CheckinDate, CheckoutDate } = AirlineRoutesDates[0];

    return checkDateForInsurance([CheckinDate, CheckoutDate]);
  }

  if (AirlineRoutesDates.length > 1) {
    return checkDateForInsurance([
      AirlineRoutesDates[0].CheckinDate,
      AirlineRoutesDates[AirlineRoutesDates.length - 1].CheckoutDate,
    ]);
  }

  return false;
};

const getPropsFromCart = (sources: ICartItem[]) => {
  const hasInvalidInsurance = sources.some(({ ServiceType, TripId }) =>
    ServiceType !== SERVICETYPE.INSURANCE || TripId === null);

  const cartItemsWIthoutTripsAndInsurances = sources.filter(({ ServiceType, TripId }) =>
    ServiceType !== SERVICETYPE.INSURANCE && TripId === null);
  const cartItemsIds = cartItemsWIthoutTripsAndInsurances.map(({ Id }) => Id);

  const tripIds = sources.filter(({ ServiceType, TripId }) =>
    (ServiceType === SERVICETYPE.INSURANCE && TripId !== null)).map(({ TripId }) => TripId);

  return { hasInvalidInsurance, cartItemsWIthoutTripsAndInsurances, cartItemsIds, tripIds };
};

const getPriceOfAddedInsurance = (
  sources: ICartItem[],
  selectedRate: EnumRateName,
  rates: InsuranceRateType[],
) => {
  const choosenRate = rates.find(({ Rate }) => Rate === selectedRate);
  const bookedInsuranceInCart = sources.find(({ ServiceType, TripId }) =>
    ServiceType === SERVICETYPE.INSURANCE && TripId === null) ?? choosenRate;

  return bookedInsuranceInCart?.Price || 0;
};

const formatRatesToLowerCase = (arr: InsuranceRateType[]) => {
  const rates = arr.map(({ Rate, Price }: InsuranceRateType) => ({
    Rate: Rate.toLowerCase() as EnumRateName,
    Price: Math.round(Price * ONE_HUNDRED) / ONE_HUNDRED,
  }));

  return rates;
};

const formatRates = (arr: InsuranceRateType[]) => arr.sort((first, second) => {
  const ratesOrder: { [key: string]: number } = { base: 1, optimal: 2, business: 3 };

  return ratesOrder[first.Rate] - ratesOrder[second.Rate];
});

const defineOrderDateForTrips = (listItems: IListItem[], selectedItems: IItemInTrip[]) => {
  const selectedIds = selectedItems.map(({ TripItemId }) => TripItemId);
  const checkedItemsData = listItems.filter(({ TripItemId, Data }) => selectedIds.includes(TripItemId) && Data);

  const currentDate = getMoment();

  const listDates = checkedItemsData.map(({ Data }) => {
    const jsonData = JSON.parse(Data);

    if (!jsonData) {
      return {
        checkinDate: currentDate,
        checkoutDate: currentDate,
      };
    }

    const { CheckinDate, CheckoutDate } = jsonData;
    const checkinDate = momentObject(CheckinDate);
    const checkoutDate = CheckoutDate ? momentObject(CheckoutDate) : checkinDate;

    return {
      checkinDate,
      checkoutDate,
    };
  });

  const arrCheckinDates = listDates.map(({ checkinDate }) => checkinDate);
  const arrCheckoutDates = listDates.map(({ checkoutDate }) => checkoutDate);

  if (selectedIds.length) {
    const checkinDate = minDate(arrCheckinDates);
    const checkoutDate = maxDate(arrCheckoutDates);

    return [checkinDate, checkoutDate];
  }

  return [currentDate, currentDate];
};

export {
  defineIsValidDate,
  defineWarnLabel,
  defineWarnLabelForCheckboxes,
  formatRates,
  formatRatesToLowerCase,
  getInfoByServiceType,
  getLabelCheckboxItem,
  getPriceOfAddedInsurance,
  getPropsFromCart,
  getResRates,
  prepareDocumentNumber,
  processInsuranceItemFromCart,
  defineOrderDateForTrips,
};
