import React, { ReactElement } from 'react';

import { Tooltip, Icon, Text, IconType, TootipPositionType } from 'new-ui';

import styles from './index.module.css';

interface InformationIconProps {
  text: string | ReactElement,
  iconClassName?: string,
  className?: string,
  iconType?: string,
  tooltipPosition?: string,
  classNameTooltipText?: string,
}

const InformationIcon = ({
  text,
  className = '',
  iconClassName = '',
  tooltipPosition = 'top',
  iconType = 'info',
  classNameTooltipText = styles.text,
}: InformationIconProps) => (
  <Tooltip
    className={ className }
    position={ tooltipPosition as TootipPositionType }
    renderContent={ () => (
      <Text
        type='NORMAL_14_130'
        color='white'
        className={ classNameTooltipText }
      >
        { text }
      </Text>
    ) }
  >
    <Icon type={ iconType as IconType } className={ iconClassName }/>
  </Tooltip>
);

export { InformationIcon };
