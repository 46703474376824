import { yup, yupEnum, yupNumberNull } from '../../../../utils/yup';
import { ICompany, ICompanyEasy } from './types';

enum EmployeeStatus {
  Active = 'Active',
  Archived = 'Archived',
  User = 'User',
}

enum EmployeeSex {
  Man = 'Man',
  Woman = 'Woman',
}

export enum EmployeeDocumentType {
  DomesticPassport = 'DomesticPassport',
  ForeignPassport = 'ForeignPassport',
  BirthCertificate = 'BirthCertificate',
}

export const EmployeeDocumentSchema = yup.object({
  FirstName: yup.string(),
  Id: yup.number(),
  LastName: yup.string(),
  MiddleName: yup.string(),
  Name: yup.string(),
  Number: yup.string(),
  Patronymic: yup.string(),
  Selected: yup.boolean(),
  Surname: yup.string(),
  Type: yupEnum(EmployeeDocumentType),
  DueDate: yup.string().datetime().nullable(),
});

const EmployeeTagsSchema = yup.object({
  Id: yup.number(),
  Value: yup.string(),
});

export const EmployeeSchema = yup.object({
  Id: yup.number(),
  Uuid: yup.string().uuid(),
  ExternalId: yup.string(),
  Surname: yup.string(),
  Email: yup.string(),
  Name: yup.string(),
  Patronymic: yup.string(),
  Status: yupEnum(EmployeeStatus),
  Sex: yupEnum(EmployeeSex),
  Birthday: yup.string().datetime(),
  Documents: yup.array().of(EmployeeDocumentSchema).default([]),
  ApprovalSchemeId: yupNumberNull,
  ProjectId: yupNumberNull,
  Tags: yup.array().of(EmployeeTagsSchema).default([]),
  Number: yup.string(),
  Position: yup.string(),
  DepartmentId: yup.number(),
  CompanyId: yup.number(),
  Companies: yup.array<ICompany | ICompanyEasy>(),
});

export type EmployeeType = yup.InferType<typeof EmployeeSchema>;
