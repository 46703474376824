import React, { JSX } from 'react';
import { Dialog, Text, Button } from 'new-ui';

import { getText } from '../../../i18n';

import { formatDate, isSameDate } from '../../bi/utils/formatDate';

import { DATEFORMATS } from '../../bi/constants/dateFormats';
import { INSURANCE_CONFLICTS_TYPE } from '../../bi/constants/checkout';

import styles from './index.module.css';

interface ConflictType {
  CartItemId: number;
  Type: string;
}

interface InsuranceConflictDialogProps {
  showWrong: boolean;
  sources: any[];
  conflicts: ConflictType[];
  onClose(): void;
  onDelete(id: number): void;
  onBack(): void;
}

const LABELS = {
  BACK: getText('components:taxiDialog.cancel'),
  TITLE: getText('components:taxiDialog.title'),
  NOT_VALID_DATE: getText('components:insurance.notValidDate'),
  CALL_TO_ACTION: getText('components:insurance.callToAction'),
  NOT_ALLOWED_TO_BUY: (dates: string, TotalPrice: string) => getText('components:insurance.notAllowedToBuy', { dates, TotalPrice }),
  ITEMS_CHANGED: getText('components:insurance.servicesWereChanged'),
  DELETE_INSURANCE: getText('components:insurance.deleteInsurance'),
};

const InsuranceConflictDialog = ({
  showWrong,
  sources,
  conflicts,
  onClose,
  onDelete,
  onBack,
}: InsuranceConflictDialogProps) => {
  const renderDialogError = (
    show: boolean,
    contentText: JSX.Element,
    labelBack: string,
    onBackButton: () => void,
    id: number,
  ) => (
    <Dialog show={ show } showClosing onChange={ onClose } outsideClickClosing={ false }>
      <div className={ styles.wrap }>
        <Text
          className={ styles.title }
          type='bold_20'
        >
          { LABELS.TITLE }
        </Text>
        { contentText }
        <div className={ styles.action }>
          <Button
            type='primary'
            onClick={ () => onDelete(id) }
          >
            { LABELS.DELETE_INSURANCE }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ onBackButton }
          >
            { labelBack }
          </Button>
        </div>
      </div>
    </Dialog>
  );

  const renderError = ({ CartItemId, Type }: { CartItemId: number, Type: string }) => {
    const data = sources.find(({ Id }: { Id: number }) => Id === CartItemId)?.Data || '';

    if (!data) return null;

    const dataParse = JSON.parse(data);
    const { CheckinDate = '', CheckoutDate = '', Price: { TotalPrice = '' } } = dataParse;

    const dates = isSameDate(CheckinDate, CheckoutDate)
      ? `${formatDate(CheckinDate, DATEFORMATS.DATE)}`
      : ` ${formatDate(CheckinDate, DATEFORMATS.DATE)} 
      - ${formatDate(CheckoutDate, DATEFORMATS.DATE)}`;

    const reason = Type === INSURANCE_CONFLICTS_TYPE.WRONG_DATES
      ? LABELS.NOT_VALID_DATE
      : LABELS.ITEMS_CHANGED;

    const renderContentText = () => (
      <>
        <Text className={ styles.info }>
          { LABELS.NOT_ALLOWED_TO_BUY(dates, TotalPrice)} { reason }
        </Text>
        <Text>{ LABELS.CALL_TO_ACTION }</Text>
      </>
    );

    return renderDialogError(
      showWrong,
      renderContentText(),
      LABELS.BACK,
      onBack,
      CartItemId,
    );
  };

  return renderError(conflicts[0]);
};

export { InsuranceConflictDialog };
