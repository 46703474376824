import React, { useState } from 'react';
import { Button, Icon, Text } from 'new-ui';
import { getText } from '../../../i18n';

import InfoDialog from '../AirlineSearchItem/components/infoDialog';

import {
  getTerminals,
  mappedDates,
  mappedSegments,
  mappedSegmentsFromTrip,
  getRateName,
  airlineNames,
} from '../../bi/utils/airline';
import guid from '../../bi/utils/guid';
import toDecline from '../../bi/utils/toDecline';

import {
  AIRLINE_IDS_W_CUSTOM_REFUND,
  TRANSFER,
} from '../../bi/constants/airline';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { SERVICETYPE } from '../../bi/constants/serviceType';
import SYMBOLS from '../../bi/constants/symbols';

import AirlineItemSegment from '../AirlineItemSegment';
import FareDetails from '../FareDetails';
import { AirlineTechnicalStop } from '../AirlineTechnicalStop';
import { UnderageWarning } from '../UnderageWarning';

import {
  AirRoute,
  AirSegment,
  Metadata,
} from '../../bi/types/airline';

import styles from './styles/index.module.css';

interface IAirlineItemProps {
  route: AirRoute,
  meta: Metadata,
  fromTrip?: boolean,
  pnr?: string | null,
  hasUnderageEmployees?: boolean,
  getFreeSeatsData?: (route: AirRoute) => {
    data: string | null,
    caption: string | null,
  },
  isCart?: boolean,
  qaAttr?: typeof QA_ATTRIBUTES.cart.air | typeof QA_ATTRIBUTES.changeTrip.air.byDateDirectionResult.approve.oldTicket.data,
  isMultiTrip?: boolean,
  airTripChange?: boolean,
  displayFareAirTripChange?: boolean,
  showTicketBullits?: boolean,
}

const DEFAULT_FREE_SEATS_DATA = {
  data: null,
  caption: null,
};

const LABELS = {
  withoutTransfers: getText('components:airlineSearchItem.airItem.withoutTransfers'),
  complex: getText('components:airlineSearchItem.complex.complexRoute'),
  terminal: getText('components:airlineItemSegment.terminal'),
};

const AirlineItem = ({
  route,
  route: {
    Segments,
  },
  meta: {
    Class,
    Fare,
    TicketsExtended,
  },
  fromTrip = false,
  pnr = null,
  hasUnderageEmployees = false,
  getFreeSeatsData = () => DEFAULT_FREE_SEATS_DATA,
  isCart = false,
  qaAttr = {} as typeof QA_ATTRIBUTES.cart.air,
  isMultiTrip = false,
  airTripChange = false,
  displayFareAirTripChange = false,
  showTicketBullits = false,
}: IAirlineItemProps) => {
  const [isShowSegments, setIsShowSegments] = useState(false);
  const [isShowComplexDialog, setIsShowComplexDialog] = useState(false);

  const toggleSegments = () => {
    setIsShowSegments(!isShowSegments);
  };

  const toggleComplexInfo = () => {
    setIsShowComplexDialog(!isShowComplexDialog);
  };

  const renderSegments = () => {
    const segmentsHtml = Segments.map((segment, index) => {
      const arrivalTerminal = getTerminals(segment, index, Segments)
        .arrival;
      const departureTerminal = getTerminals(segment, index, Segments)
        .departure;

      return (
        <AirlineItemSegment
          key={ `segment_${guid()}_${index}` }
          fromTrip={ fromTrip }
          segment={ segment }
          arrivalTerminal={ arrivalTerminal }
          departureTerminal={ departureTerminal }
          opts={
            fromTrip
              ? mappedSegmentsFromTrip(segment)
              : mappedSegments(segment)
          }
        />
      );
    });

    return (
      <div
        onClick={ toggleSegments }
        className={ styles.segments }
      >
        { segmentsHtml }
      </div>
    );
  };

  const renderPNR = () => {
    if (TicketsExtended) {
      const items: string[] = [];
      TicketsExtended.forEach(item => {
        let element = item.PNR;

        if (item.PNRLocator) {
          element += ` / ${item.PNRLocator}`;
        }

        items.push(element);
      });

      return (
        <div className={ styles.pnr }>
          <Text type='SEMIBOLD_14_130'>
            { items.join(', ') }
          </Text>
        </div>
      );
    }

    if (pnr) {
      return (
        <div className={ styles.pnr }>
          <Text color='gray' type='SEMIBOLD_14_130'>
            {pnr}
          </Text>
        </div>);
    }

    return null;
  };

  const renderFreeSeats = () => {
    const {
      data,
      caption,
    } = getFreeSeatsData?.(route) ?? DEFAULT_FREE_SEATS_DATA;

    if (!caption) {
      return null;
    }

    return (
      <div className={ styles.free_seats }>
        <Text type='bold_14'>{ caption }:&nbsp;</Text>
        <Text type='NORMAL_14_130'>{ data }</Text>
      </div>
    );
  };

  const renderRate = () => {
    if (!Fare) {
      return null;
    }

    const customTitle = getRateName(Fare, Class);
    const { MarketingAirline, Airline } = Segments[0];

    const customRefundabilityAndExchangeability = AIRLINE_IDS_W_CUSTOM_REFUND
      .includes(
        MarketingAirline
          ? MarketingAirline.ID
          : Airline.Code,
      );

    const displayCart = !airTripChange && isCart;

    return (
      <Text type='NORMAL_14_130'>
        <FareDetails
          customRefundabilityAndExchangeability={
            customRefundabilityAndExchangeability
          }
          isCart={ displayCart }
          title
          customTitle={ customTitle }
          fare={ Fare }
          separateSymbol={ SYMBOLS.COMMA }
          className={ styles.fare }
          showTicketBullits={ showTicketBullits }
        />
      </Text>
    );
  };

  const renderComplex = () => {
    if (!isMultiTrip) {
      return null;
    }

    return (
      <div
        className={ styles['complex-wrapper'] }
        onClick={ toggleComplexInfo }
      >
        <Text type='bold_14' className={ styles.text }>
          { LABELS.complex }
        </Text>
        <Icon type='warning' />
      </div>
    );
  };

  const changesContent = (
    changeCount: number,
    segments: AirSegment[],
  ) => {
    const isTechnicalStopDetails = segments[0].TechnicalStop?.length;
    const isTechnicalStopS7 = segments[0]?.ServiceStops?.length;

    if (changeCount) {
      return (
        <Button
          type='textual'
          className={ styles['change-toggle'] }
          onClick={ toggleSegments }
        >
          { changeCount } { toDecline(changeCount, TRANSFER) }
        </Button>
      );
    }

    if (isTechnicalStopDetails || isTechnicalStopS7) {
      return null;
    }

    return (
      <Text
        type='NORMAL_14'
        color='gray'
      >
        {LABELS.withoutTransfers}
      </Text>
    );
  };

  const renderVoucherDetails = () => {
    if (airTripChange && !displayFareAirTripChange) {
      return null;
    }

    const displayRenderComplex = !airTripChange && renderComplex();
    const displayRenderPNR = !airTripChange && renderPNR();

    return (
      <div className={ styles['voucher-details'] }>
        { displayRenderComplex }
        { renderRate() }
        { displayRenderPNR }
      </div>
    );
  };

  const segments = Segments;
  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];

  const technicalStopDetails = firstSegment.TechnicalStop;
  const technicalStopS7 = firstSegment?.ServiceStops;

  const isTechnicalStopDetails = !!technicalStopDetails?.length;
  const isTechnicalStopS7 = !!technicalStopS7?.length;

  const changeCount = segments.length - 1;

  const showSegments = changeCount && isShowSegments;

  const {
    departureTime,
    arrivalTime,
    departureDate,
    arrivalDate,
    departureWeek,
    arrivalWeek,
    duration,
  } = mappedDates(route, fromTrip as boolean);

  const airlineNameWithNumbers = airlineNames(
    segments,
    firstSegment,
  );

  const durationStyles = isTechnicalStopDetails || isTechnicalStopS7
    ? styles.duration_with_technical
    : styles.duration;

  const departureTerminal = firstSegment.DepartureTerminal && (
    <Text
      className={ styles.terminal }
      qaAttr={ (qaAttr as typeof QA_ATTRIBUTES.cart.air)?.fromTerminal }
      type='NORMAL_16'
    >
      { LABELS.terminal }: { `${firstSegment.DepartureTerminal}` }
    </Text>
  );

  const arrivalTerminal = lastSegment.ArrivalTerminal && (
    <Text
      className={ styles.terminal }
      qaAttr={ (qaAttr as typeof QA_ATTRIBUTES.cart.air)?.toTerminal }
      type='NORMAL_16'
    >
      { LABELS.terminal }: { `${lastSegment.ArrivalTerminal}` }
    </Text>
  );

  const renderTechnicalStopTooltip = () => {
    if (!isTechnicalStopDetails && !isTechnicalStopS7) {
      return null;
    }

    const technicalStop = isTechnicalStopDetails
      ? technicalStopDetails
      : technicalStopS7;

    return (
      <AirlineTechnicalStop
        changeCount={ changeCount }
        technicalStop={ technicalStop }
      />
    );
  };

  const renderUnderageWarning = () => {
    if (!hasUnderageEmployees) return null;

    return <UnderageWarning type={ SERVICETYPE.AIR } />;
  };

  const renderShowSegments = () => {
    if (!showSegments) return null;

    return renderSegments();
  };

  return (
    <div className={ styles.item }>
      <div className={ styles.top }>
        <div className={ styles.left }>
          <Text qaAttr={ qaAttr?.from } type='bold_18'>
            { `${firstSegment.DepartureCity}, ` }
            { firstSegment.DepartureAirport.ID ||
              firstSegment.DepartureAirport.Code }
          </Text>
          { departureTerminal }
          <Text
            qaAttr={ qaAttr?.timeFrom }
            type='bold_22'
            className={ styles.time }
          >
            { departureTime }
          </Text>
          <div
            data-qa={ qaAttr?.dateFrom }
            className={ styles.date }
          >
            <Text
              className={ 'notranslate' }
              color='gray'
              type='NORMAL_14'
            >
              { `${departureDate},` }
            </Text>
              &nbsp;
            <Text color='gray' type='NORMAL_14'>
              { departureWeek }
            </Text>
          </div>
        </div>
        <div className={ styles.center }>
          <Text color='gray' type='NORMAL_14'>
            { airlineNameWithNumbers }
          </Text>
          <Text className={ durationStyles } type='SEMIBOLD_16'>
            { duration }
          </Text>
          <div className={ styles.changes }>
            { changesContent(changeCount, segments) }
            { renderTechnicalStopTooltip() }
          </div>
        </div>
        <div className={ styles.right }>
          <Text qaAttr={ qaAttr?.to } type='bold_18'>
            { `${lastSegment.ArrivalCity}, ` }
            { lastSegment.ArrivalAirport.ID ||
              lastSegment.ArrivalAirport.Code }
          </Text>
          { arrivalTerminal }
          <Text
            qaAttr={ qaAttr?.timeTo }
            type='bold_22'
            className={ styles.time }
          >
            { arrivalTime }
          </Text>
          <div
            data-qa={ qaAttr?.dateTo }
            className={ styles.date }
          >
            <Text
              className={ 'notranslate' }
              color='gray'
              type='NORMAL_14'
            >
              { `${arrivalDate},` }
            </Text>
              &nbsp;
            <Text
              color='gray'
              type='NORMAL_14'
            >
              { arrivalWeek }
            </Text>
          </div>
        </div>
      </div>
      { renderShowSegments() }
      { renderVoucherDetails() }
      { renderFreeSeats() }
      { renderUnderageWarning() }
      <InfoDialog
        show={ isShowComplexDialog }
        onAddToCart={ toggleComplexInfo }
        onlyInfo
      />
    </div>
  );
};

export default AirlineItem;
