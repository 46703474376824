import React, { useState, useRef } from 'react';
import {
  Select,
  DatepickerDialog,
  Text,
  useRegisterOutsideClick,
} from 'new-ui';
import { Moment } from 'moment';

import { getText } from '../../../i18n';

import { formatDate, defaultPattern, getMoment } from '../../bi/utils/formatDate';
import { isSmartAgent } from '../../bi/utils/env';

import {
  CalendarType,
  CalendarMode,
  Item,
  ICombinedDatepickerProps,
} from './types';

import styles from './styles/index.module.css';

const LABELS = {
  ANY_DATE: getText('components:combinedDatepicker.anyDate'),
  SELECT_NUMBER: getText('components:combinedDatepicker.selectNumber'),
  SELECT_PERIOD: getText('components:combinedDatepicker.selectPeriod'),
};

const options = [
  { label: LABELS.ANY_DATE, value: 'none' },
  { label: LABELS.SELECT_NUMBER, value: 'single' },
  { label: LABELS.SELECT_PERIOD, value: 'range' },
];

const CombinedDatepicker = ({
  date = null,
  optionalDate = null,
  onChange,
  notChooseOptions = false,
  className,
}: ICombinedDatepickerProps) => {
  const [type, setType] = useState<CalendarType>(CalendarType.none);
  const [calendarMode, setCalendarMode] = useState<CalendarMode>(CalendarMode.date);

  const selectRefs = [React.createRef<HTMLDivElement>(), React.createRef<HTMLDivElement>()];
  const datepickerRef = useRef(null);

  const hideCalendar = () => {
    if (type !== CalendarType.none) {
      setType(CalendarType.none);
    }
  };

  useRegisterOutsideClick([datepickerRef, selectRefs[0], selectRefs[1]], hideCalendar);

  const onHandleChange = (date1: Moment, date2: Moment | boolean, shouldUpdate = true) => {
    if (shouldUpdate) onChange(date1, date2);

    if (type !== CalendarType.range) {
      hideCalendar();
    }
  };

  const onDateTypeChange = (calendarType: CalendarType) => {
    if (calendarType === CalendarType.none) {
      onChange(null, null);
    }

    setType(calendarType);
  };

  const renderCalendar = () => {
    const isRangeDatepicker = type === CalendarType.range;
    const datePickerType = isRangeDatepicker ? 'dateRange' : 'date';
    const min = getMoment().add(-3, 'y');
    const max = getMoment().add(1, 'y');

    return (
      <div ref={ datepickerRef }>
        <DatepickerDialog
          min={ min }
          max={ max }
          type={ datePickerType }
          value={ date }
          secondValue={ optionalDate }
          setMode={ (_, currentDate: Moment, secondDate?: Moment | boolean) => {
            setCalendarMode(CalendarMode.date);

            if (date) {
              onHandleChange(currentDate, secondDate as Moment | boolean, false);
            }
          } }
          onChangeSwitcher={ ({ mode }) => {
            setCalendarMode(mode);
          } }
          mode={ calendarMode }
          onChange={ onHandleChange }
          datePickerParent
          alternativeDesign={ isSmartAgent }
        />
      </div>
    );
  };

  const getLabel = (item: Item) => {
    const { label } = item;

    if (date && optionalDate) {
      return `${formatDate(date, defaultPattern)} — ${formatDate(optionalDate, defaultPattern)}`;
    }

    if (date) {
      return formatDate(date, defaultPattern);
    }

    return label;
  };

  const renderLabel = (item: Item) => <Text color='gray'>{ getLabel(item) }</Text>;

  const selectHtml = notChooseOptions ? (
    <Select
      theme='default-large'
      items={ [{ label: LABELS.SELECT_PERIOD, value: 'range' }] }
      value={ CalendarType.range }
      renderLabel={ renderLabel }
      onChange={ onDateTypeChange }
      className={ className }
      ref={ selectRefs[0] }
    />
  ) : (
    <Select
      theme='default-large'
      items={ options }
      value={ type }
      renderLabel={ renderLabel }
      onChange={ onDateTypeChange }
      className={ className }
      ref={ selectRefs[1] }
    />
  );

  const calendarHtml = type !== CalendarType.none && renderCalendar();

  return (
    <div
      className={ styles.wrapper }
    >
      { selectHtml }
      { calendarHtml }
    </div>
  );
};

export { CombinedDatepicker };
