import React, { ReactNode, useState } from 'react';

import { Button, Dialog, Text, Input } from 'new-ui';

import { getText } from '../../../i18n';

import styles from './styles/index.module.css';

interface InputModalProps {
  show: boolean;
  title: string;
  type?: string;
  label?: string;
  children?: ReactNode;
  onSend(value: string): void;
  onCancel(): void;
  loading?: boolean;
  isValid?: boolean;
  onChange?(value: string): void;
  qaAttrs?: {
    qaAttrDialog?: string;
    qaAttrTitle?: string;
    qaAttrInput?: string;
    qaAttrSend?: string;
    qaAttrClose?: string;
  } | null;
}

const LABELS = {
  SEND: getText('common:send'),
  UNDO: getText('common:undo'),
};

const ID_APPROVAL_EMAIL = 'approvalEmail';

const InputModal = ({
  show,
  title,
  type = 'text',
  label = '',
  children = null,
  onSend,
  onCancel,
  loading = false,
  isValid = true,
  onChange = () => null,
  qaAttrs = null,
}: InputModalProps) => {
  const [value, setValue] = useState<string>('');

  const handleChange = (newValue: string) => {
    onChange(newValue);
    setValue(newValue);
  };

  const labelHtml = label.length > 0 ? <Text className={ styles.label }>{ label }</Text> : null;

  return (
    <div onClick={ e => e.stopPropagation() }>
      <Dialog
        qaAttr={ qaAttrs?.qaAttrDialog || '' }
        onChange={ onCancel }
        show={ show }
        showClosing
      >
        <div className={ styles.wrapper }>
          <Text
            qaAttr={ qaAttrs?.qaAttrTitle || '' }
            type='bold_20'
            className={ styles.title }
          >
            { title }
          </Text>
          <form
            onSubmit={ (e) => {
              e.preventDefault();
              onSend(value);
            } }
          >
            { labelHtml }
            <Input
              qaAttr={ qaAttrs?.qaAttrInput || '' }
              type={ type }
              id={ ID_APPROVAL_EMAIL }
              value={ value }
              onChange={ handleChange }
            />
            { children }
            <div className={ styles.action }>
              <Button
                qaAttr={ qaAttrs?.qaAttrSend || '' }
                formType='submit'
                disabled={ value.length === 0 || !isValid }
                loading={ loading }
              >
                { LABELS.SEND }
              </Button>
              <Button
                qaAttr={ qaAttrs?.qaAttrClose || '' }
                formType='submit'
                className={ styles.cancel }
                type='textual-medium'
                onClick={ onCancel }
                loading={ loading }
              >
                { LABELS.UNDO }
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default InputModal;
