import { Moment } from 'moment';
import { observable, action, computed, makeObservable } from 'mobx';

import {
  getMoment,
  isSameOrAfter,
  momentObject,
  minDate,
  maxDate,
} from '../../../utils/formatDate';
import { getEmployeeFullName } from '../../../utils/employees';

import { FIELDS_DATE } from '../../../constants/dateFormats';
import { DEFAULT_STATUS_FIELDS } from '../../../constants/expenseReport';

import type {
  ExpenseReportsType,
  ReportsType,
  PagingType,
  TripType,
  DialogType,
  FiltersType,
  ApproversForExpenseReports,
  ApproversType,
} from '../types';

const DEFAULT_DIALOG: DialogType = {
  sources: [],
  trips: [],
  showTrips: false,
  checkbox: false,
  isValid: false,
  showButtonMore: false,
  loading: false,
  showCompanies: false,
  companies: [],
  currentCompany: null,
  employee: null,
  startDate: getMoment().add(-7, 'd'),
  endDate: getMoment().add(-1, 'd'),
  departments: [],
};

const DEFAULT_PAGING = {
  total: 0,
  current: 1,
  count: 5,
};

const DEFAULT_FILTERS = {
  employees: {
    list: [],
    selected: [],
  },
  companies: {
    list: [],
    selected: [],
  },
  statuses: {
    list: DEFAULT_STATUS_FIELDS,
    selected: [],
  },
  mineCheckbox: {
    list: [],
    checked: false,
    name: '',
  },
};

const preparedDates = (list: TripType[], field: string) => list.reduce((acc: any[], { CheckinDate, CheckoutDate, selected }) => {
  if (selected) {
    const date = field === FIELDS_DATE.START_DATE ? CheckinDate : CheckoutDate;

    return [...acc, momentObject(date)];
  }

  return acc;
}, []);

const preparedStartDate = (list: TripType[], currentDate: Moment | null) => {
  const preparedList = preparedDates(list, FIELDS_DATE.START_DATE);

  return preparedList.length ? minDate(preparedList) : currentDate;
};

const preparedEndDate = (list: TripType[], currentDate: Moment | null) => {
  const preparedList = preparedDates(list, FIELDS_DATE.END_DATE);

  return preparedList.length ? maxDate(preparedList) : currentDate;
};

const getPagingView = (items: any[], paging: PagingType) => {
  const startInd = paging.count * (paging.current - 1);

  return items.slice(startInd, startInd + paging.count);
};

export class ExpenseReportsStore {
  constructor() {
    makeObservable(this);
  }

  @observable sources: Array<ReportsType> = [];
  @observable cacheItems: Array<ReportsType> = [];
  @observable DepartmentIds: Array<ReportsType> = [];
  @observable filteredSources: Array<ReportsType> = [];
  @observable onlyOwn = false;
  @observable loading = true;
  @observable isIntegration1S = false;
  @observable dialog: DialogType = DEFAULT_DIALOG;
  @observable paging: PagingType = DEFAULT_PAGING;
  @observable filters: FiltersType = DEFAULT_FILTERS;
  @observable approvers: Array<ApproversForExpenseReports> = [];
  @observable usualApprovers: Array<ApproversType> = [];

  @action
  setSources = ({ Reports, OnlyOwn }: ExpenseReportsType): void => {
    this.paging = {
      ...this.paging,
      total: Reports.length,
    };
    this.sources = Reports;
    this.cacheItems = getPagingView(Reports, this.paging);
    this.onlyOwn = OnlyOwn;
    this.updateFilters();
  };

  @action
  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  @action
  setApprovers = (values: Array<ApproversForExpenseReports>): void => {
    this.approvers = values;
  };

  @action
  setUsualApprovers = (values: Array<ApproversType>): void => {
    this.usualApprovers = values;
  };

  @action
  setIntegration1S = (value: boolean): void => {
    this.isIntegration1S = value;
  };

  @action
  setCurrentPaging = (value: number): void => {
    this.paging = {
      ...this.paging,
      current: value,
    };

    const data = this.filteredSources.length ? this.filteredSources : this.sources;
    this.cacheItems = getPagingView(data, this.paging);
  };

  @action
  setLoadingDialog = (value: boolean): void => {
    this.dialog = {
      ...this.dialog,
      loading: value,
    };
  };

  @action
  setTrips = (list: Array<TripType>): void => {
    const countTrips = list.length;
    const checkbox = countTrips === 0;
    let sources: TripType[] = [];
    let trips: any = [];
    let showButtonMore = false;

    if (countTrips) {
      sources = list;
      showButtonMore = true;

      trips = sources.length > 5 ?
        sources.reduce((acc: TripType[], item, index) => {
          if (index < 5) {
            return [...acc, item];
          }

          return acc;
        }, []) :
        sources;

      if (trips.length === sources.length) {
        showButtonMore = false;
      }
    }

    const { startDate, endDate } = this.dialog;

    this.dialog = {
      ...this.dialog,
      sources,
      trips,
      showButtonMore,
      showTrips: true,
      checkbox,
      isValid: true,
      startDate: !checkbox ? preparedStartDate(trips, startDate) : startDate,
      endDate: !checkbox ? preparedEndDate(trips, endDate) : endDate,
    };
  };

  @action
  setClearDialog = (value: boolean): void => {
    this.dialog = this.updateDialog(value);
  };

  @action
  updateDialog = (value: boolean): DialogType => {
    if (!value) {
      return DEFAULT_DIALOG;
    }

    return {
      ...this.dialog,
      showTrips: value,
    };
  };

  @action
  setCheckbox = (value: boolean): void => {
    const { sources, trips, startDate, endDate } = this.dialog;

    const isValid = value || (!!sources.length && sources.some(({ selected }) => selected));

    this.dialog = {
      ...this.dialog,
      checkbox: value,
      isValid,
      startDate: !value ? preparedStartDate(trips, startDate) : startDate,
      endDate: !value ? preparedEndDate(trips, endDate) : endDate,
    };
  };

  @action
  setCheckboxTrip = (value: boolean, id: number): void => {
    const updateList = (list: Array<TripType>): Array<TripType> => list.map((item) => ({
      ...item,
      selected: id === item.TripId ? value : item.selected,
    }));

    const { trips, sources, checkbox, startDate, endDate } = this.dialog;

    const changedTrips = updateList(trips);
    const changedSources = updateList(sources);
    const isValid = checkbox || changedSources.some(({ selected }) => selected);

    this.dialog = {
      ...this.dialog,
      trips: changedTrips,
      sources: changedSources,
      isValid,
      startDate: !checkbox ? preparedStartDate(changedTrips, startDate) : startDate,
      endDate: !checkbox ? preparedEndDate(changedTrips, endDate) : endDate,
    };
  };

  @action
  openAllTrips = (): void => {
    const { sources, checkbox, startDate, endDate } = this.dialog;

    this.dialog = {
      ...this.dialog,
      trips: sources,
      showButtonMore: false,
      startDate: !checkbox ? preparedStartDate(sources, startDate) : startDate,
      endDate: !checkbox ? preparedEndDate(sources, endDate) : endDate,
    };
  };

  @action
  setStartDate = (value: Moment | null): void => {
    const { endDate } = this.dialog;

    const changedEndDate = isSameOrAfter(value, endDate) ? momentObject(value).add(1, 'd') : endDate;

    this.dialog = {
      ...this.dialog,
      startDate: value,
      endDate: changedEndDate,
    };
  };

  @action
  setEndDate = (value: Moment | null): void => {
    this.dialog = {
      ...this.dialog,
      endDate: value,
    };
  };

  @action
  setEmployee = (employee: any): void => {
    this.dialog = {
      ...this.dialog,
      employee,
    };
  };

  @action
  setCompanies = (companies: any[]): void => {
    this.dialog = {
      ...this.dialog,
      showCompanies: companies.length > 0,
      companies: companies.map(({ CompanyId, ShortCompanyName, CompanyName }) => ({
        value: CompanyId,
        label: ShortCompanyName || CompanyName,
      })),
    };
  };

  @action
  setCompany = (value: string | number | undefined): void => {
    this.dialog = {
      ...this.dialog,
      currentCompany: value,
    };
  };

  @action
  reset = (): void => {
    this.sources = [];
    this.cacheItems = [];
    this.onlyOwn = false;
    this.loading = true;
    this.isIntegration1S = false;
    this.dialog = DEFAULT_DIALOG;
    this.paging = DEFAULT_PAGING;

    const name = this.filters.mineCheckbox.name;

    this.filters = {
      ...DEFAULT_FILTERS,
      name,
    };
    this.filteredSources = [];
  };

  @action
  setDepartment = (departments: number[]): void => {
    this.dialog = {
      ...this.dialog,
      departments,
    };
  };

  @action
  setDepartmentReset = (): void => {
    this.dialog = {
      ...this.dialog,
      departments: [],
    };
  };

  @action
  setFilters = (name: string) => {
    const employeesSet = new Set();
    const companiesSet = new Set();
    const mineCheckedSet = new Set();

    this.sources.forEach(({
      CompanyId,
      EmployeeId,
      Creator,
    }) => {
      employeesSet.add(EmployeeId);
      companiesSet.add(CompanyId);

      if (Creator) {
        mineCheckedSet.add(Creator);
      }
    });

    const employees = Array.from(employeesSet).map(i => {
      const foundEmployee = this.sources.find(({ EmployeeId }) => EmployeeId === i)?.Data.Employee;
      const employeeName = foundEmployee || { Surname: '' };
      const label = getEmployeeFullName(employeeName);

      return {
        label,
        value: i,
      };
    });

    const companies = Array.from(companiesSet).map(i => {
      const foundCompany = this.sources.find(({ CompanyId }) => CompanyId === i)?.Data.CompanyName;
      const label = foundCompany || '';

      return {
        label,
        value: i,
      };
    });

    this.filters = {
      ...this.filters,
      employees: {
        ...this.filters.employees,
        list: employees,
      },
      companies: {
        ...this.filters.companies,
        list: companies,
      },
      mineCheckbox: {
        ...this.filters.mineCheckbox,
        list: Array.from(mineCheckedSet),
        name,
      },
    };
  };

  @action
  updateFilters = (): void => {
    const {
      employees: {
        selected: employees,
      },
      companies: {
        selected: companies,
      },
      statuses: {
        selected: statuses,
      },
      mineCheckbox: {
        checked: mineCheckbox,
        name,
      },
    } = this.filters;

    const filteredSources = this.sources.filter(({ CompanyId, EmployeeId, Creator, Status }) =>
      (!employees.length || employees.includes(EmployeeId)) &&
      (!companies.length || companies.includes(CompanyId)) &&
      (!statuses.length || statuses.includes(Status)) &&
      (!mineCheckbox || Creator === name),
    );

    this.paging = {
      ...this.paging,
      total: filteredSources.length,
    };

    this.filteredSources = filteredSources;
    this.cacheItems = getPagingView(filteredSources, this.paging);
  };

  @action
  updateEmployeesFilter = (values: number[]): void => {
    this.filters.employees.selected = values;
  };

  @action
  updateCompaniesFilter = (values: number[]): void => {
    this.filters.companies.selected = values;
  };

  @action
  updateStatusFilter = (values: number[]): void => {
    this.filters.statuses.selected = values;
  };

  @action
  updateMineCheckboxFilter = (value: any): void => {
    this.filters.mineCheckbox.checked = value;
  };

  @action
  resetFilters = (): void => {
    this.paging = {
      ...this.paging,
      total: this.sources.length,
    };

    this.cacheItems = getPagingView(this.sources, this.paging);
    this.filteredSources = [];
  };

  @computed
  get dataForCreateReport() {
    const { sources, startDate, endDate } = this.dialog;

    return {
      CheckinDate: momentObject(startDate),
      CheckoutDate: momentObject(endDate),
      Items: sources.filter(({ selected }) => selected),
    };
  }

  @computed
  get isShowMineCheckboxFilter(): boolean {
    return this.filters.mineCheckbox.list.length > 1;
  }

  @computed
  get isShowCompaniesFilter(): boolean {
    return this.filters.companies.list.length < 2;
  }
}

export const expenseReportsStore = new ExpenseReportsStore();
