import { yup, yupEnum, yupNumberNull, yupStringNull } from '../../../../utils/yup';

import { EmployeeSchema } from '../employee/consts';
import { ApprovalWarning } from '../../constants/travelApproval';

import { Project } from '../../types/trips';
import { BonusesType } from '../employee/types';

export enum ApproveStatus {
  waitingApprove = 'WaitingApprove',
  declined = 'Declined',
  accepted = 'Accepted',
  autoAccepted = 'AutoAccepted',
  autoDeclined = 'AutoDeclined',
  archived = 'Archived',
}

enum ApprovalType {
  All = 'All',
  TravelPolicy = 'TravelPolicy',
}

export enum StepCondition {
  AllApprovers = 'AllApprovers',
  OneOfApproversAndSendAll = 'OneOfApproversAndSendAll',
  OneOfApproversAndSendSelected = 'OneOfApproversAndSendSelected',
}

export const PurposeSchema = yup.object({
  FullName: yupStringNull.notRequired().default(''),
  Name: yupStringNull.notRequired().default(''),
  Id: yupNumberNull,
  SourceId: yupNumberNull,
});

export const TravelApprovalTemplateSchema = yup.object({
  Id: yupNumberNull,
  Name: yupStringNull,
  AccountId: yupNumberNull,
  IsRequiredDestinationCity: yup.boolean().default(true),
  IsRequiredDates: yup.boolean().default(true),
  IsRequiredBudget: yup.boolean().default(true),
  IsRequiredPurpose: yup.boolean().default(true),
  IsRequiredComment: yup.boolean().default(false),
});

const TravelApprovalSpendingSchema = yup.object({
  TripId: yup.number().integer(),
  ServiceType: yup.string(),
  Data: yup.string(),
  Amount: yup.number(),
  Employees: yup.array(yup.number().required()).default([]),
});

const ApprovesMessageSchema = yup.object({
  Comment: yup.string().default(''),
  Date: yup.string().datetime(),
  Name: yup.string(),
  Email: yup.string(),
});

const TravelApprovalApprovesSchema = EmployeeSchema.pick([
  'Id',
  'Name',
  'Email',
]).shape({
  CreatedDate: yup.string().datetime(),
  ApproverName: yup.string(),
  UserId: yup.string().uuid(),
  Resolution: yupEnum(ApproveStatus),
  RequestId: yup.number(),
  StepId: yup.number(),
  ApprovalDate: yup.string().datetime(),
  Messages: yup.array().of(ApprovesMessageSchema),
  Changes: yup.array(),
});

export const TravelApprovalSchema = yup.object({
  Id: yupNumberNull,
  EventDate: yupStringNull.datetime(),
  UserId: yupStringNull.uuid(),
  AccountId: yupStringNull,
  StartDate: yupStringNull.datetime(),
  MinDate: yup.string().nullable().default(undefined).datetime(),
  EndDate: yupStringNull.datetime(),
  Budget: yupNumberNull,
  Comment: yup.string().default(''),
  Analytics: yup.array().of(yup.number().required()).default([]),
  Tags: yup.array().of(yup.number().required()).default([]),
  Purpose: PurposeSchema.nullable().default(null),
  Destinations: yup.array().of(PurposeSchema).default([PurposeSchema.getDefault()]),
  Employees: yup.array().of(EmployeeSchema).default([]),
  ApprovalSchemeId: yupNumberNull,
  Template: TravelApprovalTemplateSchema.default(TravelApprovalTemplateSchema.getDefault()),
  AvailableBalance: yupNumberNull,
  Spendings: yup.array().of(TravelApprovalSpendingSchema).default([]),
  CanBeArchived: yup.boolean().default(false),
  Approves: yup.array().of(TravelApprovalApprovesSchema).default([]),
  Status: yupEnum(ApproveStatus).required().default(ApproveStatus.waitingApprove),
});

const ClientStepApproversEmployeeSchema = yup.object().shape({
  FirstName: yup.string(),
  LastName: yup.string(),
  MiddleName: yup.string(),
  EmployeeId: yup.number(),
  UserId: yup.string(),
  Email: yup.string().email(),
});

const ClientStepApproversSchema = yup.object({
  Id: yup.number(),
  Guid: yup.string().uuid(),
  Approvers: yup.array().of(ClientStepApproversEmployeeSchema),
  StepCondition: yupEnum(StepCondition),
});

const ClientFactualStepApproversSchema = ClientStepApproversSchema.shape({
  Type: yupEnum(ApprovalType),
});

export const RequestGetApproverListSchema = yup.object({
  CanSkipApproval: yup.boolean(),
  PreSteps: yup.array().of(ClientStepApproversSchema),
  Steps: yup.array().of(ClientFactualStepApproversSchema),
  SchemeId: yup.number().notRequired(),
});

type CartItemValidationStatuses = Record<string, ApprovalWarning[]>;

export const RequestCartValidateSchema = yup.object({
  AvailableBalance: yupNumberNull,
  CartItemValidationStatuses: yup.mixed<CartItemValidationStatuses>().nullable().default(null),
  Valid: yup.boolean().default(false),
});

export const EmployeeProjectsSchema = EmployeeSchema.shape({
  MobilePhone: yup.string(),
  Code: yup.string(),
  Code3: yup.string(),
  EmailEditable: yup.boolean(),
  EmployeeNumber: yup.string(),
  ApprovalExpenseReportSchemeId: yupNumberNull,
  Projects: yup.array<Project>(),
  Bonuses: yup.array<BonusesType>(),
  Underage: yup.boolean(),
});

export type DestinationType = yup.InferType<typeof PurposeSchema>;
export type PurposeType = yup.InferType<typeof PurposeSchema>;
export type ApprovesType = yup.InferType<typeof TravelApprovalApprovesSchema>;
export type RequestApprovesType = Omit<ApprovesType, 'Changes'>;
export type MessagesType = yup.InferType<typeof ApprovesMessageSchema>;
export type TravelApprovalType = yup.InferType<typeof TravelApprovalSchema>;
export type TravelApprovalTemplateType = yup.InferType<typeof TravelApprovalTemplateSchema>;
export type TravelApprovalEmployeeType = yup.InferType<typeof EmployeeSchema>;
export type TravelApprovalSpendingType = yup.InferType<typeof TravelApprovalSpendingSchema>;
export type RequestGetApproverListType = yup.InferType<typeof RequestGetApproverListSchema>;
export type RequestCartValidateType = yup.InferType<typeof RequestCartValidateSchema>;
export type EmployeeProjectsType = yup.InferType<typeof EmployeeProjectsSchema>;
export type ProjectEditableType = Record<`${number}`, boolean>;
