import { getText, getTextArray } from '../../../i18n';

import { AnalyticSortTrain, TTransitDocumentButtons } from '../types/trains';

const TRANSIT_DOCUMENTS = {
  UPD_TITLE: getText('components:trainItem.attentionKaliningrad.updInfo.title'),
  VISA: getText('components:trainItem.attentionKaliningrad.visa'),
};

const SEARCH_STORE_FIELDS = {
  FROM: 'from',
  TO: 'to',
  FROMSELECTED: 'from.selected',
  TOSELECTED: 'to.selected',
  FROMCLEAR: 'from.clear',
  TOCLEAR: 'to.clear',
  TRAVELLERS: 'travellers',
  DATE: 'date',
  DATEBACK: 'date.back',
};

const CARTYPE = {
  RESERVEDSEATS: getText('constants:train.carType.reservedSeats'),
  COMPARTMENT: getText('constants:train.carType.compartment'),
  SUITE: getText('constants:train.carType.suite'),
  SEDENTARY: getText('constants:train.carType.sedentary'),
  VIP: getText('constants:train.carType.vip'),
  COMMON: getText('constants:train.carType.common'),
};

const SHORT_TYPE_CAR_EN = {
  Couchette: 'Couch',
  Deluxe: 'Luxe',
  Compartment: 'Comp',
  'Seat-only': 'Seat',
  Cushioned: 'Cush',
  'Sitting carriage': 'Sitt',
  Sleeper: 'Sleep',
};

const TRAINNAMES = {
  SAPSAN: getText('constants:train.trainNames.sapsan'),
  LASTOCHKA: getText('constants:train.trainNames.lastochka'),
  LASTOCHKAPREMIUM: getText('constants:train.trainNames.lastochkaPremium'),
  STRIJ: getText('constants:train.trainNames.strij'),
  NEVSKIJ: getText('constants:train.trainNames.nevskij'),
};

const LETTERPLACE = {
  М: getText('constants:train.letterPlace.м'),
  Ж: getText('constants:train.letterPlace.ж'),
  С: getText('constants:train.letterPlace.с'),
  Ц: getText('constants:train.letterPlace.ц'),
};

const CANCELLATION_TRAIN = {
  DATE: getText('constants:train.cancellationTrain.date'),
  HOUR: getText('constants:train.cancellationTrain.hour'),
};

const TRAIN_TARIFFS = {
  SIMPLE: 0,
  TO: 1,
  FROM: 2,
  COUPE_MEETING: 3,
  COUPE_BUY_TWO: 4,
  KUPEK_IN_TRIP: 5,
  SINGLE: 'Single',
  KUPEK: 'Kupek',
};

const TRAIN_RESULT_SORT = [
  {
    label: getText('constants:train.trainResultSort.tripTime'),
    value: 'duration_up',
    type: 1,
    analyticValue:
    AnalyticSortTrain.arrival_time_ascending,
  },
  {
    label: getText('constants:train.trainResultSort.tripTime'),
    value: 'duration_down',
    type: 0,
    analyticValue:
    AnalyticSortTrain.arrival_time_descending,
  },
  {
    label: getText('constants:train.trainResultSort.departureTime'),
    value: 'departure_up',
    type: 1,
    analyticValue:
    AnalyticSortTrain.departure_time_descending,
  },
  {
    label: getText('constants:train.trainResultSort.departureTime'),
    value: 'departure_down',
    type: 0,
    analyticValue:
    AnalyticSortTrain.departure_time_ascending,
  },
  {
    label: getText('constants:train.trainResultSort.arrivalTime'),
    value: 'arrival_up',
    type: 1,
    analyticValue:
    AnalyticSortTrain.arrival_time_descending,
  },
  {
    label: getText('constants:train.trainResultSort.arrivalTime'),
    value: 'arrival_down',
    type: 0,
    analyticValue:
    AnalyticSortTrain.arrival_time_ascending,
  },
  {
    label: getText('constants:train.trainResultSort.firstCheapest'),
    value: 'price_up',
    type: null,
    analyticValue:
    AnalyticSortTrain.cheaper_first,
  },
  {
    label: getText('constants:train.trainResultSort.firstExpensive'),
    value: 'price_down',
    type: null,
    analyticValue:
    AnalyticSortTrain.more_expensive_first,
  },
];

const TRAIN_LENGTHS = {
  SHORTER: getText('constants:train.trainLengths.shorter'),
  LONGER: getText('constants:train.trainLengths.longer'),
  CHEAPER: getText('constants:train.trainLengths.cheapest'),
  MORE_EXPENSIVE: getText('constants:train.trainLengths.moreExpensive'),
};

const BUTTON_LABELS = {
  FURTHER: getText('constants:train.buttonLabels.next'),
  TO_CART: getText('constants:train.buttonLabels.toCart'),
  SEARCH_BACK_TICKETS: getText('constants:train.buttonLabels.searchBackTickets'),
};

const TRANSFER = getTextArray('constants:train.transferDeclines');

const INDEX_NUMBER = {
  0: getText('constants:train.indexNumber.0'),
  1: getText('constants:train.indexNumber.1'),
  2: getText('constants:train.indexNumber.2'),
  4: getText('constants:train.indexNumber.4'),
};

const TRAIN_CAR_TWO_FLOOR = 'sedentaryTwoFloorClassOne';

const SEAT_TYPES = {
  UP: 'up',
  DOWN: 'down',
  UNDEF: 'undef',
};

/** Станции Калининграда для выбора транзитных документов и их валидации */
const TRAIN_KALININGRAD_STATIONS = [
  'калининград',
  'kaliningrad',
];

enum EKaliningradRadioVariantsResponse {
  /** Упрощенный проездной документ на железной дороге (УПД ЖД) */
  SimplifiedDocument = 'SimplifiedDocument',
  /** Есть документ для транзитного проезда */
  Permission = 'Permission',
  /** Нет выбранного документа */
  NoValue = 'NoValue',
}

enum EKaliningradRadioVariantsRequest {
  SimplifiedDocument = '2',
  Permission = '1',
  NoValue = '0',
}

const TRANSIT_DOCUMENTS_RADIO_BUTTON_CONFIG: TTransitDocumentButtons = {
  [EKaliningradRadioVariantsRequest.SimplifiedDocument]: {
    text: TRANSIT_DOCUMENTS.UPD_TITLE,
    checked: true,
  },
  [EKaliningradRadioVariantsRequest.Permission]: {
    text: TRANSIT_DOCUMENTS.VISA,
    checked: true,
  },
};

enum FilterType {
  PRICE = 'price',
  TIME = 'time',
  TRAIN_NUMBER_OR_NAME = 'trainNumberOrName',
  IS_SAPSAN = 'isSapsan',
  TYPE = 'type',
  NUMBER = 'number',
  FAVORITE_ID = 'favoriteId',
  SELECTED_TRAVEL_POLICY = 'selectedTravelPolicy',
  TRANSFERS = 'transfers',
}

enum FilterKey {
  PRICE = 'price',
  DEPARTURE = 'departure',
  ARRIVAL = 'arrival',
  NUMBER = 'number',
  TRAIN_NUMBER_OR_NAME = 'trainNumberOrName',
  IS_SAPSAN = 'isSapsan',
  FAVORITE = 'favorite',
  TRAVEL_POLICY = 'travelPolicy',
}

enum SortBy {
  DURATION_UP = 'duration_up',
  DURATION_DOWN = 'duration_down',
  ARRIVAL_UP = 'arrival_up',
  ARRIVAL_DOWN = 'arrival_down',
  DEPARTURE_UP = 'departure_up',
  DEPARTURE_DOWN = 'departure_down',
  PRICE_UP = 'price_up',
  PRICE_DOWN = 'price_down',
}

enum TrainSortableFields {
  TRAVEL_TIME = 'TravelTime',
  ARRIVAL_DATE = 'ArrivalDate',
  DEPARTURE_DATE = 'DepartureDate',
  PRICE = 'Price',
}

export {
  SEARCH_STORE_FIELDS as default,
  CARTYPE,
  TRAINNAMES,
  LETTERPLACE,
  CANCELLATION_TRAIN,
  TRAIN_TARIFFS,
  TRAIN_RESULT_SORT,
  TRAIN_LENGTHS,
  BUTTON_LABELS,
  TRANSFER,
  INDEX_NUMBER,
  SHORT_TYPE_CAR_EN,
  TRAIN_CAR_TWO_FLOOR,
  SEAT_TYPES,
  TRAIN_KALININGRAD_STATIONS,
  TRANSIT_DOCUMENTS_RADIO_BUTTON_CONFIG,
  FilterType,
  FilterKey,
  SortBy,
  TrainSortableFields,
  EKaliningradRadioVariantsResponse,
  EKaliningradRadioVariantsRequest,
};
