import { Moment } from 'moment';

interface IInsuranceStore {
  addedItemsToInsurance: number[],
  bookedInsurance: IOrderedInsurance,
  choosenRate: EnumRateName,
  clearSelectedItems: () => void,
  disabledItems: number[],
  isOpenInsuranceSidePanel: boolean,
  isSelectedAll: boolean,
  listItems: IListItem[],
  rates: InsuranceRateType[],
  selectedItems: IItemInTrip[],
  setAddedInsurance: (cartIds: number[]) => void,
  setAllItems: () => void,
  setBookedInsurance: (rate: IOrderedInsurance) => void,
  setChoosenRate: (rate: EnumRateName) => void,
  setInsuranceSidePanel: (isOpen: boolean) => void,
  setIsSelectedAll: () => void,
  setIsValiDates: (valid: boolean) => void,
  setListItems: (list: IListItem[], disabledItems: number[]) => void,
  setRates: (rates: InsuranceRateType[]) => void,
  setSelectedItems: (ids: IItemInTrip[]) => void,
}

interface IInsuranceDataFromService {
  InsuranceServices: {
    TripItemId: number,
    ServiceType: string,
    Amount: number,
    Data: string,
    Employees: number[],
  }[],
  InsuranceRatePrice: InsuranceRateType[],
  CartInsuranceInfo: {
    Rate: EnumRateName,
    InsuranceRatePrice: InsuranceRateType[],
    TripItemIds: number[],
  }
}

interface InsuranceServiceType {
  api: any,
  clearSelectedItems: () => void,
  fetchPriceOfInsurance: (Id: number, isCart: boolean, rateName: EnumRateName) => void,
  getListItems: () => void,
  insuranceStore: IInsuranceStore,
  sendInsuranceToCartService: (orderedData: IOrderedInsurance, tripId?: number | null) => void,
  setAddedInsurance: () => void,
  setAllItems: () => void,
  setBookedInsurance: (rate: IOrderedInsurance) => void,
  setChoosenRate: (rate: EnumRateName) => void,
  setInsuranceSidePanel: (isOpen: boolean) => void,
  setIsValidDates: (valid: boolean) => void,
  setSelectedItems: (bookedInfo: IItemInTrip, tripId?: number) => void,
}

enum EnumRateName {
  BASE = 'base',
  OPTIMAL = 'optimal',
  BUSINESS = 'business',
  DEFAULT = '',
}

type IDates = [minDate: Moment | string, maxDate: Moment | string];

type iAirlineRoutesDates = { CheckinDate: string, CheckoutDate: string }[];

interface InsuranceRateType {
  Rate: EnumRateName,
  Price: number,
}

interface IListItem extends IItemInTrip {
  ServiceType: string,
  Data: string,
}

interface IInsuranceItemData {
  Rate: string,
  InsuranceServices: IInsuranceServices[],
}
interface IInsuranceServices extends InsuranceServiceType {
  ServiceType: string,
  Amount: number,
  Data: string,
  checkIsServicesNotRefundable: (orderItems: IItemInOrder[]) => boolean,
}

interface IDataForInsurance {
  AirlineRoutesDates: { CheckinDate: string; }[];
  CarClass: string;
  CheckinDate: string;
  CheckoutDate: string;
  City: string;
  Description: string;
  Direction: string;
  IncludeTable: boolean;
  Name: string;
  OrderId?: string | null;
  Price: number;
}

interface IOrderedInsurance {
  orderDate: IDates;
  rate: EnumRateName,
  price: number,
  isCart: boolean,
  items: number[] | IItemInTrip[],
}

interface IItemInTrip {
  Amount: number,
  TripItemId: number,
}

interface IItemInOrder {
  ActualVersion: {
    ServiceType: string,
    JsonData: string,
  }
}

interface ICartItem {
  ServiceType: string,
  Data: string,
  Id?: number,
  TripId?: number | null;
  Price: number
}

export {
  EnumRateName,
};

export type {
  ICartItem,
  IDataForInsurance,
  IDates,
  iAirlineRoutesDates,
  IInsuranceServices,
  IInsuranceStore,
  IItemInOrder,
  IItemInTrip,
  IListItem,
  InsuranceRateType,
  InsuranceServiceType,
  IOrderedInsurance,
  IInsuranceDataFromService,
  IInsuranceItemData,
};
