import React from 'react';
import { Text } from 'new-ui';

import { TRANSIT_DOCUMENTS } from '../../constants';

import styles from '../../../../styles/index.module.css';

export const TooltipTextUPD = () => (
  <div className={ styles.tooltip_upd }>
    <Text type='NORMAL_14' color='white'>
      { TRANSIT_DOCUMENTS.UPD_INFO.FIRST }
    </Text>
    <Text type='NORMAL_14' color='white'>
      { TRANSIT_DOCUMENTS.UPD_INFO.SECOND }
    </Text>
  </div>
);
