import React, { useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Text, Textarea, Button, Tooltip } from 'new-ui';

import { getText } from '../../../i18n';

import { MainAnalytic } from '../../bi/utils/analytics';

import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { ChatService } from '../../bi/types/chat';
import { OrderItem } from '../../bi/types/order';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('components:changeAirTrip.other.title'),
  PLACEHOLDER: getText('components:changeAirTrip.other.placeholder'),
  SEND_REQUEST: getText('components:changeAirTrip.sendRequest'),
  CHANGE_TICKET: getText('components:changeAirTrip.changeTicket'),
  DEMO_TOOLTIP: getText('components:changeAirTrip.demo'),
  BACK: getText('components:changeAirTrip.back'),
  COMMENT: getText('components:changeAirTrip.comment'),
};

const {
  changeTrip: {
    air: {
      formOther: {
        title,
        commentContent,
        sendRequest,
        backButton: backButtonTest,
      },
    },
  },
} = QA_ATTRIBUTES;

interface ChangeAirTripFormOtherProps {
  chatService: ChatService,
  history: RouteComponentProps['history'],
  tripId: string,
  isDemo: boolean,
  ticketId?: string,
  tripItem?: OrderItem | null,
  isSectionOther?: boolean,
  classNameWrapper?: string,
}

const ChangeAirTripFormOther = ({
  history,
  tripId,
  ticketId = '',
  chatService,
  tripItem = null,
  classNameWrapper = '',
  isDemo,
  isSectionOther = false,
}: ChangeAirTripFormOtherProps) => {
  const [valueMessage, setValueMessage] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const disabled = !valueMessage || isDemo;

  const createMessage = () => `
    \n---------------
    ${LABELS.COMMENT}: \n${valueMessage}
  `;

  const handleEditTripItem = () => {
    if (tripItem) {
      chatService.requestOnChangeByOrder(tripItem, createMessage(), Number(tripId));
      chatService.sendTypeForAirline(tripItem.Id);
    }
  };

  const handelBack = () => history.push(ROUTES.CHANGE_AIR_TRIP.SELECT_CHANGE(tripId, ticketId));

  const handleSendRequest = () => {
    handleEditTripItem();

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_CHANGE_CONFIRM_PRESSED(Number(tripId), ANALYTIC_SERVICE_TYPES.AIR),
    );

    history.push(ROUTES.CHANGE_AIR_TRIP.TRIP(tripId));
  };

  const renderButtonBack = () => (isSectionOther && (
    <Button
      qaAttr={ backButtonTest }
      className={ styles.button }
      type='textual'
      onClick={ handelBack }
    >
      { LABELS.BACK }
    </Button>
  ));

  return (
    <div className={ classNameWrapper }>
      <Text qaAttr={ title } type='bold_24'>{ LABELS.TITLE }</Text>
      <Textarea
        qaAttr={ commentContent }
        className={ styles.textarea }
        value={ valueMessage }
        noneResize
        placeholder={ LABELS.PLACEHOLDER }
        autoFocus
        ref={ textAreaRef }
        debounceMs={ 0 }
        onChange={ setValueMessage }
      />
      <div className={ styles.action }>
        <Tooltip
          className={ styles.tooltip }
          show={ isDemo }
          renderContent={ () => (
            <Text
              className={ styles.tooltip_text }
              color='white'
              type='NORMAL_14_130'
            >
              { LABELS.DEMO_TOOLTIP }
            </Text>
          ) }
        >
          <Button
            qaAttr={ sendRequest }
            type='secondary'
            disabled={ disabled }
            onClick={ handleSendRequest }
          >
            {LABELS.SEND_REQUEST}
          </Button>
        </Tooltip>
        { renderButtonBack() }
      </div>
    </div>
  );
};

export { ChangeAirTripFormOther };
