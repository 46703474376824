import React, { ReactNode } from 'react';

interface IFormWrapperProps {
  children: ReactNode;
  className?: string;
  onSubmit?(): void;
}

const FormWrapper = ({
  children,
  className = '',
  onSubmit = () => {},
}: IFormWrapperProps) => (
  <form
    autoComplete={ 'off' }
    className={ className }
    onSubmit={ e => {
      e.preventDefault();
      onSubmit();
    } }
  >
    { children }
  </form>
);

export { FormWrapper };
