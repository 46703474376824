import { ISavedTicket } from '../../../types/trains';

const getMinMaxValues = (array: number[]) =>
  [Math.min(...array), Math.max(...array)];

const mapSavedTicketToTrainItemModel = ({
  car: {
    ClassService,
    CarNumber,
    parentCar: {
      TrainNumber,
      TypeShow,
    },
  },
  train: {
    ArrivalDateLocal,
    ArrivalDate,
    DepartureDateLocal,
    DepartureDate,
    StationFrom,
    StationTo,
    StationDepart,
    StationArrive,
    TrainId,
  },
  places,
  totalPrice,
}: ISavedTicket) => {
  const selectedPlaces = places
    ? places.reduce<string[]>((arr, { place }) => {
      if (!place) return arr;

      return [...arr, ...place];
    }, [])
    : [];

  const isValidSelectedPlaces = selectedPlaces.length > 1
  && !isNaN(selectedPlaces[0] as unknown as number);

  const selectedPlacesMinMax = isValidSelectedPlaces
    ? getMinMaxValues(selectedPlaces as unknown as number[])
    : selectedPlaces;

  return {
    ClassService,
    DateArrivalLocal: ArrivalDateLocal,
    DateArrive: ArrivalDate,
    DateDepartureLocal: DepartureDateLocal,
    DateDeparture: DepartureDate,
    TrainNumber,
    CarNumber,
    CarType: TypeShow as string,
    StationDepart,
    StationArrive,
    StationFrom,
    StationTo,
    PlaceNumber: selectedPlacesMinMax,
    TrainId,
    travellers: places?.length,
    totalPrice,
  };
};

export default mapSavedTicketToTrainItemModel;
