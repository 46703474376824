import React, { Component } from 'react';
import { Moment } from 'moment';
import { Dialog, Button, Dropdown, RadioButton, Datepicker, Text, Select } from 'new-ui';

import { getText } from '../../../../../../i18n';

import Report from '../../../../../bi/services/report';

import { isSmartAgent } from '../../../../../bi/utils/env';

import FORMATS from '../../../../../bi/constants/formats';
import { RADIO_VALUES } from '../../../../../bi/constants/report';
import { FIELDS_DATE } from '../../../../../bi/constants/dateFormats';

import {
  IAllInvoice,
  IInvoiceDebitData,
  IOperationStore,
} from '../../../../../bi/types/report';

import styles from './index.module.css';

const RADIOFIELD = 'radioField';

const LABELS = {
  TITLE: getText('components:operationsTable.dialogs.downloadInvoice.title'),
  SUBTITLE: getText('components:operationsTable.dialogs.downloadInvoice.subtitle'),
  UNDO: getText('common:undo'),
  ALL_TIME: getText('components:operationsTable.dialogs.downloadInvoice.allTime'),
  AFTER_LAST_PAY: getText('components:operationsTable.dialogs.downloadInvoice.afterLastTime'),
  PICK_INVOICE: getText('components:operationsTable.dialogs.downloadInvoice.pickInvoice'),
  PDF: getText('components:operationsTable.dialogs.downloadInvoice.pdf'),
  XLSX: getText('components:operationsTable.dialogs.downloadInvoice.xlsx'),
  FROM: getText('components:operationsTable.dialogs.downloadInvoice.from'),
  FROM_LOWER: getText('components:operationsTable.dialogs.downloadInvoice.fromLower'),
  BY: getText('components:operationsTable.dialogs.downloadInvoice.by'),
};

interface IDownloadInvoiceDialogProps {
  reportService: Report;
  onDownloadInvoiceForDateRange: (
    startDate: string,
    endDate: string,
    format: string,
  ) => void;
  onDownloadInvoiceDebt: (data: IInvoiceDebitData) => void;
  onCloseDialog: () => void;
  show: boolean;
}

interface IDownloadInvoiceDialogState {
  radioField: string;
  startDate: Moment | string;
  endDate: Moment | string;
  loadingAllInvoices: boolean;
  allInvoices: IAllInvoice[];
  selectedInvoice: string;
}

class DownloadInvoiceDialog extends Component<IDownloadInvoiceDialogProps, IDownloadInvoiceDialogState> {
  unsubscribeFn: () => void;

  constructor(props: IDownloadInvoiceDialogProps) {
    super(props);

    const {
      invoice: {
        startDate,
        endDate,
      },
      allInvoicesInfo: {
        loadingAllInvoices,
        allInvoices,
      },
    } = props.reportService.getOperations();

    this.state = {
      [RADIOFIELD]: RADIO_VALUES.LAST_PAYMENT,
      [FIELDS_DATE.START_DATE]: startDate,
      [FIELDS_DATE.END_DATE]: endDate,
      loadingAllInvoices,
      allInvoices,
      selectedInvoice: '',
    };
  }

  componentDidMount() {
    const { reportService: { subscribeOperations } } = this.props;
    this.unsubscribeFn = subscribeOperations(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = ({ allInvoicesInfo: {
    loadingAllInvoices,
    allInvoices,
  } }: IOperationStore) => {
    this.setState({
      loadingAllInvoices,
      allInvoices,
      selectedInvoice: allInvoices[0] ? JSON.parse(allInvoices[0].value) : '',
    });
  };

  handleSetInvoice = (selectedInvoice: string) =>
    this.setState({ selectedInvoice: JSON.parse(selectedInvoice) });

  handleDownload = (format: string) => {
    const {
      reportService,
      onDownloadInvoiceForDateRange,
      onDownloadInvoiceDebt,
      onCloseDialog,
    } = this.props;
    const {
      [RADIOFIELD]: radioField,
      [FIELDS_DATE.START_DATE]: startDate,
      [FIELDS_DATE.END_DATE]: endDate,
      selectedInvoice,
    } = this.state;

    switch (radioField) {
      case RADIO_VALUES.ALL_TIME: {
        const data = {
          Period: RADIO_VALUES.ALL_TIME,
          Format: format,
          Invoice: null,
        };

        onDownloadInvoiceDebt(data);
        break;
      }
      case RADIO_VALUES.LAST_PAYMENT: {
        const data = {
          Period: RADIO_VALUES.LAST_PAYMENT,
          Format: format,
          Invoice: null,
        };

        onDownloadInvoiceDebt(data);
        break;
      }

      case RADIO_VALUES.DATE_RANGE: {
        onDownloadInvoiceForDateRange(
          startDate as string,
          endDate as string,
          format,
        );
        break;
      }

      case RADIO_VALUES.WITH_DATE: {
        const data = {
          Period: RADIO_VALUES.WITH_DATE,
          Format: format,
          Invoice: selectedInvoice,
        };

        onDownloadInvoiceDebt(data);
        break;
      }
    }

    reportService.setInvoiceDialogSettings(RADIOFIELD, radioField);
    onCloseDialog();
  };

  handleSwitchRadio = (value: string) => {
    if (this.state[RADIOFIELD] !== value) {
      this.setState({ [RADIOFIELD]: value });
    }
  };

  handleChangeDate = (field: FIELDS_DATE, value: string | Moment | null) => {
    const dateField = this.state[field] as Moment;

    if (dateField && !dateField.isSame(value)) {
      this.setState({
        [field]: value,
      } as unknown as IDownloadInvoiceDialogState);
    }
  };

  renderLabel = (item: { label: string }) => (
    <div className={ styles.wrap_label }>
      {LABELS.PICK_INVOICE}
      &nbsp;
      <Text color='accent' type='NORMAL_16'>{item.label}</Text>
    </div>
  );

  renderSelectInvoice = () => {
    const { allInvoices, selectedInvoice, [RADIOFIELD]: radioField } = this.state;

    if (!allInvoices.length) {
      return null;
    }

    const value = selectedInvoice ? JSON.stringify(selectedInvoice) : '';

    return (
      <div className={ styles.radio }>
        <RadioButton
          value={ RADIO_VALUES.WITH_DATE }
          checked={ radioField === RADIO_VALUES.WITH_DATE }
          alternativeDesign={ isSmartAgent }
          onChange={ () => this.handleSwitchRadio(RADIO_VALUES.WITH_DATE) }
        >
          <div className={ styles.range } onClick={ () => this.handleSwitchRadio(RADIO_VALUES.WITH_DATE) }>
            <Select
              search
              items={ allInvoices }
              value={ value }
              className={ styles.selectInvoice }
              onChange={ this.handleSetInvoice }
              renderLabel={ this.renderLabel }
            />
          </div>
        </RadioButton>
      </div>
    );
  };

  render() {
    const { onCloseDialog, show } = this.props;
    const {
      [RADIOFIELD]: radioField,
      [FIELDS_DATE.START_DATE]: startDate,
      [FIELDS_DATE.END_DATE]: endDate,
      selectedInvoice,
    } = this.state;

    const disabledBySelectedInvoice = radioField === RADIO_VALUES.WITH_DATE && !selectedInvoice;

    return (
      <Dialog
        show={ show }
        showClosing
        onChange={ onCloseDialog }
      >
        <div className={ styles.main }>
          <Text type='bold_20'>{ LABELS.TITLE }</Text>
          <Text className={ styles.subtitle }>{ LABELS.SUBTITLE }</Text>
          <div className={ styles.radios }>
            <div className={ styles.radio }>
              <RadioButton
                value={ RADIO_VALUES.LAST_PAYMENT }
                checked={ radioField === RADIO_VALUES.LAST_PAYMENT }
                alternativeDesign={ isSmartAgent }
                onChange={ () => this.handleSwitchRadio(RADIO_VALUES.LAST_PAYMENT) }
              >
                <Text>{ LABELS.AFTER_LAST_PAY }</Text>
              </RadioButton>
            </div>
            {this.renderSelectInvoice()}
            <div className={ styles.radio }>
              <RadioButton
                value={ RADIO_VALUES.ALL_TIME }
                checked={ radioField === RADIO_VALUES.ALL_TIME }
                alternativeDesign={ isSmartAgent }
                onChange={ () => this.handleSwitchRadio(RADIO_VALUES.ALL_TIME) }
              >
                <Text>{ LABELS.ALL_TIME }</Text>
              </RadioButton>
            </div>
            <div className={ styles.radio }>
              <RadioButton
                value={ RADIO_VALUES.DATE_RANGE }
                checked={ radioField === RADIO_VALUES.DATE_RANGE }
                alternativeDesign={ isSmartAgent }
                onChange={ () => this.handleSwitchRadio(RADIO_VALUES.DATE_RANGE) }
              >
                <Text>{ LABELS.FROM }</Text>
              </RadioButton>
              <div className={ styles.range }>
                <Datepicker
                  placeholder={ LABELS.FROM_LOWER }
                  value={ startDate }
                  wrapperClassName={ styles.datepicker }
                  onChange={ v => this.handleChangeDate(FIELDS_DATE.START_DATE, v) }
                  onFocus={ () => this.handleSwitchRadio(RADIO_VALUES.DATE_RANGE) }
                />
                <Text className={ styles.middleText }>{LABELS.BY}</Text>
                <Datepicker
                  placeholder={ LABELS.BY }
                  value={ endDate }
                  // TODO: minDate не ожидается в Datepicker
                  // @ts-ignore
                  minDate={ startDate }
                  wrapperClassName={ styles.datepicker }
                  onChange={ v => this.handleChangeDate(FIELDS_DATE.END_DATE, v) }
                  onFocus={ () => this.handleSwitchRadio(RADIO_VALUES.DATE_RANGE) }
                />
              </div>
            </div>
          </div>
          <div className={ styles.actions }>
            <Dropdown
              renderLabel={ () => <Text className={ styles.dropdown_title } type='NORMAL_14'>{LABELS.PDF}</Text> }
              theme='primary'
              onClick={ disabledBySelectedInvoice ? () => {} : () => this.handleDownload(FORMATS.PDF) }
            >
              <Button
                className={ styles.subitem }
                type='textual'
                onClick={ () => this.handleDownload(FORMATS.XLSX) }
                disabled={ disabledBySelectedInvoice }
              >
                { LABELS.XLSX }
              </Button>
            </Dropdown>
            <Button
              className={ styles.cancel }
              type='textual'
              onClick={ onCloseDialog }
            >
              { LABELS.UNDO }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export { DownloadInvoiceDialog };
