import { Moment } from 'moment';

export enum CalendarType {
  none = 'none',
  single = 'single',
  range = 'range',
}

export enum CalendarMode {
  date = 'date',
  month = 'month',
  year = 'year',
  time = 'time',
}

export type Item = {
  label: string;
  value: string;
};

export interface ICombinedDatepickerProps {
  date?: Moment | null;
  optionalDate?: Moment | null;
  onChange: (date: Moment | null, optionalDate: Moment | null | boolean) => void;
  notChooseOptions?: boolean;
  className?: string;
}
