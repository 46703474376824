import React, { FC } from 'react';
import { Moment } from 'moment';
import { Text } from 'new-ui';
import { getText, getTextArray } from '../../../i18n';

import { MAPCARCLASS_RU } from '../../bi/constants/transfer';
import toDecline from '../../bi/utils/toDecline';

import { TransferItemType } from '../../bi/types/transfer';

import styles from './styles/index.module.css';

const LABELS = {
  CANCEL_TRIP: getText('components:transferItem.cancelTrip'),
  HOURS: getTextArray('components:transferItem.hours'),
  NO_MONEY_BACK: getText('components:transferItem.noCancel'),
  COUNT_OF_PLACES: getText('components:transferItem.countOfPlaces'),
  DATE_TIME: getText('components:transferItem.dateTime'),
  IN: getText('common:in'),
  TABLE_SIGNATURE: getText('components:transferItem.tableSignature'),
  INCLUDE: getText('components:transferItem.include'),
  NOT_INCLUDE: getText('components:transferItem.notInclude'),
  CLASS: getText('components:transferItem.class'),
};

interface TransferItemProps {
  item: TransferItemType<Moment>,
}

const TransferItem: FC<TransferItemProps> = ({ item }) => {
  const {
    StartPlace,
    EndPlace,
    Class,
    CarClass,
    Capacity,
    CancellationTime,
    IncludeTablet,
  } = item;

  const startPlace = StartPlace.Address;
  const endPlace = EndPlace.Address;
  const carClass = Class || CarClass || '';
  const canceledDate = CancellationTime ? (
    <Text type='NORMAL_14' color='gray' className={ styles.class }>
      { LABELS.CANCEL_TRIP }{' '}
      { CancellationTime } { toDecline(CancellationTime, LABELS.HOURS) }
    </Text>
  ) : <Text type='NORMAL_14' color='gray' className={ styles.class }>{ LABELS.NO_MONEY_BACK }</Text>;

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.row }>
        <div>
          <Text type='bold_18'>{ startPlace }</Text>
        </div>
        <div className={ styles.right }>
          <Text type='bold_18'>{ endPlace }</Text>
        </div>
      </div>
      <Text type='NORMAL_14' color='gray' className={ styles.places }>
        { `${LABELS.CLASS} ${MAPCARCLASS_RU[carClass][0].toUpperCase() + MAPCARCLASS_RU[carClass].slice(1)}, ${LABELS.COUNT_OF_PLACES} ${Capacity}` }
      </Text>
      <Text type='NORMAL_14' color='gray' className={ styles.places }>
        { LABELS.TABLE_SIGNATURE } { IncludeTablet ? LABELS.INCLUDE : LABELS.NOT_INCLUDE }
      </Text>
      {canceledDate}
    </div>
  );
};

export default TransferItem;
