import { getText } from '../../../i18n';

const LABELS = {
  DUPLICATE: {
    USER: getText('constants:checkout.duplicate.user'),
    TRIP: getText('constants:checkout.duplicate.trip'),
    TRIPS: getText('constants:checkout.duplicate.trips'),
    TICKETS: getText('constants:checkout.duplicate.tickets'),
    HOTELS: getText('constants:checkout.duplicate.hotels'),
    TICKET_HOTELS: getText('constants:checkout.duplicate.ticketHotels'),
    TRIP_AIR_TRAIN: getText('constants:checkout.duplicate.tripAirTrain'),
    TRIP_HOTEL: getText('constants:checkout.duplicate.tripHotel'),
    HEADER: {
      CART: getText('constants:checkout.duplicate.header.cart'),
      TRIP: getText('constants:checkout.duplicate.header.trip'),
      TRIPS: getText('constants:checkout.duplicate.header.trips'),
    },
    TITLES: {
      CART: getText('constants:checkout.duplicate.titles.cart'),
      DUPLICATE_CARTS: getText('constants:checkout.duplicate.titles.duplicateCarts'),
    },
  },
};

const CHECKOUT = {
  DUBLICATE: {
    USER: LABELS.DUPLICATE.USER,
    TRIP: LABELS.DUPLICATE.TRIP,
    TRIPS: LABELS.DUPLICATE.TRIPS,
    TICKETS: LABELS.DUPLICATE.TICKETS,
    HOTELS: LABELS.DUPLICATE.HOTELS,
    TICKETSHOTELS: LABELS.DUPLICATE.TICKET_HOTELS,
    TRIPAIRTRAIN: LABELS.DUPLICATE.TRIP_AIR_TRAIN,
    TRIPHOTEL: LABELS.DUPLICATE.TRIP_HOTEL,
    HEADER: {
      CART: LABELS.DUPLICATE.HEADER.CART,
      TRIP: LABELS.DUPLICATE.HEADER.TRIP,
      TRIPS: LABELS.DUPLICATE.HEADER.TRIPS,
    },
    TITLES: {
      Cart: LABELS.DUPLICATE.TITLES.CART,
      DuplicateCarts: LABELS.DUPLICATE.TITLES.DUPLICATE_CARTS,
    },
  },
};

const TAXI_CONFLICTS_TYPE = {
  WRONG_BOTH_DATES: 'WrongBothDates',
  WRONG_START_DATE: 'WrongStartDate',
  DIALOG_WRONG_START_DATE: 'taxiDialogWrongStartDate',
  DIALOG_WRONG_BOTH_DATES: 'taxiDialogWrongBothDates',
};

const INSURANCE_CONFLICTS_TYPE = {
  WRONG_TRIP_ITEM_VERSION: 'WrongTripItemVersion',
  WRONG_DATES: 'WrongDates',
  DIALOG_WRONG: 'insuranceWrong',
};

export { CHECKOUT, TAXI_CONFLICTS_TYPE, INSURANCE_CONFLICTS_TYPE };
