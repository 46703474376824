import React, { Component } from 'react';
import { MomentInput } from 'moment';
import { DatepickerDialog, StyledWrapper, Text, Button, Price } from 'new-ui';

import { getText } from '../../../i18n';

import InputModal from '../InputModal';
import CloudPayments from '../CloudPayments';
import { ToDo } from '../ToDo';

import { defaultPattern, formatDate, getMoment, momentObject } from '../../bi/utils/formatDate';
import { isSmartAgent } from '../../bi/utils/env';

import { SmartdeskPanelProps, SmartdeskPanelState, ISmartdeskProps } from '../../bi/types/smartdesk';
import { IOnChangeArgs, IToDoItem } from '../ToDo/types';

import styles from './styles/index.module.css';

const LABELS = {
  yourDebt: getText('smartdesk:yourDebt'),
  lastInvoice: getText('smartdesk:lastInvoice'),
  from: getText('smartdesk:from'),
  savePdf: getText('smartdesk:savePdf'),
  sendToEmail: getText('smartdesk:sendToEmail'),
  putEmail: getText('smartdesk:putEmail'),
  dispatchInvoice: getText('smartdesk:dispatchInvoice'),
};

class SmartdeskPanel extends Component<SmartdeskPanelProps, SmartdeskPanelState, ISmartdeskProps> {
  static defaultProps = {
    balance: 0,
    invoice: null,
    showInvoice: true,
    showLastInvoice: true,
    showPaymentButton: true,
    children: null,
  };

  constructor(props: SmartdeskPanelProps) {
    super(props);

    this.state = {
      showSendDialog: false,
      mode: 'date',
    };
  }

  handleSendDocumentDialog = (value: boolean) => this.setState({ showSendDialog: value });

  handleSendInvoice = (email: string) => {
    this.handleSendDocumentDialog(false);

    if (this.props.onSendInvoice) this.props.onSendInvoice(email);
  };

  renderCloudPayment = () => {
    const {
      company,
      paymentService,
      isDemo,
      showPaymentButton,
    } = this.props;

    if (isDemo || !showPaymentButton || !company?.companies.length) {
      return null;
    }

    return (
      <CloudPayments
        email={ company?.email }
        companyId={ company?.companyId }
        companyInn={ company?.companyInn }
        companyName={ company?.companyName }
        allCompanies={ company?.allCompanies }
        companies={ company?.companies }
        paymentService={ paymentService }
      />
    );
  };

  renderInvoice = () => {
    const { invoice, showInvoice, onDownloadInvoice, showLastInvoice } = this.props;
    const { showSendDialog } = this.state;

    if (!showInvoice || !invoice || !showLastInvoice) {
      return null;
    }

    let invoiceHtml = null;

    if (invoice) {
      invoiceHtml = (
        <div className={ styles.invoice }>
          <Text
            type='NORMAL_14'
            className={ styles.title }
          >
            { LABELS.lastInvoice } { invoice.Number } {LABELS.from} { formatDate(invoice.Date) }
          </Text>
          <div className={ styles.actions }>
            <Button
              type='textual'
              onClick={ () => (onDownloadInvoice && onDownloadInvoice(invoice.Number)) }
            >
              { LABELS.savePdf }
            </Button>
            <Button
              type='textual'
              onClick={ () => this.handleSendDocumentDialog(true) }
            >
              { LABELS.sendToEmail }
            </Button>
          </div>
          <InputModal
            show={ showSendDialog }
            label={ LABELS.putEmail }
            title={ LABELS.dispatchInvoice }
            type='email'
            onSend={ this.handleSendInvoice }
            onCancel={ () => this.handleSendDocumentDialog(false) }
          />
        </div>
      );
    }

    return invoiceHtml;
  };

  renderInvoiceHtml = () => {
    const { showInvoicePay, balance } = this.props;

    const balanced = balance || 0;

    if (isSmartAgent || !showInvoicePay) return null;

    return (
      <StyledWrapper className={ styles.balance }>
        <div className={ styles.title }>
          <Text type='bold_24'>
            { LABELS.yourDebt }
          </Text>
          <div>
            { this.renderCloudPayment() }
          </div>
        </div>
        <Price
          className={ styles.debt }
          value={ balanced }
          type='bold_36'
          typeCurrency='normal_20'
        />
        { this.renderInvoice() }
      </StyledWrapper>
    );
  };

  renderToDo = () => {
    const { todo, showInvoicePay, onChangeTodo } = this.props;

    if (isSmartAgent) return null;

    const classNameTodoWrapper = [styles.ultimate_todo_wrap];

    if (showInvoicePay) {
      classNameTodoWrapper.push(styles.ultimate_todo_indent);
    }

    return (
      <div className={ classNameTodoWrapper.join(' ') }>
        <StyledWrapper className={ styles['todo-wrapper'] }>
          <ToDo
            items={ todo as IToDoItem[] }
            onChange={ onChangeTodo as (dispatch: IOnChangeArgs) => void }
          />
        </StyledWrapper>
      </div>
    );
  };

  rightContent = () => !isSmartAgent && (
    <div className={ styles.right }>
      { this.renderInvoiceHtml() }
      { this.renderToDo() }
    </div>
  );

  render() {
    const { calendar: { trips, date }, onChangeMonth, children } = this.props;
    const { mode } = this.state;

    const min = getMoment().add(-3, 'y');
    const max = getMoment().add(1, 'y');

    const tooltipTrips = trips || {};

    return (
      <div className={ styles.wrapper }>
        { children }
        <DatepickerDialog
          min={ min }
          max={ max }
          tooltips={ tooltipTrips }
          onChange={ onChangeMonth }
          theme='big'
          className={ styles.calendar }
          value={ date }
          setMode={ (_mode, newDate: MomentInput) => {
            this.setState({ mode: 'date' });

            if (newDate) onChangeMonth(momentObject(newDate));
          } }
          onChangeSwitcher={ (mod) => {
            this.setState(mod);
          } }
          mode={ mode }
          format={ defaultPattern }
          type='date'
          datePickerParent
          alternativeDesign={ isSmartAgent }
        />
        { this.rightContent() }
      </div>
    );
  }
}

export default SmartdeskPanel;
