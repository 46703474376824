import React from 'react';
import { Text } from 'new-ui';

import { TRANSIT_DOCUMENTS } from '../../constants';

import styles from '../../../../styles/index.module.css';

export const TooltipTextVisa = () => (
  <div className={ styles.tooltip_upd }>
    <Text type='NORMAL_14' color='white'>
      { TRANSIT_DOCUMENTS.VISA_INFO.FIRST }
    </Text>
    <ul className={ styles.description_list }>
      <li>{ TRANSIT_DOCUMENTS.VISA_INFO.SECOND }</li>
      <li>{ TRANSIT_DOCUMENTS.VISA_INFO.THIRD }</li>
      <li>{ TRANSIT_DOCUMENTS.VISA_INFO.FOURTH }</li>
      <li>{ TRANSIT_DOCUMENTS.VISA_INFO.FIFTH }</li>
    </ul>
  </div>
);
