import React, { Component } from 'react';
import { LinkButton, Select, Text } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { SearchSubMenu } from '../../../SearchSubMenu';
import { AirlineSearchRoute } from '../AirlineSearchRoute';
import ChildrenPassangers from '../ChildrenPassangers';

import { SERVICETYPE } from '../../../../../bi/constants/serviceType';
import { TYPE_AIR_SEARCH } from '../../const';

import { formatDate, momentObject } from '../../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../../bi/utils/env';

import { IAirlineSubMenuProps, IAirlineSubMenuState } from './types';

import styles from './styles.module.css';

const LABELS = {
  ADD_FLIGHT: getText('components:menu.air.main.addFlight'),
  SEARCH: getText('common:search'),
  EDIT: getText('common:edit'),
  TRAVELLERS: getText('components:menu.air.main.passengers'),
  CLASS: getText('components:menu.air.main.class'),
  DIRECT: getText('components:menu.air.main.direct'),
  COMPLEX: getText('components:menu.air.main.complex'),
};

class AirlineSubMenu extends Component<IAirlineSubMenuProps, IAirlineSubMenuState> {
  unsubscribeFn: () => void;

  static defaultProps = {
    qaAttrs: null,
  };

  state: IAirlineSubMenuState = {
    travellersOptions: this.props.airlineService.travellersOptions,
    classOptions: this.props.airlineService.classOptions,
    ...this.props.airlineService.getSearch(),
  };

  componentDidMount() {
    const { airlineService, requestsService } = this.props;
    const { request, requestItem } = requestsService.get();

    // @ts-ignore
    if (request && requestItem && requestItem?.SearchOptions?.Routes) {
      // @ts-ignore
      const { SearchOptions: { Routes } } = requestItem;

      const routes = this.state.routes.map((route, index: number) => {
        const { DepartureAirport, ArrivalAirport, DepartureDate } = Routes[index];

        return ({
          ...route,
          from: { ...route.from, label: `${DepartureAirport.City} (${DepartureAirport.Code})` },
          to: { ...route.from, label: `${ArrivalAirport.City} (${ArrivalAirport.Code})` },
          date: momentObject(DepartureDate),
        });
      });

      airlineService.searchSetRoutes(routes);

      this.setState({
        ...this.state,
        routes,
      });
    }

    this.unsubscribeFn = airlineService.subscribeSearch(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = (state: IAirlineSubMenuState) => {
    this.setState({
      ...state,
    });
  };

  renderComplex = () => {
    const {
      routes,
      isValid,
    } = this.state;

    const { onSearch, handleReturnToSearch } = this.props;

    const routesHtml = routes.map((route, ind: number) => {
      const { from, to, date } = route;

      return (
        <div key={ `airline_readonly_${ind}` } className={ styles.route }>
          <Text color='white' type='NORMAL_14'>{ `${from.label}` } &rarr; { `${to.label}` }</Text>
          <Text
            type='NORMAL_12'
            color='blue'
            className={ styles.text }
          >
            { `${formatDate(date)}` }
          </Text>
        </div>
      );
    });

    return (
      <SearchSubMenu
        serviceType={ SERVICETYPE.AIR }
        disabled={ !isValid }
        buttonLabel={ LABELS.SEARCH }
        searchButton={ false }
        onClick={ onSearch }
      >
        <div className={ styles.submenu }>
          <div className={ styles.readonly }>
            { routesHtml }
          </div>
          <div className={ styles.action }>
            <LinkButton
              theme='large-white'
              onClick={ handleReturnToSearch }
            >
              { LABELS.EDIT }
            </LinkButton>
          </div>
        </div>
      </SearchSubMenu>
    );
  };

  renderPassangers = () => {
    const { travellersOptions, travellers } = this.state;
    const {
      airlineService,
      handleChangeAdult,
      handleChangeChild,
      featureFlagsService: {
        getNewAirlineSearch,
        getShowElementForSmartagent,
      },
      qaAttrs,
    } = this.props;

    if (isSmartAgent && getNewAirlineSearch()) {
      const colorText = isSmartAgent && getShowElementForSmartagent() ? 'white' : 'default';

      return (
        <ChildrenPassangers
          airlineService={ airlineService }
          handleChangeAdult={ handleChangeAdult }
          handleChangeChild={ handleChangeChild }
          colorText={ colorText }
          type={ TYPE_AIR_SEARCH.SUB }
        />
      );
    }

    return (
      <Select
        withLabel
        className={ styles.wrapper }
        placeholder={ LABELS.TRAVELLERS }
        theme='dark'
        value={ travellers }
        items={ travellersOptions }
        onChange={ handleChangeAdult }
        alternativeDesign={ isSmartAgent }
        qaAttr={ qaAttrs?.passengers || '' }
      />
    );
  };

  render() {
    const {
      classOptions,
      routes,
      flightClass,
      isComplex,
      isValid,
    } = this.state;
    const {
      airlineService,
      handleChangeFlightClass,
    } = this.props;

    if (isComplex) return this.renderComplex();

    const { onSearch, qaAttrs } = this.props;

    const routesHtml = routes.map((route, ind: number) => (
      <AirlineSearchRoute
        key={ `airline__row__${ind}` }
        ind={ ind }
        theme='dark'
        route={ route }
        isComplex={ isComplex }
        airlineService={ airlineService }
        qaAttrs={ qaAttrs }
      />
    ));

    return (
      <SearchSubMenu
        serviceType={ SERVICETYPE.AIR }
        disabled={ !isValid }
        buttonLabel={ LABELS.SEARCH }
        onClick={ onSearch }
        qaAttrIcon={ qaAttrs?.icon || '' }
        qaAttrSearchButton={ qaAttrs?.searchButton || '' }
      >
        <div className={ styles.submenu }>
          { routesHtml }
          <div className={ `${styles.select} ${styles.passengers}` }>
            { this.renderPassangers() }
          </div>
          <div className={ styles.select }>
            <Select
              withLabel
              className={ styles.wrapper }
              placeholder={ LABELS.CLASS }
              theme='dark'
              value={ flightClass }
              items={ classOptions }
              onChange={ handleChangeFlightClass }
              alternativeDesign={ isSmartAgent }
              qaAttrLabel={ qaAttrs?.flyClass || '' }
            />
          </div>
        </div>
      </SearchSubMenu>
    );
  }
}

export { AirlineSubMenu };
