import bs from '../api/api.config.bs.json';
import f1 from '../api/api.config.f1.json';
import f1Sadev from '../api/api.config.f1.sadev.json';
import f2 from '../api/api.config.f2.json';
import f2Sadev from '../api/api.config.f2.sadev.json';
import f3 from '../api/api.config.f3.json';
import f3Sadev from '../api/api.config.f3.sadev.json';
import f4 from '../api/api.config.f4.json';
import f4Sadev from '../api/api.config.f4.sadev.json';
import f5 from '../api/api.config.f5.json';
import f5Sadev from '../api/api.config.f5.sadev.json';
import f6 from '../api/api.config.f6.json';
import f6Sadev from '../api/api.config.f6.sadev.json';
import f7 from '../api/api.config.f7.json';
import f7Sadev from '../api/api.config.f7.sadev.json';
import f8 from '../api/api.config.f8.json';
import f8Sadev from '../api/api.config.f8.sadev.json';
import f9 from '../api/api.config.f9.json';
import f9Sadev from '../api/api.config.f9.sadev.json';
import f10 from '../api/api.config.f10.json';
import f10Sadev from '../api/api.config.f10.sadev.json';
import f12 from '../api/api.config.f12.json';
import f12Sadev from '../api/api.config.f12.sadev.json';
import f13 from '../api/api.config.f13.json';
import f13Sadev from '../api/api.config.f13.sadev.json';
import f14 from '../api/api.config.f14.json';
import f14Sadev from '../api/api.config.f14.sadev.json';
import f15 from '../api/api.config.f15.json';
import f15Sadev from '../api/api.config.f15.sadev.json';
import f16 from '../api/api.config.f16.json';
import f16Sadev from '../api/api.config.f16.sadev.json';
import f17 from '../api/api.config.f17.json';
import f17Sadev from '../api/api.config.f17.sadev.json';
import f18 from '../api/api.config.f18.json';
import f18Sadev from '../api/api.config.f18.sadev.json';
import f19 from '../api/api.config.f19.json';
import f19Sadev from '../api/api.config.f19.sadev.json';
import f20 from '../api/api.config.f20.json';
import f20Sadev from '../api/api.config.f20.sadev.json';
import f21 from '../api/api.config.f21.json';
import hf from '../api/api.config.hf.json';
import local from '../api/api.config.local.json';
import production from '../api/api.config.production.json';
import productionGost from '../api/api.config.production-gost.json';
import rc from '../api/api.config.rc.json';
import rcSadev from '../api/api.config.rc.sadev.json';
import sandbox from '../api/api.config.sandbox.json';
import smartAgentOnline from '../api/api.config.smartagent.online.json';
import test from '../api/api.config.test.json';

const configs = {
  bs,
  f1,
  f1Sadev,
  f2,
  f2Sadev,
  f3,
  f3Sadev,
  f4,
  f4Sadev,
  f5,
  f5Sadev,
  f6,
  f6Sadev,
  f7,
  f7Sadev,
  f8,
  f8Sadev,
  f9,
  f9Sadev,
  f10,
  f10Sadev,
  f12,
  f12Sadev,
  f13,
  f13Sadev,
  f14,
  f14Sadev,
  f15,
  f15Sadev,
  f16,
  f16Sadev,
  f17,
  f17Sadev,
  f18,
  f18Sadev,
  f19,
  f19Sadev,
  f20,
  f20Sadev,
  f21,
  hf,
  local,
  prod: production,
  production,
  productionGost,
  rc,
  rcSadev,
  sandbox,
  smartAgentOnline,
  test,
};

const featureRename = (fName: string) => {
  const fId = fName.match(/feature(?<fId>[0-9]+(Sadev)*)/mi)?.groups?.fId;

  if (fId) {
    return `f${fId}` as FeatureConfigName;
  }

  if (/(HF|BS|TEST|RC)/mi.test(fName) && !(/(Sa)/.test(fName))) {
    return fName.toLowerCase() as FeatureConfigName;
  }

  return fName as FeatureConfigName;
};

type FeatureConfigName = keyof typeof configs;

const configName = !window.env || window.env.SW_ENV === '%_SW_ENV'
  ? process.env.NODE_ENV as string
  : window.env.SW_ENV;

const config = configs[
  featureRename(configName)
];

type ConfigFixExtendType = typeof config & {
  BACKOFFICE: string,
  CLOUDPAYMENTS: { API_KEY: string },
  PROMO_ROOT: string & Location,
};

export default config as ConfigFixExtendType;
